import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './new-edit-form.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {NewEditFormEffects} from './new-edit-form.effects';
import {NewEditFormService} from './new-edit-form.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppNewEditFormModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppNewEditFormModule,
      providers: [
        {provide: 'config', useValue: config},
        NewEditFormService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppNewEditFormModule,
    StoreModule.forFeature('appNewEditForm', reducer),
    EffectsModule.forFeature([NewEditFormEffects]),
  ],
})
export class RootAppNewEditFormModule {
}
