import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class NewEditFormService {
  private urlResource = environment.app.apiBaseUrl;

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  createForm(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urlResource}formbuilder/forms`, {
      type: body.form_type,
      name: body.name
    }, {observe: 'response'});
  }

  modifyForm(body: any, id: any): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urlResource}formbuilder/forms/${id}`, body, {observe: 'response'});
  }

  getAllFieldTypes(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}formbuilder/fieldtypes?limit=100`, {observe: 'response'});
  }

  getCatalogs(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}catalogs`, {observe: 'response'});
  }

  getAllSubForms(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}formbuilder/subforms`, {observe: 'response'});
  }

  getByIdForm(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}formbuilder/forms/${id}?include=convocatory,parent`, {observe: 'response'});
  }
}
