export class Convocatorie {
  areaIntervention = '';
  lineIntervention = '';
  name = '';
  code = '';
  type = '';
  start_postulation_date = '';
  finish_postulation_date = '';
}

export class ConvocatoriesFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  group = 'draft';
  convocatorie: Convocatorie = new Convocatorie();
}
