export class Postulation {
  search = '';
}

export class PostulationsFilter {
  limit = 10;
  page = 1;
  orderBy = 'postulation_date';
  sortedBy = 'desc';
  postulation: Postulation = new Postulation();
}