import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {SpecialtyFilter} from '../specialties.model';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class SpecialtiesService {

  private urlResource = environment.app.apiBaseUrl + 'specialties';
  private specialtiesFilter = new BehaviorSubject<any>(new SpecialtyFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  sendSpecialtyFilter(object: any) {
    this.specialtiesFilter.next(object);
  }

  currentSpecialtyFilter(): Observable<any> {
    return this.specialtiesFilter.asObservable();
  }

  getSpecialtyFilter() {
    return this.specialtiesFilter.getValue();
  }

  createSpecialty(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urlResource}`, body, {observe: 'response'});
  }

  deleteSpecialty(id): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urlResource}/${id}`, {observe: 'response'});
  }

  modifySpecialty(body: any, id: any): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urlResource}/${id}`, body, {observe: 'response'});
  }

  getByIdSpecialty(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}/${id}?include=area_intervention`, {observe: 'response'});
  }

  getAllSpecialties(specialtyFilter: SpecialtyFilter): Observable<HttpResponse<any>> {
    const params = createRequestOption({
      'page': specialtyFilter.page,
      'limit': specialtyFilter.limit,
      'orderBy': specialtyFilter.orderBy,
      'sortedBy': specialtyFilter.sortedBy,
      'search': specialtyFilter.specialty.search,
      'include': 'area_intervention'
    });
    return this.http.get(`${this.urlResource}`, {params: params, observe: 'response'});
  }
}
