export class Postulation {
  code = '';
  user = '';
  sector = '';
  subsector = '';
  state = '';
  type = '';
  updated_at: any = '';
  postulation_date: any = '';
}

export class PostulationsFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  postulation: Postulation = new Postulation();
}
