import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {CatalogsService} from './catalogs.service';
import * as catalogs from './catalogs.actions';

@Injectable()
export class CatalogsEffects {

  constructor(private actions$: Actions,
              private catalogsService: CatalogsService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(catalogs.CatalogsActionTypes.CreateAction),
    map((action: catalogs.CreateAction) => action.payload),
    exhaustMap((param: any) =>
      this.catalogsService.createCatalog(param).pipe(
        map(success => new catalogs.CreateSuccess({resultHeader: success})),
        catchError(err => of(new catalogs.CreateFailure({validation: err})))
      )
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(catalogs.CatalogsActionTypes.UpdateAction),
    map((action: catalogs.UpdateAction) => action.payload),
    exhaustMap((param: any) =>
      this.catalogsService.modifyCatalog(param.body, param.id).pipe(
        map(success => new catalogs.UpdateSuccess({resultHeader: success})),
        catchError(err => of(new catalogs.UpdateFailure({validation: err})))
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType(catalogs.CatalogsActionTypes.GetByIdAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: catalogs.GetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.catalogsService.getByIdCatalog(param.id).pipe(
        map(success => new catalogs.GetByIdSuccess({resultHeader: success})),
        catchError(err => of(new catalogs.GetByIdFailure({validation: err})))
      )
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(catalogs.CatalogsActionTypes.DeleteAction),
    map((action: catalogs.DeleteAction) => action.payload),
    exhaustMap((param: any) =>
      this.catalogsService.deleteCatalog(param.id).pipe(
        map(success => new catalogs.DeleteSuccess({resultHeader: success})),
        catchError(err => of(new catalogs.DeleteFailure({validation: err})))
      )
    )
  );

}
