export class Convocatorie {
  search = '';
}

export class ConovocatorieFilter {
  limit = 10;
  page = 1;
  orderBy = '';
  sortedBy = '';
  convocatorie: Convocatorie = new Convocatorie();
}
