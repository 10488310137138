import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './convocatories.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ConvocatoriesEffects} from './convocatories.effects';
import {ConvocatoriesService} from './convocatories.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppConvocatoriesModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppConvocatoriesModule,
      providers: [
        {provide: 'config', useValue: config},
        ConvocatoriesService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppConvocatoriesModule,
    StoreModule.forFeature('appConvocatoriesEvaluation', reducer),
    EffectsModule.forFeature([ConvocatoriesEffects]),
  ],
})
export class RootAppConvocatoriesModule {
}
