export class Piu {
  search = '';
}

export class PiuFilter {
  limit = 10;
  page = 1;
  orderBy = 'created_at';
  sortedBy = 'desc';
  convocatorie: Piu = new Piu();
}
