import {Action} from '@ngrx/store';

export enum DischargePostulationsActionTypes {
  EvaluationGetAction = '[DischargePostulations]EvaluationGetAction',
  EvaluationGetSuccess = '[DischargePostulations]EvaluationGetSuccess',
  EvaluationGetFailure = '[DischargePostulations]EvaluationGetFailure',

  EvaluationTotalsAction = '[DischargePostulations]EvaluationTotalsAction',
  EvaluationTotalsSuccess = '[DischargePostulations]EvaluationTotalsSuccess',
  EvaluationTotalsFailure = '[DischargePostulations]EvaluationTotalsFailure',

  PostulationQualifyAction = '[DischargePostulations]PostulationQualifyAction',
  PostulationQualifySuccess = '[DischargePostulations]PostulationQualifySuccess',
  PostulationQualifyFailure = '[DischargePostulations]PostulationQualifyFailure',

  PostulationQualifySetAction = '[DischargePostulations]PostulationQualifySetAction',
  PostulationQualifySetSuccess = '[DischargePostulations]PostulationQualifySetSuccess',
  PostulationQualifySetFailure = '[DischargePostulations]PostulationQualifySetFailure',
  
  EvaluationPDfAction = '[DischargePostulations]EvaluationPDfAction',
  EvaluationPDfSuccess = '[DischargePostulations]EvaluationPDfSuccess',
  EvaluationPDfFailure = '[DischargePostulations]EvaluationPDfFailure',

  PostulationReportAction = '[DischargePostulations]PostulationReportAction',
  PostulationReportSuccess = '[DischargePostulations]PostulationReportSuccess',
  PostulationReportFailure = '[DischargePostulations]PostulationReportFailure',

  PostulationCloseAction = '[DischargePostulations]PostulationCloseAction',
  PostulationCloseSuccess = '[DischargePostulations]PostulationCloseSuccess',
  PostulationCloseFailure = '[DischargePostulations]PostulationCloseFailure',  

  DischargePostulationsEndAction = '[DischargePostulations]DischargePostulationsEndAction',
  DischargePostulationsEndSuccess = '[DischargePostulations]DischargePostulationsEndSuccess',
  DischargePostulationsEndFailure = '[DischargePostulations]DischargePostulationsEndFailure',
}


export class EvaluationGetAction implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationGetAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationGetSuccess implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationGetSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationGetFailure implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationGetFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationTotalsAction implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationTotalsAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationTotalsSuccess implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationTotalsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationTotalsFailure implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationTotalsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationQualifyAction implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationQualifyAction;

  constructor(public payload: { idConvocatory: string, id: string }) {
  }
}

export class PostulationQualifySuccess implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationQualifySuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationQualifyFailure implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationQualifyFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationQualifySetAction implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationQualifySetAction;

  constructor(public payload: { idConvocatory: string, id: string, data:any }) {
  }
}

export class PostulationQualifySetSuccess implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationQualifySetSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationQualifySetFailure implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationQualifySetFailure;

  constructor(public payload: { validation: any }) {
  }
}


export class EvaluationPDfAction implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationPDfAction;

  constructor(public payload: { evaluation: string, user: string }) {
  }
}

export class EvaluationPDfSuccess implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationPDfSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationPDfFailure implements Action {
  readonly type = DischargePostulationsActionTypes.EvaluationPDfFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationReportAction implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationReportAction;

  constructor(public payload: { hash: any }) {
  }
}

export class PostulationReportSuccess implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationReportSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationReportFailure implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationReportFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class DischargePostulationsEndAction implements Action {
  readonly type = DischargePostulationsActionTypes.DischargePostulationsEndAction;

  constructor(public payload: { id: any, phase: any }) {
  }
}

export class DischargePostulationsEndSuccess implements Action {
  readonly type = DischargePostulationsActionTypes.DischargePostulationsEndSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class DischargePostulationsEndFailure implements Action {
  readonly type = DischargePostulationsActionTypes.DischargePostulationsEndFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationCloseAction implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationCloseAction;

  constructor(public payload: { hash: string, type: string }) {
  }
}

export class PostulationCloseSuccess implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationCloseSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationCloseFailure implements Action {
  readonly type = DischargePostulationsActionTypes.PostulationCloseFailure;

  constructor(public payload: { validation: any }) {
  }
}


export type DischargePostulationsActions =
  | EvaluationGetAction
  | EvaluationGetSuccess
  | EvaluationGetFailure
  | EvaluationTotalsAction
  | EvaluationTotalsSuccess
  | EvaluationTotalsFailure
  | PostulationQualifyAction
  | PostulationQualifySuccess
  | PostulationQualifyFailure
  | PostulationQualifySetAction
  | PostulationQualifySetSuccess
  | PostulationQualifySetFailure
  | EvaluationPDfAction
  | EvaluationPDfSuccess
  | EvaluationPDfFailure
  | PostulationReportAction
  | PostulationReportSuccess
  | PostulationReportFailure
  | PostulationCloseAction
  | PostulationCloseSuccess
  | PostulationCloseFailure
  | DischargePostulationsEndAction
  | DischargePostulationsEndSuccess
  | DischargePostulationsEndFailure;
