import {Action} from '@ngrx/store';

export enum InterventionAreasActionTypes {
  InterventionAreasCreateAction = '[InterventionAreas]InterventionAreasCreateAction',
  InterventionAreasCreateSuccess = '[InterventionAreas]InterventionAreasCreateSuccess',
  InterventionAreasCreateFailure = '[InterventionAreas]InterventionAreasCreateFailure',

  InterventionAreasUpdateAction = '[InterventionAreas]InterventionAreasUpdateAction',
  InterventionAreasUpdateSuccess = '[InterventionAreas]InterventionAreasUpdateSuccess',
  InterventionAreasUpdateFailure = '[InterventionAreas]InterventionAreasUpdateFailure',

  InterventionAreasDeleteAction = '[InterventionAreas]InterventionAreasDeleteAction',
  InterventionAreasDeleteSuccess = '[InterventionAreas]InterventionAreasDeleteSuccess',
  InterventionAreasDeleteFailure = '[InterventionAreas]InterventionAreasDeleteFailure',

  InterventionAreasGetByIdAction = '[InterventionAreas]InterventionAreasGetByIdAction',
  InterventionAreasGetByIdSuccess = '[InterventionAreas]InterventionAreasGetByIdSuccess',
  InterventionAreasGetByIdFailure = '[InterventionAreas]InterventionAreasGetByIdFailure'
}

export class InterventionAreasCreateAction implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasCreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class InterventionAreasCreateSuccess implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasCreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class InterventionAreasCreateFailure implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasCreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class InterventionAreasUpdateAction implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasUpdateAction;

  constructor(public payload: { body: any, id: any }) {
  }
}

export class InterventionAreasUpdateSuccess implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasUpdateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class InterventionAreasUpdateFailure implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasUpdateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class InterventionAreasDeleteAction implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasDeleteAction;

  constructor(public payload: { id: any }) {
  }
}

export class InterventionAreasDeleteSuccess implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasDeleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class InterventionAreasDeleteFailure implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasDeleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class InterventionAreasGetByIdAction implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasGetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class InterventionAreasGetByIdSuccess implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasGetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class InterventionAreasGetByIdFailure implements Action {
  readonly type = InterventionAreasActionTypes.InterventionAreasGetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type InterventionAreasActions =
  | InterventionAreasCreateAction
  | InterventionAreasCreateSuccess
  | InterventionAreasCreateFailure

  | InterventionAreasUpdateAction
  | InterventionAreasUpdateSuccess
  | InterventionAreasUpdateFailure

  | InterventionAreasDeleteAction
  | InterventionAreasDeleteSuccess
  | InterventionAreasDeleteFailure

  | InterventionAreasGetByIdAction
  | InterventionAreasGetByIdSuccess
  | InterventionAreasGetByIdFailure;
