import {Action} from '@ngrx/store';

export enum AllConvocatoriesActionTypes {
  GetConvocatoriesFileAction = '[ArchivedFinished]GetConvocatoriesFileAction',
  GeTConvocatoriesFileSuccess = '[ArchivedFinished]GeTConvocatoriesFileSuccess',
  GetConvocatoriesFileFailure = '[ArchivedFinished]GetConvocatoriesFileFailure',

  GetInterventionAreasAction = '[ArchivedFinished]GetInterventionAreasAction',
  GetInterventionAreasSuccess = '[ArchivedFinished]GetInterventionAreasSuccess',
  GetInterventionAreasFailure = '[ArchivedFinished]GetInterventionAreasFailure',

  GetInterventionLinesAction = '[ArchivedFinished]GetInterventionLinesAction',
  GetInterventionLinesSuccess = '[ArchivedFinished]GetInterventionLinesSuccess',
  GetInterventionLinesFailure = '[ArchivedFinished]GetInterventionLinesFailure'
}

export class GetInterventionAreasAction implements Action {
  readonly type = AllConvocatoriesActionTypes.GetInterventionAreasAction;
}

export class GetInterventionAreasSuccess implements Action {
  readonly type = AllConvocatoriesActionTypes.GetInterventionAreasSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetInterventionAreasFailure implements Action {
  readonly type = AllConvocatoriesActionTypes.GetInterventionAreasFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetInterventionLinesAction implements Action {
  readonly type = AllConvocatoriesActionTypes.GetInterventionLinesAction;
}

export class GetInterventionLinesSuccess implements Action {
  readonly type = AllConvocatoriesActionTypes.GetInterventionLinesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetInterventionLinesFailure implements Action {
  readonly type = AllConvocatoriesActionTypes.GetInterventionLinesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetConvocatoriesFileAction implements Action {
  readonly type = AllConvocatoriesActionTypes.GetConvocatoriesFileAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetConvocatoriesFileSuccess implements Action {
  readonly type = AllConvocatoriesActionTypes.GeTConvocatoriesFileSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetConvocatoriesFileFailure implements Action {
  readonly type = AllConvocatoriesActionTypes.GetConvocatoriesFileFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type ArchivedFinishedActions =
  | GetConvocatoriesFileAction
  | GetConvocatoriesFileSuccess
  | GetConvocatoriesFileFailure

  | GetInterventionAreasAction
  | GetInterventionAreasSuccess
  | GetInterventionAreasFailure

  | GetInterventionLinesAction
  | GetInterventionLinesSuccess
  | GetInterventionLinesFailure;
