import {User, AuthenticateResponse} from '../models/auth.models';
import {AuthActions, AuthActionTypes} from './auth.actions';

export interface AuthState {
  user: User;
  confirmed: boolean;
  authenticate: AuthenticateResponse | undefined;
  pending: boolean;
  error: string | undefined;
}

export const initialState: AuthState = {
  user: undefined,
  confirmed: false,
  authenticate: undefined,
  pending: false,
  error: undefined
};

export function reducer(state: AuthState = initialState, action: AuthActions): AuthState {

  switch (action.type) {
    case AuthActionTypes.Login: {
      return {
        ...state,
        pending: true,
        error: undefined
      };
    }

    case AuthActionTypes.AuthenticationSuccess: {
      window.localStorage.setItem('authenticate', JSON.stringify(action.payload.authenticate));
      return {
        ...state,
        user: action.payload.user ? action.payload.user : state.user,
        confirmed: action.payload.confirmed,
        authenticate: action.payload.authenticate,
        pending: false,
        error: undefined
      };
    }

    case AuthActionTypes.AuthenticationFailure: {
      window.localStorage.removeItem('authenticate');
      return {
        ...state,
        error: action.payload,
        pending: false,
        user: undefined,
        confirmed: false,
        authenticate: undefined
      };
    }

    case AuthActionTypes.LogoutSuccess: {
      window.localStorage.removeItem('authenticate');
      return {
        ...state,
        error: undefined,
        pending: false,
        user: undefined,
        confirmed: false,
        authenticate: undefined
      };
    }

    case AuthActionTypes.AuthenticationVerifyEmailSuccess: {
      return {
        ...state,
        confirmed: true
      };
    }

    case AuthActionTypes.AuthenticationVerifyEmailFailure: {
      return {
        ...state,
        confirmed: false
      };
    }

    case AuthActionTypes.AuthenticationGetProfileSuccess: {
      return {
        ...state,
        user: {
          id: action.payload.resultHeader.body.data.id,
          username: action.payload.resultHeader.body.data.name,
          email: action.payload.resultHeader.body.data.email,
          roles: action.payload.resultHeader.body.data.roles.data
        },
        confirmed: action.payload.resultHeader.body.data.confirmed,
      };
    }

    case AuthActionTypes.AuthenticationSetNameAction: {
      return {
        ...state,
        user:{
          id: state.user.id,
          username: action.payload.name,
          email: state.user.email,
          roles: state.user.roles
        }
      };
    }

    default:
      return state;
  }
}
