import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './specialties.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SpecialtiesEffects} from './specialties.effects';
import {SpecialtiesService} from './specialties.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppSpecialtiesModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppSpecialtiesModule,
      providers: [
        {provide: 'config', useValue: config},
        SpecialtiesService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppSpecialtiesModule,
    StoreModule.forFeature('appSpecialties', reducer),
    EffectsModule.forFeature([SpecialtiesEffects]),
  ],
})
export class RootAppSpecialtiesModule {
}
