import {Component, Input, OnInit} from '@angular/core';
import {SparklineChartOptions} from '../../../index';

@Component({
  selector: 'kt-widget26',
  template: `
    <div class="kt-widget26">
      <div class="kt-widget26__content">
        <span class="kt-widget26__number">{{value}}</span>
        <span class="kt-widget26__desc">{{desc}}</span>
      </div>
      <div class="kt-widget26__chart" style="height:100px; width: 230px;">
        <canvas ktSparklineChart [options]="options" id="kt_chart_quick_stats_1"></canvas>
      </div>
    </div>
  `,
  styleUrls: ['./widget26.component.scss']
})
export class Widget26Component implements OnInit {

  @Input() value: string | number;
  @Input() desc: string;
  @Input() options: SparklineChartOptions;

  constructor() {
  }

  ngOnInit() {
  }

}
