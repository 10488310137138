import {Action} from '@ngrx/store';

export enum PiuActionTypes {
  PiuAddAction = '[AdminPiu]PiuAddAction',
  PiuAddSuccess = '[AdminPiu]PiuAddSuccess',
  PiuAddFailure = '[AdminPiu]PiuAddFailure',

  PiuEnDiAction = '[AdminPiu]PiuEnDiAction',
  PiuEnDiSuccess = '[AdminPiu]PiuEnDiSuccess',
  PiuEnDiFailure = '[AdminPiu]PiuEnDiFailure',
  
}

export class PiuAddAction implements Action {
  readonly type = PiuActionTypes.PiuAddAction;

  constructor(public payload: { data: any }) {
  }
}

export class PiuAddSuccess implements Action {
  readonly type = PiuActionTypes.PiuAddSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PiuAddFailure implements Action {
  readonly type = PiuActionTypes.PiuAddFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PiuEnDiAction implements Action {
  readonly type = PiuActionTypes.PiuEnDiAction;

  constructor(public payload: { id: string }) {
  }
}

export class PiuEnDiSuccess implements Action {
  readonly type = PiuActionTypes.PiuEnDiSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PiuEnDiFailure implements Action {
  readonly type = PiuActionTypes.PiuEnDiFailure;

  constructor(public payload: { validation: any }) {
  }
}



export type PiuActions =
  | PiuAddAction
  | PiuAddSuccess
  | PiuAddFailure
  | PiuEnDiAction
  | PiuEnDiSuccess
  | PiuEnDiFailure;
