import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {PostulationsFilter} from '../phase-first.model';


@Injectable()
export class PhaseFirstService {
	private postulationsFilter = new BehaviorSubject<any>(new PostulationsFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	getEvaluation(id: string){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}?include=users`;
		return this.http.get(apiUrl, { observe: 'response' });
	}
	currentPostulationsFilter(): Observable<any> {
    	return this.postulationsFilter.asObservable();
  	}
  	getPostulationsFilter() {
    	return this.postulationsFilter.getValue();
  	}
  	sendPostulationsFilter(object: any) {
    	this.postulationsFilter.next(object);
  	}
	getPostulations(id: string, postulationsFilter: PostulationsFilter): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/postulations`;

		const params = createRequestOption({
			'include': 'evaluation',
			'page': postulationsFilter.page,
			'limit': postulationsFilter.limit,
			'orderBy': postulationsFilter.orderBy,
			'sortedBy': postulationsFilter.sortedBy,
			'search': postulationsFilter.postulation.search
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	getPostulationsStatus(id: string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}convocatories/${id}/postulations`;
		return this.http.get(apiUrl,{observe: 'response'});
	}
	getPDF(id: string, type:string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/postulations/pdf/${type}/qualification`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
	getExcel(id: string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/postulations/excel/submits`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
	getReport(hash: string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}postulations/${hash}/report/pdf?attached=true`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
	startQualified(evaluation_id: string, postulation_id: string){	
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${evaluation_id}/postulations/${postulation_id}/qualify`;
		return this.http.patch(apiUrl, {}, {observe: 'response'});
	}
	finishPhase(id: string, phase: number){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/phase/${phase}/close/prequalification`;
		return this.http.patch(apiUrl, {}, {observe: 'response'});
	}
}