import {Action} from '@ngrx/store';

export enum DashBoardActionTypes {
  FinalizeAction = '[ConvocatoriesDashboard]FinalizeAction',
  FinalizeSuccess = '[ConvocatoriesDashboard]FinalizeSuccess',
  FinalizeFailure = '[ConvocatoriesDashboard]FinalizeFailure',

  GetConvocatoryDetailAction = '[ConvocatoriesDashboard]GetConvocatoryDetailAction',
  GetConvocatoryDetailSuccess = '[ConvocatoriesDashboard]GetConvocatoryDetailSuccess',
  GetConvocatoryDetailFailure = '[ConvocatoriesDashboard]GetConvocatoryDetailFailure',

  RescheduleConvocatoryAction = '[ConvocatoriesDashboard]RescheduleConvocatoryAction',
  RescheduleConvocatorySuccess = '[ConvocatoriesDashboard]RescheduleConvocatorySuccess',
  RescheduleConvocatoryFailure = '[ConvocatoriesDashboard]RescheduleConvocatoryFailure',

  ArchiveConvocatoryAction = '[ConvocatoriesDashboard]ArchiveConvocatoryAction',
  ArchiveConvocatorySuccess = '[ConvocatoriesDashboard]ArchiveConvocatorySuccess',
  ArchiveConvocatoryFailure = '[ConvocatoriesDashboard]ArchiveConvocatoryFailure',

  UnpublishConvocatoryAction = '[ConvocatoriesDashboard]UnpublishConvocatoryAction',
  UnpublishConvocatorySuccess = '[ConvocatoriesDashboard]UnpublishConvocatorySuccess',
  UnpublishConvocatoryFailure = '[ConvocatoriesDashboard]UnpublishConvocatoryFailure',

  PublishConvocatoryAction = '[ConvocatoriesDashboard]PublishConvocatoryAction',
  PublishConvocatorySuccess = '[ConvocatoriesDashboard]PublishConvocatorySuccess',
  PublishConvocatoryFailure = '[ConvocatoriesDashboard]PublishConvocatoryFailure',

  GetTotalPostulationsAction = '[ConvocatoriesDashboard]GetTotalPostulationsAction',
  GetTotalPostulationsSuccess = '[ConvocatoriesDashboard]GetTotalPostulationsSuccess',
  GetTotalPostulationsFailure = '[ConvocatoriesDashboard]GetTotalPostulationsFailure',

  GetChartPostulationsAction = '[ConvocatoriesDashboard]GetChartPostulationsAction',
  GetChartPostulationsSuccess = '[ConvocatoriesDashboard]GetChartPostulationsSuccess',
  GetChartPostulationsFailure = '[ConvocatoriesDashboard]GetChartPostulationsFailure',

  GetPostulationsAction = '[ConvocatoriesDashboard]GetPostulationsAction',
  GetPostulationsSuccess = '[ConvocatoriesDashboard]GetPostulationsSuccess',
  GetPostulationsFailure = '[ConvocatoriesDashboard]GetPostulationsFailure'
}

export class FinalizeAction implements Action {
  readonly type = DashBoardActionTypes.FinalizeAction;

  constructor(public payload: { id: any }) {
  }
}

export class FinalizeSuccess implements Action {
  readonly type = DashBoardActionTypes.FinalizeSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class FinalizeFailure implements Action {
  readonly type = DashBoardActionTypes.FinalizeFailure;

  constructor(public payload: { validation: any }) {
  }
}


export class GetPostulationsAction implements Action {
  readonly type = DashBoardActionTypes.GetPostulationsAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetPostulationsSuccess implements Action {
  readonly type = DashBoardActionTypes.GetPostulationsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetPostulationsFailure implements Action {
  readonly type = DashBoardActionTypes.GetPostulationsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetConvocatoryDetailAction implements Action {
  readonly type = DashBoardActionTypes.GetConvocatoryDetailAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetConvocatoryDetailSuccess implements Action {
  readonly type = DashBoardActionTypes.GetConvocatoryDetailSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetConvocatoryDetailFailure implements Action {
  readonly type = DashBoardActionTypes.GetConvocatoryDetailFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class RescheduleConvocatoryAction implements Action {
  readonly type = DashBoardActionTypes.RescheduleConvocatoryAction;

  constructor(public payload: { id: any, body: any }) {
  }
}

export class RescheduleConvocatorySuccess implements Action {
  readonly type = DashBoardActionTypes.RescheduleConvocatorySuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class RescheduleConvocatoryFailure implements Action {
  readonly type = DashBoardActionTypes.RescheduleConvocatoryFailure;

  constructor(public payload: { validation: any }) {
  }
}


export class ArchiveConvocatoryAction implements Action {
  readonly type = DashBoardActionTypes.ArchiveConvocatoryAction;

  constructor(public payload: { id: any }) {
  }
}


export class ArchiveConvocatorySuccess implements Action {
  readonly type = DashBoardActionTypes.ArchiveConvocatorySuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ArchiveConvocatoryFailure implements Action {
  readonly type = DashBoardActionTypes.ArchiveConvocatoryFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class UnpublishConvocatoryAction implements Action {
  readonly type = DashBoardActionTypes.UnpublishConvocatoryAction;

  constructor(public payload: { id: any }) {
  }
}

export class UnpublishConvocatorySuccess implements Action {
  readonly type = DashBoardActionTypes.UnpublishConvocatorySuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class UnpublishConvocatoryFailure implements Action {
  readonly type = DashBoardActionTypes.UnpublishConvocatoryFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PublishConvocatoryAction implements Action {
  readonly type = DashBoardActionTypes.PublishConvocatoryAction;

  constructor(public payload: { id: any }) {
  }
}

export class PublishConvocatorySuccess implements Action {
  readonly type = DashBoardActionTypes.PublishConvocatorySuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PublishConvocatoryFailure implements Action {
  readonly type = DashBoardActionTypes.PublishConvocatoryFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetTotalPostulationsAction implements Action {
  readonly type = DashBoardActionTypes.GetTotalPostulationsAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetTotalPostulationsSuccess implements Action {
  readonly type = DashBoardActionTypes.GetTotalPostulationsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetTotalPostulationsFailure implements Action {
  readonly type = DashBoardActionTypes.GetTotalPostulationsFailure;

  constructor(public payload: { validation: any }) {
  }
}


export class GetChartPostulationsAction implements Action {
  readonly type = DashBoardActionTypes.GetChartPostulationsAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetChartPostulationsSuccess implements Action {
  readonly type = DashBoardActionTypes.GetChartPostulationsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetChartPostulationsFailure implements Action {
  readonly type = DashBoardActionTypes.GetChartPostulationsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type DashboardActions =
  | FinalizeAction
  | FinalizeSuccess
  | FinalizeFailure

  | GetConvocatoryDetailAction
  | GetConvocatoryDetailSuccess
  | GetConvocatoryDetailFailure

  | RescheduleConvocatoryAction
  | RescheduleConvocatorySuccess
  | RescheduleConvocatoryFailure

  | ArchiveConvocatoryAction
  | ArchiveConvocatorySuccess
  | ArchiveConvocatoryFailure

  | UnpublishConvocatoryAction
  | UnpublishConvocatorySuccess
  | UnpublishConvocatoryFailure

  | PublishConvocatoryAction
  | PublishConvocatorySuccess
  | PublishConvocatoryFailure

  | GetTotalPostulationsAction
  | GetTotalPostulationsSuccess
  | GetTotalPostulationsFailure

  | GetChartPostulationsAction
  | GetChartPostulationsSuccess
  | GetChartPostulationsFailure;
