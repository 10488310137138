export class IntervencionLine {
  search = '';
}

export class IntervencionLinesFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  intervencionLine: IntervencionLine = new IntervencionLine();
}
