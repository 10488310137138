import {Action} from '@ngrx/store';

export enum LogsActionTypes {
  LogGetTypesAction = '[Evaluators]LogGetTypesAction',
  LogGetTypesSuccess = '[Evaluators]LogGetTypesSuccess',
  LogGetTypesFailure = '[Evaluators]LogGetTypesFailure',

}

export class LogGetTypesAction implements Action {
  readonly type = LogsActionTypes.LogGetTypesAction;
  constructor(public payload: {}){

  }
}

export class LogGetTypesSuccess implements Action {
  readonly type = LogsActionTypes.LogGetTypesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class LogGetTypesFailure implements Action {
  readonly type = LogsActionTypes.LogGetTypesFailure;

  constructor(public payload: { validation: any }) {
  }
}


export type LogsActions =
  | LogGetTypesAction
  | LogGetTypesSuccess
  | LogGetTypesFailure;
