import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {EvaluatorFilter} from '../../../users/evaluators/evaluators.model';

@Injectable()
export class PhaseSecondService {
	private evaluatorsFilter = new BehaviorSubject<any>(new EvaluatorFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	getEvaluators(){
		let apiUrl = `${environment.app.apiBaseUrl}evaluators`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
	sendValues(id: string, value: any){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}?include=users`;
		return this.http.put(apiUrl, value, {observe: 'response'});
	}
	finishPhase(id: string, phase: number){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/phase/2/close`;
		if(phase===3){
			apiUrl+='/evaluationjudge';
		}
		return this.http.patch(apiUrl, {}, {observe: 'response'});
	}
	deleteFile(id: any): Observable<HttpResponse<any>> {
	    return this.http.delete(`${environment.app.apiBaseUrl}files/${id}`, {observe: 'response'});
	}
	/*---evaluators---*/
	currentEvaluatorsFilter(): Observable<any> {
    	return this.evaluatorsFilter.asObservable();
  	}
  	getEvaluatorsFilter() {
    	return this.evaluatorsFilter.getValue();
  	}
  	sendEvaluatorsFilter(object: any) {
    	this.evaluatorsFilter.next(object);
  	}
	getEvaluatorsAsigned(EvaluatorsFilter: EvaluatorFilter, id: string): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/judge`;
		const params = createRequestOption({
			'page': EvaluatorsFilter.page,
			'limit': EvaluatorsFilter.limit,
			'orderBy': EvaluatorsFilter.orderBy,
			'sortedBy': EvaluatorsFilter.sortedBy,
			'search': EvaluatorsFilter.convocatorie.search
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	enableCalification(evaluation: string, user: string){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${evaluation}/enable/calification/judge/${user}`;
		return this.http.patch(apiUrl, {}, {observe: 'response'});
	}
	extendDate(id: string, data: any){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/extend`;
		return this.http.patch(apiUrl, data, {observe: 'response'});	
	}
}