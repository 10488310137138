import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {SectorsService} from './sectors.service';
import * as sectors from './sectors.actions';

@Injectable()
export class SectorsEffects {

  constructor(private actions$: Actions,
              private sectorsService: SectorsService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(sectors.SectorsActionTypes.SectorsCreateAction),
    map((action: sectors.SectorsCreateAction) => action.payload),
    exhaustMap((param: any) =>
      this.sectorsService.createSector(param).pipe(
        map(success => new sectors.SectorsCreateSuccess({resultHeader: success})),
        catchError(err => of(new sectors.SectorsCreateFailure({validation: err})))
      )
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(sectors.SectorsActionTypes.SectorsUpdateAction),
    map((action: sectors.SectorsUpdateAction) => action.payload),
    exhaustMap((param: any) =>
      this.sectorsService.modifySector(param.body, param.id).pipe(
        map(success => new sectors.SectorsUpdateSuccess({resultHeader: success})),
        catchError(err => of(new sectors.SectorsUpdateFailure({validation: err})))
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType(sectors.SectorsActionTypes.SectorsGetByIdAction),
    map((action: sectors.SectorsGetByIdAction) => action.payload),
    exhaustMap((param: any) =>
      this.sectorsService.getByIdSector(param.id).pipe(
        map(success => new sectors.SectorsGetByIdSuccess({resultHeader: success})),
        catchError(err => of(new sectors.SectorsGetByIdFailure({validation: err})))
      )
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(sectors.SectorsActionTypes.SectorsDeleteAction),
    map((action: sectors.SectorsDeleteAction) => action.payload),
    exhaustMap((param: any) =>
      this.sectorsService.deleteSector(param.id).pipe(
        map(success => new sectors.SectorsDeleteSuccess({resultHeader: success})),
        catchError(err => of(new sectors.SectorsDeleteFailure({validation: err})))
      )
    )
  );
}
