import {InterventionLinesActions} from './intervention-lines.actions';

export interface InterventionLinesState {
  dataTable: any;
}

export const initialState: InterventionLinesState = {
  dataTable: undefined
};

export function reducer(state: InterventionLinesState = initialState, action: InterventionLinesActions): InterventionLinesState {

  switch (action.type) {

    default:
      return state;
  }
}
