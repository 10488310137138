import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './archived-finished.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ArchivedFinishedEffects} from './archived-finished.effects';
import {ArchivedFinishedService} from './archived-finished.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppConvocatoriesArchivedFinishedModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppConvocatoriesArchivedFinishedModule,
      providers: [
        {provide: 'config', useValue: config},
        ArchivedFinishedService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppConvocatoriesArchivedFinishedModule,
    StoreModule.forFeature('appConvocatoriesArchivedFinished', reducer),
    EffectsModule.forFeature([ArchivedFinishedEffects]),
  ],
})
export class RootAppConvocatoriesArchivedFinishedModule {
}
