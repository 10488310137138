import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {PostulationsFilter} from '../postulations.model';


@Injectable()
export class PhaseThirdService {
	private postulationsFilter = new BehaviorSubject<any>(new PostulationsFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	currentPostulationsFilter(): Observable<any> {
    	return this.postulationsFilter.asObservable();
  	}
  	getPostulationsFilter() {
    	return this.postulationsFilter.getValue();
  	}
  	sendPostulationsFilter(object: any) {
    	this.postulationsFilter.next(object);
  	}
	getPostulations(id: string, postulationsFilter: PostulationsFilter): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/postulations/calified?calificationOrder=desc`;

		const params = createRequestOption({
			'calificationOrder': 'desc',
			'page': postulationsFilter.page,
			'limit': postulationsFilter.limit,
			'orderBy': postulationsFilter.orderBy,
			'sortedBy': postulationsFilter.sortedBy,
			'search': postulationsFilter.postulation.search
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	getPostulationsStatus(id: string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}convocatories/${id}/postulations`;
		return this.http.get(apiUrl,{observe: 'response'});
	}

	getPDF(id: string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/postulations/pdf/final/califications`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
	getExcel(id: string, data: any): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/postulations/excel/final/califications`;
		let params = createRequestOption();
		if(data.field_name && data.field_budget){
			params = createRequestOption({
				'field_name': data.field_name,
				'field_budget': data.field_budget,
			});
		}else{
			if(data.field_name){
				params = createRequestOption({
					'field_name': data.field_name,
				});	
			}
			if(data.field_budget){
				params = createRequestOption({
					'field_budget': data.field_budget,
				});	
			}
		}
		return this.http.get(apiUrl, {params:params, observe: 'response'});
	}
	setWiner(evaluation_id: string, postulation_id: string){	
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${evaluation_id}/postulations/${postulation_id}/winner`;
		return this.http.patch(apiUrl, {}, {observe: 'response'});
	}
	getDetail(evaluation_id: string, postulation_id: string){	
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${evaluation_id}/postulations/${postulation_id}/detail/califications`;
		return this.http.get(apiUrl, {observe: 'response'});
	}

	finishPhase(id: string, data: any){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/complete`;
		return this.http.post(apiUrl, data, {observe: 'response'});
	}
	getEspecial(id: string){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${id}/postulations/pdf/final/califications/special`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
}