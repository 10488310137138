import {ServiceWorkerModule} from '@angular/service-worker';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GestureConfig, MatProgressSpinnerModule} from '@angular/material';
import {OverlayModule} from '@angular/cdk/overlay';
// NgBootstrap
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// Perfect Scroll bar
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
// SVG inline
import {InlineSVGModule} from 'ng-inline-svg';
// Env
import {environment} from '../environments/environment';
// Hammer JS
import 'hammerjs';
// NGX Permissions
import {NgxPermissionsModule} from 'ngx-permissions';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
// State
import {reducers} from './core/reducers';
import {metaReducers} from './core/reducers/meta';

// Copmponents
import {AppComponent} from './app.component';
// Modules
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
// Partials
import {PartialsModule} from './shared/partials.module';
// Services
import {DataTableService} from './shared/index';
// Layout Services
import {
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
  KtDialogService
} from './shared/index';
// Config
import {LayoutConfig} from './core/config/layout.config';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

// ngrx modules
import {AppAuthModule} from './pages/auth/shared/store/auth.module';
import {AppInterventionAreasModule} from './pages/categories/intervention-areas/store/intervention-areas.module';
import {AppInterventionLinesModule} from './pages/categories/intervention-lines/store/intervention-lines.module';
import {AppSubsectorsModule} from './pages/categories/subsectors/store/subsectors.module';
import {AppSectorsModule} from './pages/categories/sectors/store/sectors.module';
import {AppSpecialtiesModule} from './pages/categories/specialties/store/specialties.module';
import {AppNewEditFormModule} from './pages/forms/new-edit-form/store/new-edit-form.module';
import {AppAllFormsModule} from './pages/forms/all-forms/store/all-forms.module';
import {ToastrModule} from './shared/modules/ngx-toastr/toastr/toastr.module';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {AlertModule} from './shared/modules/alert/alert.module';
import {AlertComponent} from './shared/modules/alert/alert.component';

import {AppConvocatoriesPublishedPastModule} from "./pages/convocatories/published-past/store/published-past.module";
import {AppConvocatoriesDraftsPreparationsModule} from "./pages/convocatories/drafts-preparations/store/drafts-preparations.module";
import {AppConvocatoriesArchivedFinishedModule} from "./pages/convocatories/archived-finished/store/archived-finished.module";

import {AppNewEditConvocatorieModule} from "./pages/convocatories/new-edit-convocatorie/store/new-edit-convocatorie.module";
import {AppConvocatoriesDahsboardModule} from "./pages/convocatories/dashboard/store/dashboard.module";
import {AppConvocatoriesPostulationsModule} from "./pages/convocatories/postulations/store/postulations.module";
import {AppConvocatoriesPostulationDetailModule} from "./pages/convocatories/postulation-detail/store/postulation-detail.module";
import {AppCatalogsModule} from "./pages/categories/catalogs/store/catalogs.module";
import {AppCatalogDetailModule} from "./pages/categories/catalog-detail/store/catalog-detail.module";
import {AppDashboardModule} from "./pages/dashboard/store/dashboard.module";
/*--evaluation--*/
import {AppConvocatoriesModule} from './pages/evaluation/convocatories/store/convocatories.module';
import {AppPhaseFirstModule} from './pages/evaluation/phase-first/store/phase-first.module';
import {AppPhaseSecondModule} from './pages/evaluation/phase-second/store/phase-second.module';
import {AppPhaseThirdModule} from './pages/evaluation/phase-third/store/phase-third.module';
/*--judge--*/
import {AppConvocatoriesJudgeModule} from './pages/judge/convocatories/store/convocatories.module';
import {AppPostulationsJudgeModule} from './pages/judge/postulations/store/postulations.module';
/*--users--*/
import {AppEvaluatorUsersModule} from './pages/users/evaluators/store/evaluators.module';
import {AppProfileUsersModule} from './pages/users/profile/store/profile.module';
import {AppPostulantsUsersModule} from './pages/users/postulants/store/ponstulants.module';
/*--discharge--*/
import {AppConvocatoriesDischargeModule} from './pages/discharge/convocatories/store/convocatories.module';
import {AppPostulationsDischargeModule} from './pages/discharge/postulations/store/postulations.module';
import {AppDischargePostulationModule} from './pages/discharge/detail/store/detail.module';
/*---administrator---*/
import {AppLogsModule} from './pages/administrator/logs/store/logs.module';
import {AppPiuUsersModule} from './pages/administrator/piu/store/piu.module';

const MODULES_NGRX_IMPORTS = [
  AppAuthModule.forRoot(environment.app),
  AppInterventionAreasModule.forRoot(environment.app),
  AppInterventionLinesModule.forRoot(environment.app),
  AppSubsectorsModule.forRoot(environment.app),
  AppSectorsModule.forRoot(environment.app),
  AppSpecialtiesModule.forRoot(environment.app),
  AppNewEditFormModule.forRoot(environment.app),
  AppAllFormsModule.forRoot(environment.app),
  AppConvocatoriesPublishedPastModule.forRoot(environment.app),
  AppConvocatoriesDraftsPreparationsModule.forRoot(environment.app),
  AppConvocatoriesArchivedFinishedModule.forRoot(environment.app),
  AppNewEditConvocatorieModule.forRoot(environment.app),
  AppConvocatoriesDahsboardModule.forRoot(environment.app),
  AppConvocatoriesPostulationsModule.forRoot(environment.app),
  AppConvocatoriesPostulationDetailModule.forRoot(environment.app),
  AppCatalogsModule.forRoot(environment.app),
  AppCatalogDetailModule.forRoot(environment.app),
  AppDashboardModule.forRoot(environment.app),
  AppConvocatoriesModule.forRoot(environment.app),
  AppPhaseFirstModule.forRoot(environment.app),
  AppPhaseSecondModule.forRoot(environment.app),
  AppPhaseThirdModule.forRoot(environment.app),
  AppConvocatoriesJudgeModule.forRoot(environment.app),
  AppPostulationsJudgeModule.forRoot(environment.app),
  AppEvaluatorUsersModule.forRoot(environment.app),
  AppConvocatoriesDischargeModule.forRoot(environment.app),
  AppPostulationsDischargeModule.forRoot(environment.app),
  AppDischargePostulationModule.forRoot(environment.app),
  AppProfileUsersModule.forRoot(environment.app),
  AppPostulantsUsersModule.forRoot(environment.app),
  AppLogsModule.forRoot(environment.app),
  AppPiuUsersModule.forRoot(environment.app),
      
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    NgbModule,
    AlertModule,
    MatProgressSpinnerModule,

    NgxPermissionsModule.forRoot(),
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, {
      metaReducers, 
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      } 
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
    StoreDevtoolsModule.instrument(),
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
    ...MODULES_NGRX_IMPORTS,

    ToastrModule.forRoot()
  ],
  entryComponents: [
    AlertComponent
  ],
  providers: [
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
