import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SubsectorsService} from './subsectors.service';
import * as subsectors from './subsectors.actions';

@Injectable()
export class SubsectorsEffects {

  constructor(private actions$: Actions,
              private subsectorsService: SubsectorsService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(subsectors.SubsectorsActionTypes.SubsectorsCreateAction),
    map((action: subsectors.SubsectorsCreateAction) => action.payload),
    exhaustMap((param: any) =>
      this.subsectorsService.createSubsector(param).pipe(
        map(success => new subsectors.SubsectorsCreateSuccess({resultHeader: success})),
        catchError(err => of(new subsectors.SubsectorsCreateFailure({validation: err})))
      )
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(subsectors.SubsectorsActionTypes.SubsectorsUpdateAction),
    map((action: subsectors.SubsectorsUpdateAction) => action.payload),
    exhaustMap((param: any) =>
      this.subsectorsService.modifySubsector(param.body, param.id).pipe(
        map(success => new subsectors.SubsectorsUpdateSuccess({resultHeader: success})),
        catchError(err => of(new subsectors.SubsectorsUpdateFailure({validation: err})))
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType(subsectors.SubsectorsActionTypes.SubsectorsGetByIdAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: subsectors.SubsectorsGetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.subsectorsService.getByIdSubsector(param.id).pipe(
        map(success => new subsectors.SubsectorsGetByIdSuccess({resultHeader: success})),
        catchError(err => of(new subsectors.SubsectorsGetByIdFailure({validation: err})))
      )
    )
  );


  @Effect()
  delete$ = this.actions$.pipe(
    ofType(subsectors.SubsectorsActionTypes.SubsectorsDeleteAction),
    map((action: subsectors.SubsectorsDeleteAction) => action.payload),
    exhaustMap((param: any) =>
      this.subsectorsService.deleteSubsector(param.id).pipe(
        map(success => new subsectors.SubsectorsDeleteSuccess({resultHeader: success})),
        catchError(err => of(new subsectors.SubsectorsDeleteFailure({validation: err})))
      )
    )
  );
}
