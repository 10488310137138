import {Action} from '@ngrx/store';

export enum DashboardActionTypes {
  GetTotalUsersAction = '[Dashboard]GetTotalUsersAction',
  GetTotalUsersSuccess = '[Dashboard]GetTotalUsersSuccess',
  GetTotalUsersFailure = '[Dashboard]GetTotalUsersFailure',

  GetChartUsersAction = '[Dashboard]GetChartUsersAction',
  GetChartUsersSuccess = '[Dashboard]GetChartUsersSuccess',
  GetChartUsersFailure = '[Dashboard]GetChartUsersFailure',

  GetConvocatoriesByAreaAction = '[Dashboard]GetConvocatoriesByAreaAction',
  GetConvocatoriesByAreaSuccess = '[Dashboard]GetConvocatoriesByAreaSuccess',
  GetConvocatoriesByAreaFailure = '[Dashboard]GetConvocatoriesByAreaFailure',

  GetTotalPostulationsAction = '[Dashboard]GetTotalPostulationsAction',
  GetTotalPostulationsSuccess = '[Dashboard]GetTotalPostulationsSuccess',
  GetTotalPostulationsFailure = '[Dashboard]GetTotalPostulationsFailure',

  GetChartPostulationsAction = '[Dashboard]GetChartPostulationsAction',
  GetChartPostulationsSuccess = '[Dashboard]GetChartPostulationsSuccess',
  GetChartPostulationsFailure = '[Dashboard]GetChartPostulationsFailure',

  GetAreasAction = '[Dashboard]GetAreasAction',
  GetAreasSuccess = '[Dashboard]GetAreasSuccess',
  GetAreasFailure = '[Dashboard]GetAreasFailure'
}

export class GetTotalUsersAction implements Action {
  readonly type = DashboardActionTypes.GetTotalUsersAction;
}

export class GetTotalUsersSuccess implements Action {
  readonly type = DashboardActionTypes.GetTotalUsersSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetTotalUsersFailure implements Action {
  readonly type = DashboardActionTypes.GetTotalUsersFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetChartUsersAction implements Action {
  readonly type = DashboardActionTypes.GetChartUsersAction;
}

export class GetChartUsersSuccess implements Action {
  readonly type = DashboardActionTypes.GetChartUsersSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetChartUsersFailure implements Action {
  readonly type = DashboardActionTypes.GetChartUsersFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetConvocatoriesByAreaAction implements Action {
  readonly type = DashboardActionTypes.GetConvocatoriesByAreaAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetConvocatoriesByAreaSuccess implements Action {
  readonly type = DashboardActionTypes.GetConvocatoriesByAreaSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetConvocatoriesByAreaFailure implements Action {
  readonly type = DashboardActionTypes.GetConvocatoriesByAreaFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetTotalPostulationsAction implements Action {
  readonly type = DashboardActionTypes.GetTotalPostulationsAction;
}

export class GetTotalPostulationsSuccess implements Action {
  readonly type = DashboardActionTypes.GetTotalPostulationsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetTotalPostulationsFailure implements Action {
  readonly type = DashboardActionTypes.GetTotalPostulationsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetChartPostulationsAction implements Action {
  readonly type = DashboardActionTypes.GetChartPostulationsAction;
}

export class GetChartPostulationsSuccess implements Action {
  readonly type = DashboardActionTypes.GetChartPostulationsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetChartPostulationsFailure implements Action {
  readonly type = DashboardActionTypes.GetChartPostulationsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetAreasAction implements Action {
  readonly type = DashboardActionTypes.GetAreasAction;
}

export class GetAreasSuccess implements Action {
  readonly type = DashboardActionTypes.GetAreasSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetAreasFailure implements Action {
  readonly type = DashboardActionTypes.GetAreasFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type DashboardActions =
  | GetTotalUsersAction
  | GetTotalUsersSuccess
  | GetTotalUsersFailure

  | GetChartUsersAction
  | GetChartUsersSuccess
  | GetChartUsersFailure

  | GetConvocatoriesByAreaAction
  | GetConvocatoriesByAreaSuccess
  | GetConvocatoriesByAreaFailure

  | GetTotalPostulationsAction
  | GetTotalPostulationsSuccess
  | GetTotalPostulationsFailure

  | GetChartPostulationsAction
  | GetChartPostulationsSuccess
  | GetChartPostulationsFailure

  | GetAreasAction
  | GetAreasSuccess
  | GetAreasFailure;
