import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PostulationsService} from './postulations.service';
import * as archived_finished from './postulations.actions';

@Injectable()
export class PostulationsEffects {

  constructor(private actions$: Actions,
              private archivedFinishedService: PostulationsService) {
  }

  @Effect()
  getDetailConvocatory$ = this.actions$.pipe(
    ofType(archived_finished.PostulationsActionTypes.GetConvocatoryDetailAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetConvocatoryDetailAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getDetailConvocatory(param.id).pipe(
        map(success => new archived_finished.GetConvocatoryDetailSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetConvocatoryDetailFailure({validation: err})))
      )
    )
  );

  @Effect()
  getTotalPostulations$ = this.actions$.pipe(
    ofType(archived_finished.PostulationsActionTypes.GetTotalPostulationsAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetTotalPostulationsAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getTotalPostulations(param.id).pipe(
        map(success => new archived_finished.GetTotalPostulationsSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetTotalPostulationsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getSubsectors$ = this.actions$.pipe(
    ofType(archived_finished.PostulationsActionTypes.PostulationsGetSubsectorsAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.PostulationsGetSubsectorsAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getSubsectors(param.id).pipe(
        map(success => new archived_finished.PostulationsGetSubsectorsSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.PostulationsGetSubsectorsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getReport$ = this.actions$.pipe(
    ofType(archived_finished.PostulationsActionTypes.GetReportAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetReportAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getReport(param.id).pipe(
        map(success => new archived_finished.GetReportSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetReportFailure({validation: err})))
      )
    )
  );

}
