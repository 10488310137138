import {DashboardActions} from './dashboard.actions';

export interface ConvocatorieState {
  dataTable: any;
}

export const initialState: ConvocatorieState = {
  dataTable: undefined
};

export function reducer(state: ConvocatorieState = initialState, action: DashboardActions): ConvocatorieState {

  switch (action.type) {

    default:
      return state;
  }

}
