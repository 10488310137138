import {NewEditConvocatorieActions, NewEditConvocatorieActionTypes} from './new-edit-convocatorie.actions';

export interface NewEditConvocatorieState {
  fieldTypes: any;
}

export const initialState: NewEditConvocatorieState = {
  fieldTypes: undefined
};

export function reducer(state: NewEditConvocatorieState = initialState, action: NewEditConvocatorieActions): NewEditConvocatorieState {

  switch (action.type) {

    default:
      return state;
  }
}
