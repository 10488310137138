import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from "../../../shared/models/app-config.model";
import {environment} from "../../../../environments/environment";

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  getTotalUsers(): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}dashboard/users/postulant`, {observe: 'response'});
  }

  getChartUsers(): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}dashboard/chart/users/postulant`, {observe: 'response'});
  }

  getConvocatoriesByArea(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}convocatories/area/${id}?group=current`, {observe: 'response'});
  }

  getTotalPostulations(): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}dashboard/postulations/totals`, {observe: 'response'});
  }

  getChartPostulations(): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}dashboard/postulations/chart`, {observe: 'response'});
  }

  getAreas(): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}a/areas_intervention`, {observe: 'response'});
  }

}
