import {Action} from '@ngrx/store';
import {User, AuthenticateByLogin, AuthenticateResponse, AuthenticateByRefreshToken} from '../models/auth.models';

export enum AuthActionTypes {
  Login = '[Auth]Login',
  RefreshToken = '[Auth]RefreshToken',

  LogoutAction = '[Auth]LogoutAction',
  LogoutSuccess = '[Auth]LogoutSuccess',

  LoginRedirect = '[Auth]LoginRedirect',

  AuthenticationSuccess = '[Auth]AuthenticationSuccess',
  AuthenticationFailure = '[Auth]AuthenticationFailure',

  RequestAuthenticationFailure = '[Auth]RequestAuthenticationFailure',
  RequestAuthorizationFailure = '[Auth]RequestAuthorizationFailure',

  AuthenticationFacebookAction = '[Auth]AuthenticationFacebookAction',
  AuthenticationFacebookFailure = '[Auth]AuthenticationFacebookFailure',

  AuthenticationVerifyEmailAction = '[Auth]AuthenticationVerifyEmailAction',
  AuthenticationVerifyEmailSuccess = '[Auth]AuthenticationVerifyEmailSuccess',
  AuthenticationVerifyEmailFailure = '[Auth]AuthenticationVerifyEmailFailure',

  AuthenticationResendEmailAction = '[Auth]AuthenticationResendEmailAction',
  AuthenticationResendEmailSuccess = '[Auth]AuthenticationResendEmailSuccess',
  AuthenticationResendEmailFailure = '[Auth]AuthenticationResendEmailFailure',

  AuthenticationGetProfileAction = '[Auth]AuthenticationGetProfileAction',
  AuthenticationGetProfileSuccess = '[Auth]AuthenticationGetProfileSuccess',
  AuthenticationGetProfileFailure = '[Auth]AuthenticationGetProfileFailure',

  AuthenticationSetNameAction = '[Auth]AuthenticationSetNameAction',

}

export class AuthenticationSetNameAction implements Action {
  readonly type = AuthActionTypes.AuthenticationSetNameAction;

  constructor(public payload: { name: string }) {
  }
}

export class AuthenticationGetProfileAction implements Action {
  readonly type = AuthActionTypes.AuthenticationGetProfileAction;
}

export class AuthenticationGetProfileSuccess implements Action {
  readonly type = AuthActionTypes.AuthenticationGetProfileSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class AuthenticationGetProfileFailure implements Action {
  readonly type = AuthActionTypes.AuthenticationGetProfileFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class AuthenticationResendEmailAction implements Action {
  readonly type = AuthActionTypes.AuthenticationResendEmailAction;

  constructor(public payload: { body: any }) {
  }
}

export class AuthenticationResendEmailSuccess implements Action {
  readonly type = AuthActionTypes.AuthenticationResendEmailSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class AuthenticationResendEmailFailure implements Action {
  readonly type = AuthActionTypes.AuthenticationResendEmailFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class AuthenticationVerifyEmailAction implements Action {
  readonly type = AuthActionTypes.AuthenticationVerifyEmailAction;

  constructor(public payload: { user: any, expires: any, signature: any }) {
  }
}

export class AuthenticationVerifyEmailSuccess implements Action {
  readonly type = AuthActionTypes.AuthenticationVerifyEmailSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class AuthenticationVerifyEmailFailure implements Action {
  readonly type = AuthActionTypes.AuthenticationVerifyEmailFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class RefreshToken implements Action {
  readonly type = AuthActionTypes.RefreshToken;

  constructor(public payload: AuthenticateByRefreshToken) {
  }
}

export class AuthenticationFacebookAction implements Action {
  readonly type = AuthActionTypes.AuthenticationFacebookAction;

  constructor(public payload: { oauth_token: any, verifyurl: any }) {
  }
}

export class AuthenticationFacebookFailure implements Action {
  readonly type = AuthActionTypes.AuthenticationFacebookFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: { credentials: AuthenticateByLogin }) {
  }
}

export class AuthenticationSuccess implements Action {
  readonly type = AuthActionTypes.AuthenticationSuccess;

  constructor(public payload: { user?: User, confirmed: boolean, authenticate: AuthenticateResponse }) {
  }
}

export class AuthenticationFailure implements Action {
  readonly type = AuthActionTypes.AuthenticationFailure;

  constructor(public payload: string) {
  }
}

export class LogoutAction implements Action {
  readonly type = AuthActionTypes.LogoutAction;
}

export class LogoutSuccess implements Action {
  readonly type = AuthActionTypes.LogoutSuccess;
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;

  constructor() {
  }
}

export class RequestAuthenticationFailure implements Action {
  readonly type = AuthActionTypes.RequestAuthenticationFailure;
}

export class RequestAuthorizationFailure implements Action {
  readonly type = AuthActionTypes.RequestAuthorizationFailure;
}

export type AuthActions =
  | Login
  | RefreshToken
  | AuthenticationSuccess
  | AuthenticationFailure

  | LogoutAction
  | LogoutSuccess
  | LoginRedirect

  | RequestAuthenticationFailure
  | RequestAuthorizationFailure

  | AuthenticationFacebookAction
  | AuthenticationFacebookFailure

  | AuthenticationVerifyEmailAction
  | AuthenticationVerifyEmailSuccess
  | AuthenticationVerifyEmailFailure

  | AuthenticationResendEmailAction
  | AuthenticationResendEmailSuccess
  | AuthenticationResendEmailFailure

  | AuthenticationGetProfileAction
  | AuthenticationGetProfileSuccess
  | AuthenticationGetProfileFailure
  | AuthenticationSetNameAction;
