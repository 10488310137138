import {SpecialtiesActions} from './specialties.actions';

export interface SpecialtiesState {
  dataTable: any;
}

export const initialState: SpecialtiesState = {
  dataTable: undefined
};

export function reducer(state: SpecialtiesState = initialState, action: SpecialtiesActions): SpecialtiesState {

  switch (action.type) {

    default:
      return state;
  }
}
