import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {DraftsPreparationsService} from './drafts-preparations.service';
import * as drafts_preparations from './drafts-preparations.actions';

@Injectable()
export class DraftsPreparationsEffects {

  constructor(private actions$: Actions,
              private draftsPreparationsService: DraftsPreparationsService) {
  }

  @Effect()
  file$ = this.actions$.pipe(
    ofType(drafts_preparations.DraftsPreparationsActionTypes.GetConvocatoriesAction),
    map((action: drafts_preparations.GetConvocatoriesAction) => action.payload),
    exhaustMap((param: any) =>
      this.draftsPreparationsService.fileConvocatorie(param.id).pipe(
        map(success => new drafts_preparations.GetConvocatoriesSuccess({resultHeader: success})),
        catchError(err => of(new drafts_preparations.GetConvocatoriesFailure({validation: err})))
      )
    )
  );

  @Effect()
  getInterventionLines$ = this.actions$.pipe(
    ofType(drafts_preparations.DraftsPreparationsActionTypes.GetInterventionLinesAction),
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((param: any) =>
      this.draftsPreparationsService.getInterventionLines().pipe(
        map(success => new drafts_preparations.GetInterventionLinesSuccess({resultHeader: success})),
        catchError(err => of(new drafts_preparations.GetInterventionLinesFailure({validation: err})))
      )
    )
  );

  @Effect()
  getInterventionAreas$ = this.actions$.pipe(
    ofType(drafts_preparations.DraftsPreparationsActionTypes.GetInterventionAreasAction),
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((param: any) =>
      this.draftsPreparationsService.getInterventionAreas().pipe(
        map(success => new drafts_preparations.GetInterventionAreasSuccess({resultHeader: success})),
        catchError(err => of(new drafts_preparations.GetInterventionAreasFailure({validation: err})))
      )
    )
  );

  @Effect()
  archive$ = this.actions$.pipe(
    ofType(drafts_preparations.DraftsPreparationsActionTypes.ArchiveAction),
    map((action: drafts_preparations.ArchiveAction) => action.payload),
    exhaustMap((param: any) =>
      this.draftsPreparationsService.archiveConvocatorie(param.id).pipe(
        map(success => new drafts_preparations.ArchiveSuccess({resultHeader: success})),
        catchError(err => of(new drafts_preparations.ArchiveFailure({validation: err})))
      )
    )
  );

}
