import {Action} from '@ngrx/store';

export enum NewEditConvocatorieActionTypes {
  ConvocatorieCreateAction = '[NewEditConvocatorie]ConvocatorieCreateAction',
  ConvocatorieCreateSuccess = '[NewEditConvocatorie]ConvocatorieCreateSuccess',
  ConvocatorieCreateFailure = '[NewEditConvocatorie]ConvocatorieCreateFailure',

  ConvocatorieUpdateAction = '[NewEditConvocatorie]ConvocatorieUpdateAction',
  ConvocatorieUpdateSuccess = '[NewEditConvocatorie]ConvocatorieUpdateSuccess',
  ConvocatorieUpdateFailure = '[NewEditConvocatorie]ConvocatorieUpdateFailure',

  ConvocatorieGetByIdAction = '[NewEditConvocatorie]ConvocatorieGetByIdAction',
  ConvocatorieGetByIdSuccess = '[NewEditConvocatorie]ConvocatorieGetByIdSuccess',
  ConvocatorieGetByIdFailure = '[NewEditConvocatorie]ConvocatorieGetByIdFailure',

  ConvocatorieGetInterventionAreasAction = '[NewEditConvocatorie]ConvocatorieGetInterventionAreasAction',
  ConvocatorieGetInterventionAreasSuccess = '[NewEditConvocatorie]ConvocatorieGetInterventionAreasSuccess',
  ConvocatorieGetInterventionAreasFailure = '[NewEditConvocatorie]ConvocatorieGetInterventionAreasFailure',

  ConvocatorieGetInterventionLinesAction = '[NewEditConvocatorie]ConvocatorieGetInterventionLinesAction',
  ConvocatorieGetInterventionLinesSuccess = '[NewEditConvocatorie]ConvocatorieGetInterventionLinesSuccess',
  ConvocatorieGetInterventionLinesFailure = '[NewEditConvocatorie]ConvocatorieGetInterventionLinesFailure',

  ConvocatorieGetFormsAction = '[NewEditConvocatorie]ConvocatorieGetFormsAction',
  ConvocatorieGetFormsSuccess = '[NewEditConvocatorie]ConvocatorieGetFormsSuccess',
  ConvocatorieGetFormsFailure = '[NewEditConvocatorie]ConvocatorieGetFormsFailure',

  ConvocatorieGetSectorsAction = '[NewEditConvocatorie]ConvocatorieGetSectorsAction',
  ConvocatorieGetSectorsSuccess = '[NewEditConvocatorie]ConvocatorieGetSectorsSuccess',
  ConvocatorieGetSectorsFailure = '[NewEditConvocatorie]ConvocatorieGetSectorsFailure',

  ConvocatorieGetSpecialitiesAction = '[NewEditConvocatorie]ConvocatorieGetSpecialitiesAction',
  ConvocatorieGetSpecialitiesSuccess = '[NewEditConvocatorie]ConvocatorieGetSpecialitiesSuccess',
  ConvocatorieGetSpecialitiesFailure = '[NewEditConvocatorie]ConvocatorieGetSpecialitiesFailure',

  ConvocatorieGetSubsectorsAction = '[NewEditConvocatorie]ConvocatorieGetSubsectorsAction',
  ConvocatorieGetSubsectorsSuccess = '[NewEditConvocatorie]ConvocatorieGetSubsectorsSuccess',
  ConvocatorieGetSubsectorsFailure = '[NewEditConvocatorie]ConvocatorieGetSubsectorsFailure',

  ConvocatorieDeleteFileAction = '[NewEditConvocatorie]ConvocatorieDeleteFileAction',
  ConvocatorieDeleteFileSuccess = '[NewEditConvocatorie]ConvocatorieDeleteFileSuccess',
  ConvocatorieDeleteFileFailure = '[NewEditConvocatorie]ConvocatorieDeleteFileFailure',

  ConvocatorieGetAllAction = '[NewEditConvocatorie]ConvocatorieGetAllAction',
  ConvocatorieGetAllSuccess = '[NewEditConvocatorie]ConvocatorieGetAllSuccess',
  ConvocatorieGetAllFailure = '[NewEditConvocatorie]ConvocatorieGetAllFailure'

}

export class ConvocatorieDeleteFileAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieDeleteFileAction;

  constructor(public payload: { id: any, type: any, index: any }) {
  }
}

export class ConvocatorieDeleteFileSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieDeleteFileSuccess;

  constructor(public payload: { resultHeader: any, type: any, index: any }) {
  }
}

export class ConvocatorieDeleteFileFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieDeleteFileFailure;

  constructor(public payload: { validation: any, type: any, index: any }) {
  }
}

export class ConvocatorieGetFormsAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetFormsAction;

  constructor(public payload: { id: any }) {
  }
}

export class ConvocatorieGetFormsSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetFormsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieGetFormsFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetFormsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ConvocatorieGetInterventionLinesAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetInterventionLinesAction;
}

export class ConvocatorieGetInterventionLinesSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetInterventionLinesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieGetInterventionLinesFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetInterventionLinesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ConvocatorieGetInterventionAreasAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetInterventionAreasAction;
}

export class ConvocatorieGetInterventionAreasSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetInterventionAreasSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieGetInterventionAreasFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetInterventionAreasFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ConvocatorieCreateAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieCreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class ConvocatorieCreateSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieCreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieCreateFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieCreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ConvocatorieUpdateAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieUpdateAction;

  constructor(public payload: { body: any, id: any, typesave: any }) {
  }
}

export class ConvocatorieUpdateSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieUpdateSuccess;

  constructor(public payload: { resultHeader: any, typesave: any }) {
  }
}

export class ConvocatorieUpdateFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieUpdateFailure;

  constructor(public payload: { validation: any, typesave: any }) {
  }
}

export class ConvocatorieGetByIdAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class ConvocatorieGetByIdSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieGetByIdFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ConvocatorieGetSectorsAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSectorsAction;

  constructor(public payload: { id: any }) {
  }

}

export class ConvocatorieGetSectorsSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSectorsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieGetSectorsFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSectorsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ConvocatorieGetSpecialitiesAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSpecialitiesAction;

  constructor(public payload: { id: any }) {
  }
}

export class ConvocatorieGetSpecialitiesSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSpecialitiesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieGetSpecialitiesFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSpecialitiesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ConvocatorieGetSubsectorsAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSubsectorsAction;

  constructor(public payload: { id: any }) {
  }
}

export class ConvocatorieGetSubsectorsSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSubsectorsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieGetSubsectorsFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetSubsectorsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ConvocatorieGetAllAction implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetAllAction;

  constructor(public payload: { sector: any }) {
  }
}

export class ConvocatorieGetAllSuccess implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetAllSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatorieGetAllFailure implements Action {
  readonly type = NewEditConvocatorieActionTypes.ConvocatorieGetAllFailure;

  constructor(public payload: { validation: any }) {
  }
}



export type NewEditConvocatorieActions =

  | ConvocatorieCreateAction
  | ConvocatorieCreateSuccess
  | ConvocatorieCreateFailure

  | ConvocatorieUpdateAction
  | ConvocatorieUpdateSuccess
  | ConvocatorieUpdateFailure

  | ConvocatorieGetByIdAction
  | ConvocatorieGetByIdSuccess
  | ConvocatorieGetByIdFailure

  | ConvocatorieGetInterventionAreasAction
  | ConvocatorieGetInterventionAreasSuccess
  | ConvocatorieGetInterventionAreasFailure

  | ConvocatorieGetInterventionLinesAction
  | ConvocatorieGetInterventionLinesSuccess
  | ConvocatorieGetInterventionLinesFailure

  | ConvocatorieGetSectorsAction
  | ConvocatorieGetSectorsSuccess
  | ConvocatorieGetSectorsFailure

  | ConvocatorieGetSpecialitiesAction
  | ConvocatorieGetSpecialitiesSuccess
  | ConvocatorieGetSpecialitiesFailure

  | ConvocatorieGetSubsectorsAction
  | ConvocatorieGetSubsectorsSuccess
  | ConvocatorieGetSubsectorsFailure

  | ConvocatorieDeleteFileAction
  | ConvocatorieDeleteFileSuccess
  | ConvocatorieDeleteFileFailure

  | ConvocatorieGetAllAction
  | ConvocatorieGetAllSuccess
  | ConvocatorieGetAllFailure;
