import {
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';

import {storeFreeze} from 'ngrx-store-freeze';
//import {storeLogger} from 'ngrx-store-logger';
import {AppState} from './index';
import {environment} from '../../../environments/environment';

/*export function logger(reducer: ActionReducer<AppState>): any {
  return storeLogger()(reducer);
}*/

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [storeFreeze] : [];
