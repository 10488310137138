import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SpecialtiesService} from './specialties.service';
import * as specialties from './specialties.actions';

@Injectable()
export class SpecialtiesEffects {

  constructor(private actions$: Actions,
              private specialtiesService: SpecialtiesService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(specialties.SpecialtiesActionTypes.SpecialtiesCreateAction),
    map((action: specialties.SpecialtiesCreateAction) => action.payload),
    exhaustMap((param: any) =>
      this.specialtiesService.createSpecialty(param).pipe(
        map(success => new specialties.SpecialtiesCreateSuccess({resultHeader: success})),
        catchError(err => of(new specialties.SpecialtiesCreateFailure({validation: err})))
      )
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(specialties.SpecialtiesActionTypes.SpecialtiesUpdateAction),
    map((action: specialties.SpecialtiesUpdateAction) => action.payload),
    exhaustMap((param: any) =>
      this.specialtiesService.modifySpecialty(param.body, param.id).pipe(
        map(success => new specialties.SpecialtiesUpdateSuccess({resultHeader: success})),
        catchError(err => of(new specialties.SpecialtiesUpdateFailure({validation: err})))
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType(specialties.SpecialtiesActionTypes.SpecialtiesGetByIdAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: specialties.SpecialtiesGetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.specialtiesService.getByIdSpecialty(param.id).pipe(
        map(success => new specialties.SpecialtiesGetByIdSuccess({resultHeader: success})),
        catchError(err => of(new specialties.SpecialtiesGetByIdFailure({validation: err})))
      )
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(specialties.SpecialtiesActionTypes.SpecialtiesDeleteAction),
    map((action: specialties.SpecialtiesDeleteAction) => action.payload),
    exhaustMap((param: any) =>
      this.specialtiesService.deleteSpecialty(param.id).pipe(
        map(success => new specialties.SpecialtiesDeleteSuccess({resultHeader: success})),
        catchError(err => of(new specialties.SpecialtiesDeleteFailure({validation: err})))
      )
    )
  );
}
