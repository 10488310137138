import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {ConvocatoriesFilter} from '../drafts-preparations.model';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class DraftsPreparationsService {

  private urlResource = environment.app.apiBaseUrl + 'convocatories';
  private allConvocatoriesFilter = new BehaviorSubject<any>(new ConvocatoriesFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  sendAllConvocatoriesFilter(object: any) {
    this.allConvocatoriesFilter.next(object);
  }

  currentAllConvocatoriesFilter(): Observable<any> {
    return this.allConvocatoriesFilter.asObservable();
  }

  getAllConvocatoriesFilter() {
    return this.allConvocatoriesFilter.getValue();
  }

  fileConvocatorie(id): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urlResource}/${id}`, {}, {observe: 'response'});
  }

  archiveConvocatorie(id): Observable<HttpResponse<any>> {
    return this.http.patch(`${this.urlResource}/${id}/archive`, {}, {observe: 'response'});
  }

  getInterventionAreas(): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}a/areas_intervention`, {observe: 'response'});
  }

  getInterventionLines(): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}a/lines_intervention`, {observe: 'response'});
  }

  getAllConvocatories(convocatoriesFilter: ConvocatoriesFilter): Observable<HttpResponse<any>> {
    const auxSearch = [];
    convocatoriesFilter.convocatorie.areaIntervention !== '' ?
      auxSearch.push('areaIntervention.code:' + convocatoriesFilter.convocatorie.areaIntervention) : '';
    convocatoriesFilter.convocatorie.lineIntervention !== '' ?
      auxSearch.push('lineIntervention.code:' + convocatoriesFilter.convocatorie.lineIntervention) : '';
    convocatoriesFilter.convocatorie.name !== '' ?
      auxSearch.push('name:' + convocatoriesFilter.convocatorie.name) : '';
    convocatoriesFilter.convocatorie.code !== '' ?
      auxSearch.push('code:' + convocatoriesFilter.convocatorie.code) : '';
    convocatoriesFilter.convocatorie.type !== '' ?
      auxSearch.push('type:' + convocatoriesFilter.convocatorie.type) : '';
    convocatoriesFilter.convocatorie.start_postulation_date !== '' ?
      auxSearch.push('start_postulation_date:' + convocatoriesFilter.convocatorie.start_postulation_date) : '';
    convocatoriesFilter.convocatorie.finish_postulation_date !== '' ?
      auxSearch.push('finish_postulation_date:' + convocatoriesFilter.convocatorie.finish_postulation_date) : '';

    let auxDataSearch = '';
    auxSearch.map((item, i) => auxDataSearch = auxDataSearch + item + (i !== (auxSearch.length - 1) ? ';' : ''));

    const params = createRequestOption({
      page: convocatoriesFilter.page,
      limit: convocatoriesFilter.limit,
      orderBy: convocatoriesFilter.orderBy,
      sortedBy: convocatoriesFilter.sortedBy,
      group: convocatoriesFilter.group,
      searchJoin: 'and',
      search: auxDataSearch
    });
    return this.http.get(`${this.urlResource}`, {params: params, observe: 'response'});
  }
}
