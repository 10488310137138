import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PublishedPastService} from './published-past.service';
import * as published_past from './published-past.actions';

@Injectable()
export class PublishedPastEffects {

  constructor(private actions$: Actions,
              private allConvocatoriesService: PublishedPastService) {
  }

  @Effect()
  file$ = this.actions$.pipe(
    ofType(published_past.PublishedPastActionTypes.GetConvocatoriesAction),
    map((action: published_past.GetConvocatoriesAction) => action.payload),
    exhaustMap((param: any) =>
      this.allConvocatoriesService.fileConvocatorie(param.id).pipe(
        map(success => new published_past.GetConvocatoriesSuccess({resultHeader: success})),
        catchError(err => of(new published_past.GetConvocatoriesFailure({validation: err})))
      )
    )
  );

  @Effect()
  modal$ = this.actions$.pipe(
    ofType(published_past.PublishedPastActionTypes.ModalConvocatoriesAction),
    map((action: published_past.ModalConvocatoriesAction) => action.payload),
    exhaustMap((param: any) =>
      this.allConvocatoriesService.getConvocatoriesModal().pipe(
        map(success => new published_past.ModalConvocatoriesSuccess({resultHeader: success})),
        catchError(err => of(new published_past.ModalConvocatoriesFailure({validation: err})))
      )
    )
  );

  @Effect()
  report$ = this.actions$.pipe(
    ofType(published_past.PublishedPastActionTypes.ReportConvocatoriesAction),
    map((action: published_past.ReportConvocatoriesAction) => action.payload),
    exhaustMap((param: any) =>
      this.allConvocatoriesService.getReport(param.data).pipe(
        map(success => new published_past.ReportConvocatoriesSuccess({resultHeader: success})),
        catchError(err => of(new published_past.ReportConvocatoriesFailure({validation: err})))
      )
    )
  );

  @Effect()
  getInterventionLines$ = this.actions$.pipe(
    ofType(published_past.PublishedPastActionTypes.GetInterventionLinesAction),
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((param: any) =>
      this.allConvocatoriesService.getInterventionLines().pipe(
        map(success => new published_past.GetInterventionLinesSuccess({resultHeader: success})),
        catchError(err => of(new published_past.GetInterventionLinesFailure({validation: err})))
      )
    )
  );

  @Effect()
  getInterventionAreas$ = this.actions$.pipe(
    ofType(published_past.PublishedPastActionTypes.GetInterventionAreasAction),
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((param: any) =>
      this.allConvocatoriesService.getInterventionAreas().pipe(
        map(success => new published_past.GetInterventionAreasSuccess({resultHeader: success})),
        catchError(err => of(new published_past.GetInterventionAreasFailure({validation: err})))
      )
    )
  );

}
