import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './all-forms.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AllFormsEffects} from './all-forms.effects';
import {AllFormsService} from './all-forms.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppAllFormsModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppAllFormsModule,
      providers: [
        {provide: 'config', useValue: config},
        AllFormsService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppAllFormsModule,
    StoreModule.forFeature('appAllForms', reducer),
    EffectsModule.forFeature([AllFormsEffects]),
  ],
})
export class RootAppAllFormsModule {
}
