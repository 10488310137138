import {Action} from '@ngrx/store';

export enum AllFormsActionTypes {
  AllFormsDeleteAction = '[AllForms]AllFormsDeleteAction',
  AllFormsDeleteSuccess = '[AllForms]AllFormsDeleteSuccess',
  AllFormsDeleteFailure = '[AllForms]AllFormsDeleteFailure'
}

export class AllFormsDeleteAction implements Action {
  readonly type = AllFormsActionTypes.AllFormsDeleteAction;

  constructor(public payload: { id: any }) {
  }
}

export class AllFormsDeleteSuccess implements Action {
  readonly type = AllFormsActionTypes.AllFormsDeleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class AllFormsDeleteFailure implements Action {
  readonly type = AllFormsActionTypes.AllFormsDeleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type AllFormsActions =
  | AllFormsDeleteAction
  | AllFormsDeleteSuccess
  | AllFormsDeleteFailure;
