import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './catalog-detail.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CatalogDetailEffects} from './catalog-detail.effects';
import {CatalogDetailService} from './catalog-detail.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppCatalogDetailModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppCatalogDetailModule,
      providers: [
        {provide: 'config', useValue: config},
        CatalogDetailService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppCatalogDetailModule,
    StoreModule.forFeature('appCatalogDetail', reducer),
    EffectsModule.forFeature([CatalogDetailEffects]),
  ],
})

export class RootAppCatalogDetailModule {
}
