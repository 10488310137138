export class Specialty {
  search = '';
}

export class SpecialtyFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  specialty: Specialty = new Specialty();
}
