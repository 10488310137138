export class IntervencionArea {
  search = '';
}

export class IntervencionAreaFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  intervencionArea: IntervencionArea = new IntervencionArea();
}
