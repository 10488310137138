import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './phase-first.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PhaseFirstEffects} from './phase-first.effects';
import {PhaseFirstService} from './phase-first.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppPhaseFirstModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppPhaseFirstModule,
      providers: [
        {provide: 'config', useValue: config},
        PhaseFirstService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppPhaseFirstModule,
    StoreModule.forFeature('appPhaseFirstEvaluation', reducer),
    EffectsModule.forFeature([PhaseFirstEffects]),
  ],
})
export class RootAppPhaseFirstModule {
}
