export const environment = {
  production: false,
  siteName: 'Programa de Interveniones Urbanas',
  app: {
    //apiBaseUrl: 'https://migrate-apipiu.cs3code.com/v1/',
    apiBaseUrl: 'https://api.piuag.ea-cod3.com/v1/',
    verifyUrl: 'https://admin.piuag.ea-cod3.com/',
    apiFacebook: '783525635339798-X',
    enableFacebook: false,
  }
};
