export class Evaluator {
  search = '';
}

export class EvaluatorFilter {
  limit = 10;
  page = 1;
  orderBy = 'created_at';
  sortedBy = 'desc';
  convocatorie: Evaluator = new Evaluator();
}
