export class Postulant {
  search = '';
}

export class PostulantFilter {
  limit = 10;
  page = 1;
  orderBy = 'created_at';
  sortedBy = 'desc';
  convocatorie: Postulant = new Postulant();
}
