export class Postulation {
  search = '';
}

export class PostulationsFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';  
  postulation: Postulation = new Postulation();
}