import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class PostulationDetailService {

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  getConvocatoryDetail(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}convocatories/detail/${id}`, {observe: 'response'});
  }

  getPostulationDetail(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}postulations/${id}`, {observe: 'response'});
  }

}
