import {Action} from '@ngrx/store';

export enum PublishedPastActionTypes {
  GetConvocatoriesAction = '[PublishedPast]GetConvocatoriesAction',
  GetConvocatoriesSuccess = '[PublishedPast]GetConvocatoriesSuccess',
  GetConvocatoriesFailure = '[PublishedPast]GetConvocatoriesFailure',

  ModalConvocatoriesAction = '[PublishedPast]ModalConvocatoriesAction',
  ModalConvocatoriesSuccess = '[PublishedPast]ModalConvocatoriesSuccess',
  ModalConvocatoriesFailure = '[PublishedPast]ModalConvocatoriesFailure',

  ReportConvocatoriesAction = '[PublishedPast]ReportConvocatoriesAction',
  ReportConvocatoriesSuccess = '[PublishedPast]ReportConvocatoriesSuccess',
  ReportConvocatoriesFailure = '[PublishedPast]ReportConvocatoriesFailure',

  GetInterventionAreasAction = '[PublishedPast]GetInterventionAreasAction',
  GetInterventionAreasSuccess = '[PublishedPast]GetInterventionAreasSuccess',
  GetInterventionAreasFailure = '[PublishedPast]GetInterventionAreasFailure',

  GetInterventionLinesAction = '[PublishedPast]GetInterventionLinesAction',
  GetInterventionLinesSuccess = '[PublishedPast]GetInterventionLinesSuccess',
  GetInterventionLinesFailure = '[PublishedPast]GetInterventionLinesFailure'
}

export class GetInterventionAreasAction implements Action {
  readonly type = PublishedPastActionTypes.GetInterventionAreasAction;
}

export class GetInterventionAreasSuccess implements Action {
  readonly type = PublishedPastActionTypes.GetInterventionAreasSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetInterventionAreasFailure implements Action {
  readonly type = PublishedPastActionTypes.GetInterventionAreasFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetInterventionLinesAction implements Action {
  readonly type = PublishedPastActionTypes.GetInterventionLinesAction;
}

export class GetInterventionLinesSuccess implements Action {
  readonly type = PublishedPastActionTypes.GetInterventionLinesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetInterventionLinesFailure implements Action {
  readonly type = PublishedPastActionTypes.GetInterventionLinesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetConvocatoriesAction implements Action {
  readonly type = PublishedPastActionTypes.GetConvocatoriesAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetConvocatoriesSuccess implements Action {
  readonly type = PublishedPastActionTypes.GetConvocatoriesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetConvocatoriesFailure implements Action {
  readonly type = PublishedPastActionTypes.GetConvocatoriesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ModalConvocatoriesAction implements Action {
  readonly type = PublishedPastActionTypes.ModalConvocatoriesAction;

  constructor(public payload: {}) {
  }
}

export class ModalConvocatoriesSuccess implements Action {
  readonly type = PublishedPastActionTypes.ModalConvocatoriesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ModalConvocatoriesFailure implements Action {
  readonly type = PublishedPastActionTypes.ModalConvocatoriesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ReportConvocatoriesAction implements Action {
  readonly type = PublishedPastActionTypes.ReportConvocatoriesAction;

  constructor(public payload: { data: any }) {
  }
}

export class ReportConvocatoriesSuccess implements Action {
  readonly type = PublishedPastActionTypes.ReportConvocatoriesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ReportConvocatoriesFailure implements Action {
  readonly type = PublishedPastActionTypes.ReportConvocatoriesFailure;

  constructor(public payload: { validation: any }) {
  }
}



export type PublishedPastActions =
  | GetConvocatoriesAction
  | GetConvocatoriesSuccess
  | GetConvocatoriesFailure

  | ModalConvocatoriesAction
  | ModalConvocatoriesSuccess
  | ModalConvocatoriesFailure  

  | ReportConvocatoriesAction
  | ReportConvocatoriesSuccess
  | ReportConvocatoriesFailure  
    
  | GetInterventionAreasAction
  | GetInterventionAreasSuccess
  | GetInterventionAreasFailure

  | GetInterventionLinesAction
  | GetInterventionLinesSuccess
  | GetInterventionLinesFailure;
