import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PhaseSecondService} from './phase-second.service';
import * as phaseSecond from './phase-second.actions';

@Injectable()
export class PhaseSecondEffects {

  constructor(private actions$: Actions,
              private phaseSecondService: PhaseSecondService) {
  }

  @Effect()
  sendValues = this.actions$.pipe(
    ofType(phaseSecond.PhaseSecondActionTypes.SaveAction),
    map((action: phaseSecond.SaveAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseSecondService.sendValues(param.id, param.value).pipe(
        map(success => new phaseSecond.SaveSuccess({resultHeader: success})),
        catchError(err => of(new phaseSecond.SaveFailure({validation: err, type: param.type})))
      )
    )
  );

  @Effect()
  submitValues = this.actions$.pipe(
    ofType(phaseSecond.PhaseSecondActionTypes.SubmitAction),
    map((action: phaseSecond.SubmitAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseSecondService.sendValues(param.id, param.value).pipe(
        map(success => new phaseSecond.SubmitSuccess({resultHeader: success})),
        catchError(err => of(new phaseSecond.SubmitFailure({validation: err})))
      )
    )
  );

  @Effect()
  getEvaluators = this.actions$.pipe(
    ofType(phaseSecond.PhaseSecondActionTypes.EvaluatorsAction),
    map((action: phaseSecond.EvaluatorsAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseSecondService.getEvaluators().pipe(
        map(success => new phaseSecond.EvaluatorsSuccess({resultHeader: success})),
        catchError(err => of(new phaseSecond.EvaluatorsFailure({validation: err})))
      )
    )
  );

  @Effect()
  delFile = this.actions$.pipe(
    ofType(phaseSecond.PhaseSecondActionTypes.DeleteFileAction),
    map((action: phaseSecond.DeleteFileAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseSecondService.deleteFile(param.id).pipe(
        map(success => new phaseSecond.DeleteFileSuccess({resultHeader: success})),
        catchError(err => of(new phaseSecond.DeleteFileFailure({validation: err})))
      )
    )
  );

  @Effect()
  enableEvaluator = this.actions$.pipe(
    ofType(phaseSecond.PhaseSecondActionTypes.EnableEvaluatorAction),
    map((action: phaseSecond.EnableEvaluatorAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseSecondService.enableCalification(param.idEvaluation, param.idUser).pipe(
        map(success => new phaseSecond.EnableEvaluatorSuccess({resultHeader: success})),
        catchError(err => of(new phaseSecond.EnableEvaluatorFailure({validation: err})))
      )
    )
  );

  @Effect()
  extendDate = this.actions$.pipe(
    ofType(phaseSecond.PhaseSecondActionTypes.ExtendDateAction),
    map((action: phaseSecond.ExtendDateAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseSecondService.extendDate(param.id, param.data).pipe(
        map(success => new phaseSecond.ExtendDateSuccess({resultHeader: success})),
        catchError(err => of(new phaseSecond.ExtendDateFailure({validation: err})))
      )
    )
  );

  @Effect()
  finishPhase = this.actions$.pipe(
    ofType(phaseSecond.PhaseSecondActionTypes.FinishPhaseAction),
    map((action: phaseSecond.FinishPhaseAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseSecondService.finishPhase(param.id, param.data).pipe(
        map(success => new phaseSecond.FinishPhaseSuccess({resultHeader: success})),
        catchError(err => of(new phaseSecond.FinishPhaseFailure({validation: err})))
      )
    )
  );
  
}
