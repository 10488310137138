import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './drafts-preparations.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DraftsPreparationsEffects} from './drafts-preparations.effects';
import {DraftsPreparationsService} from './drafts-preparations.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppConvocatoriesDraftsPreparationsModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppConvocatoriesDraftsPreparationsModule,
      providers: [
        {provide: 'config', useValue: config},
        DraftsPreparationsService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppConvocatoriesDraftsPreparationsModule,
    StoreModule.forFeature('appConvocatoriesDraftsPreparations', reducer),
    EffectsModule.forFeature([DraftsPreparationsEffects]),
  ],
})
export class RootAppConvocatoriesDraftsPreparationsModule {
}
