import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {DashboardService} from './dashboard.service';
import * as dashboard from './dashboard.actions';

@Injectable()
export class DashboardEffects {

  constructor(private actions$: Actions,
              private dashboardService: DashboardService) {
  }

  @Effect()
  getTotalUsers$ = this.actions$.pipe(
    ofType(dashboard.DashboardActionTypes.GetTotalUsersAction),
    exhaustMap(() =>
      this.dashboardService.getTotalUsers().pipe(
        map(success => new dashboard.GetTotalUsersSuccess({resultHeader: success})),
        catchError(err => of(new dashboard.GetTotalUsersFailure({validation: err})))
      )
    )
  );

  @Effect()
  getChartUsers$ = this.actions$.pipe(
    ofType(dashboard.DashboardActionTypes.GetChartUsersAction),
    exhaustMap(() =>
      this.dashboardService.getChartUsers().pipe(
        map(success => new dashboard.GetChartUsersSuccess({resultHeader: success})),
        catchError(err => of(new dashboard.GetChartUsersFailure({validation: err})))
      )
    )
  );

  @Effect()
  getConvocatoriesByArea$ = this.actions$.pipe(
    ofType(dashboard.DashboardActionTypes.GetConvocatoriesByAreaAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: dashboard.GetConvocatoriesByAreaAction) => action.payload),
    switchMap((param: any) =>
      this.dashboardService.getConvocatoriesByArea(param.id).pipe(
        map(success => new dashboard.GetConvocatoriesByAreaSuccess({resultHeader: success})),
        catchError(err => of(new dashboard.GetConvocatoriesByAreaFailure({validation: err})))
      )
    )
  );

  @Effect()
  getTotalPostulations$ = this.actions$.pipe(
    ofType(dashboard.DashboardActionTypes.GetTotalPostulationsAction),
    exhaustMap(() =>
      this.dashboardService.getTotalPostulations().pipe(
        map(success => new dashboard.GetTotalPostulationsSuccess({resultHeader: success})),
        catchError(err => of(new dashboard.GetTotalPostulationsFailure({validation: err})))
      )
    )
  );


  @Effect()
  getChartPostulations$ = this.actions$.pipe(
    ofType(dashboard.DashboardActionTypes.GetChartPostulationsAction),
    exhaustMap(() =>
      this.dashboardService.getChartPostulations().pipe(
        map(success => new dashboard.GetChartPostulationsSuccess({resultHeader: success})),
        catchError(err => of(new dashboard.GetChartPostulationsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getAreas$ = this.actions$.pipe(
    ofType(dashboard.DashboardActionTypes.GetAreasAction),
    exhaustMap(() =>
      this.dashboardService.getAreas().pipe(
        map(success => new dashboard.GetAreasSuccess({resultHeader: success})),
        catchError(err => of(new dashboard.GetAreasFailure({validation: err})))
      )
    )
  );

}
