import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PiuService} from './piu.service';
import * as piu from './piu.actions';

@Injectable()
export class PiuEffects {

  constructor(private actions$: Actions,
              private piuService: PiuService) {
  }

  @Effect()
  createPiu$ = this.actions$.pipe(
    ofType(piu.PiuActionTypes.PiuAddAction),
    map((action: piu.PiuAddAction) => action.payload),
    exhaustMap((param: any) =>
      this.piuService.createUser(param.data).pipe(
        map(success => new piu.PiuAddSuccess({resultHeader: success})),
        catchError(err => of(new piu.PiuAddFailure({validation: err})))
      )
    )
  );

  @Effect()
  enableDisablePiu$ = this.actions$.pipe(
    ofType(piu.PiuActionTypes.PiuEnDiAction),
    map((action: piu.PiuEnDiAction) => action.payload),
    exhaustMap((param: any) =>
      this.piuService.enableDisable(param.id).pipe(
        map(success => new piu.PiuEnDiSuccess({resultHeader: success})),
        catchError(err => of(new piu.PiuEnDiFailure({validation: err})))
      )
    )
  );

  
}
