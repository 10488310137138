import {Action} from '@ngrx/store';

export enum PhaseFirstActionTypes {
  EvaluationGetAction = '[PhaseFirst]EvaluationGetAction',
  EvaluationGetSuccess = '[PhaseFirst]EvaluationGetSuccess',
  EvaluationGetFailure = '[PhaseFirst]EvaluationGetFailure',

  EvaluationTotalsAction = '[PhaseFirst]EvaluationTotalsAction',
  EvaluationTotalsSuccess = '[PhaseFirst]EvaluationTotalsSuccess',
  EvaluationTotalsFailure = '[PhaseFirst]EvaluationTotalsFailure',

  EvaluationPDfAction = '[PhaseFirst]EvaluationPDfAction',
  EvaluationPDfSuccess = '[PhaseFirst]EvaluationPDfSuccess',
  EvaluationPDfFailure = '[PhaseFirst]EvaluationPDfFailure',

  EvaluationExcelAction = '[PhaseFirst]EvaluationExcelAction',
  EvaluationExcelSuccess = '[PhaseFirst]EvaluationExcelSuccess',
  EvaluationExcelFailure = '[PhaseFirst]EvaluationExcelFailure',

  PostulationReportAction = '[PhaseFirst]PostulationReportAction',
  PostulationReportSuccess = '[PhaseFirst]PostulationReportSuccess',
  PostulationReportFailure = '[PhaseFirst]PostulationReportFailure',

  PostulationQualifiedAction = '[PhaseFirst]PostulationQualifiedAction',
  PostulationQualifiedSuccess = '[PhaseFirst]PostulationQualifiedSuccess',
  PostulationQualifiedFailure = '[PhaseFirst]PostulationQualifiedFailure',

  PhaseFirstEndAction = '[PhaseFirst]PhaseFirstEndAction',
  PhaseFirstEndSuccess = '[PhaseFirst]PhaseFirstEndSuccess',
  PhaseFirstEndFailure = '[PhaseFirst]PhaseFirstEndFailure',
}

export class EvaluationGetAction implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationGetAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationGetSuccess implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationGetSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationGetFailure implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationGetFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationTotalsAction implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationTotalsAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationTotalsSuccess implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationTotalsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationTotalsFailure implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationTotalsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationPDfAction implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationPDfAction;

  constructor(public payload: { id: any, type: any }) {
  }
}

export class EvaluationPDfSuccess implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationPDfSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationPDfFailure implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationPDfFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationExcelAction implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationExcelAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationExcelSuccess implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationExcelSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationExcelFailure implements Action {
  readonly type = PhaseFirstActionTypes.EvaluationExcelFailure;

  constructor(public payload: { validation: any }) {
  }
}


export class PostulationReportAction implements Action {
  readonly type = PhaseFirstActionTypes.PostulationReportAction;

  constructor(public payload: { hash: any }) {
  }
}

export class PostulationReportSuccess implements Action {
  readonly type = PhaseFirstActionTypes.PostulationReportSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationReportFailure implements Action {
  readonly type = PhaseFirstActionTypes.PostulationReportFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationQualifiedAction implements Action {
  readonly type = PhaseFirstActionTypes.PostulationQualifiedAction;

  constructor(public payload: { evaluation_id: any, postulation_id: any }) {
  }
}

export class PostulationQualifiedSuccess implements Action {
  readonly type = PhaseFirstActionTypes.PostulationQualifiedSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationQualifiedFailure implements Action {
  readonly type = PhaseFirstActionTypes.PostulationQualifiedFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PhaseFirstEndAction implements Action {
  readonly type = PhaseFirstActionTypes.PhaseFirstEndAction;

  constructor(public payload: { id: any, phase: any }) {
  }
}

export class PhaseFirstEndSuccess implements Action {
  readonly type = PhaseFirstActionTypes.PhaseFirstEndSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PhaseFirstEndFailure implements Action {
  readonly type = PhaseFirstActionTypes.PhaseFirstEndFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type PhaseFirstActions =
  | EvaluationGetAction
  | EvaluationGetSuccess
  | EvaluationGetFailure
  | EvaluationTotalsAction
  | EvaluationTotalsSuccess
  | EvaluationTotalsFailure
  | EvaluationPDfAction
  | EvaluationPDfSuccess
  | EvaluationPDfFailure
  | EvaluationExcelAction
  | EvaluationExcelSuccess
  | EvaluationExcelFailure
  | PostulationReportAction
  | PostulationReportSuccess
  | PostulationReportFailure
  | PostulationQualifiedAction
  | PostulationQualifiedSuccess
  | PostulationQualifiedFailure
  | PhaseFirstEndAction
  | PhaseFirstEndSuccess
  | PhaseFirstEndFailure;
