import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class ProfileService {
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	sendPerfil(id: string, name: string){
		let apiUrl = `${environment.app.apiBaseUrl}users/account/data/${id}`;
		let iForm:any = {name: name};
		const params = new HttpParams({
        	fromObject: iForm
        });
		const httpOptions = {
		  	headers: new HttpHeaders({
		  		'Accept':'application/json',
		    	'Content-Type':  'application/x-www-form-urlencoded'
		  	}),
		  	observe: 'response' as 'body'
		};
		return this.http.put(apiUrl, params, httpOptions);
	}
	sendPassword(id: string, oldpassword: string, password: string){
		let apiUrl = `${environment.app.apiBaseUrl}users/account/password/${id}`;
		let iForm:any = {
			old_password: oldpassword,
			new_password: password,
		};
		const params = new HttpParams({
        	fromObject: iForm
        });
        const httpOptions = {
		  	headers: new HttpHeaders({
		  		'Accept':'application/json',
		    	'Content-Type':  'application/x-www-form-urlencoded'
		  	}),
		  	observe: 'response' as 'body'
		};
		return this.http.put(apiUrl, params, httpOptions);
	}
}