import {Action} from '@ngrx/store';

export enum CatalogsActionTypes {
  CreateAction = '[Catalogs]CreateAction',
  CreateSuccess = '[Catalogs]CreateSuccess',
  CreateFailure = '[Catalogs]CreateFailure',

  UpdateAction = '[Catalogs]UpdateAction',
  UpdateSuccess = '[Catalogs]UpdateSuccess',
  UpdateFailure = '[Catalogs]UpdateFailure',

  DeleteAction = '[Catalogs]DeleteAction',
  DeleteSuccess = '[Catalogs]DeleteSuccess',
  DeleteFailure = '[Catalogs]DeleteFailure',

  GetByIdAction = '[Catalogs]GetByIdAction',
  GetByIdSuccess = '[Catalogs]GetByIdSuccess',
  GetByIdFailure = '[Catalogs]GetByIdFailure'
}

export class CreateAction implements Action {
  readonly type = CatalogsActionTypes.CreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class CreateSuccess implements Action {
  readonly type = CatalogsActionTypes.CreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class CreateFailure implements Action {
  readonly type = CatalogsActionTypes.CreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class UpdateAction implements Action {
  readonly type = CatalogsActionTypes.UpdateAction;

  constructor(public payload: { body: any, id: any }) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = CatalogsActionTypes.UpdateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class UpdateFailure implements Action {
  readonly type = CatalogsActionTypes.UpdateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class DeleteAction implements Action {
  readonly type = CatalogsActionTypes.DeleteAction;

  constructor(public payload: { id: any }) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = CatalogsActionTypes.DeleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class DeleteFailure implements Action {
  readonly type = CatalogsActionTypes.DeleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetByIdAction implements Action {
  readonly type = CatalogsActionTypes.GetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetByIdSuccess implements Action {
  readonly type = CatalogsActionTypes.GetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetByIdFailure implements Action {
  readonly type = CatalogsActionTypes.GetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type CatalogsActions =
  | CreateAction
  | CreateSuccess
  | CreateFailure

  | UpdateAction
  | UpdateSuccess
  | UpdateFailure

  | DeleteAction
  | DeleteSuccess
  | DeleteFailure

  | GetByIdAction
  | GetByIdSuccess
  | GetByIdFailure;
