import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './phase-second.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PhaseSecondEffects} from './phase-second.effects';
import {PhaseSecondService} from './phase-second.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppPhaseSecondModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppPhaseSecondModule,
      providers: [
        {provide: 'config', useValue: config},
        PhaseSecondService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppPhaseSecondModule,
    StoreModule.forFeature('appPhaseSecondEvaluation', reducer),
    EffectsModule.forFeature([PhaseSecondEffects]),
  ],
})
export class RootAppPhaseSecondModule {
}
