import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LogsService} from './logs.service';
import * as logs from './logs.actions';

@Injectable()
export class LogsEffects {

  constructor(private actions$: Actions,
              private logsService: LogsService) {
  }

  @Effect()
  getType$ = this.actions$.pipe(
    ofType(logs.LogsActionTypes.LogGetTypesAction),
    map((action: logs.LogGetTypesAction) => action.payload),
    exhaustMap((param: any) =>
      this.logsService.getActivities().pipe(
        map(success => new logs.LogGetTypesSuccess({resultHeader: success})),
        catchError(err => of(new logs.LogGetTypesFailure({validation: err})))
      )
    )
  );

}
