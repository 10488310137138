import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ConvocatoriesService} from './convocatories.service';
import * as convocatories from './convocatories.actions';

@Injectable()
export class ConvocatoriesEffects {

  constructor(private actions$: Actions,
              private convocatoriesService: ConvocatoriesService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(convocatories.ConvocatoriesActionTypes.ConvocatoriesStartAction),
    map((action: convocatories.ConvocatoriesStartAction) => action.payload),
    exhaustMap((param: any) =>
      this.convocatoriesService.startEvaluation(param.id).pipe(
        map(success => new convocatories.ConvocatoriesStartSuccess({resultHeader: success})),
        catchError(err => of(new convocatories.ConvocatoriesStartFailure({validation: err})))
      )
    )
  );
}
