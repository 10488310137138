import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './phase-third.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PhaseThirdEffects} from './phase-third.effects';
import {PhaseThirdService} from './phase-third.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppPhaseThirdModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppPhaseThirdModule,
      providers: [
        {provide: 'config', useValue: config},
        PhaseThirdService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppPhaseThirdModule,
    StoreModule.forFeature('appPhaseThirdEvaluation', reducer),
    EffectsModule.forFeature([PhaseThirdEffects]),
  ],
})
export class RootAppPhaseThirdModule {
}
