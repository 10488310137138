import {Action} from '@ngrx/store';

export enum PhaseSecondActionTypes {
  SaveAction = '[PhaseSecond]SaveAction',
  SaveSuccess = '[PhaseSecond]SaveSuccess',
  SaveFailure = '[PhaseSecond]SaveFailure',

  SubmitAction = '[PhaseSecond]SubmitAction',
  SubmitSuccess = '[PhaseSecond]SubmitSuccess',
  SubmitFailure = '[PhaseSecond]SubmitFailure',

  EvaluatorsAction = '[PhaseSecond]EvaluatorsAction',
  EvaluatorsSuccess = '[PhaseSecond]EvaluatorsSuccess',
  EvaluatorsFailure = '[PhaseSecond]EvaluatorsFailure',

  DeleteFileAction = '[PhaseSecond]DeleteFileAction',
  DeleteFileSuccess = '[PhaseSecond]DeleteFileSuccess',
  DeleteFileFailure = '[PhaseSecond]DeleteFileFailure',

  EnableEvaluatorAction = '[PhaseSecond]EnableEvaluatorAction',
  EnableEvaluatorSuccess = '[PhaseSecond]EnableEvaluatorSuccess',
  EnableEvaluatorFailure = '[PhaseSecond]EnableEvaluatorFailure',

  ExtendDateAction = '[PhaseSecond]ExtendDateAction',
  ExtendDateSuccess = '[PhaseSecond]ExtendDateSuccess',
  ExtendDateFailure = '[PhaseSecond]ExtendDateFailure',

  FinishPhaseAction = '[PhaseSecond]FinishPhaseAction',
  FinishPhaseSuccess = '[PhaseSecond]FinishPhaseSuccess',
  FinishPhaseFailure = '[PhaseSecond]FinishPhaseFailure',
}

export class SaveAction implements Action {
  readonly type = PhaseSecondActionTypes.SaveAction;

  constructor(public payload: { id: string, value: any, type: string }) {
  }
}

export class SaveSuccess implements Action {
  readonly type = PhaseSecondActionTypes.SaveSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SaveFailure implements Action {
  readonly type = PhaseSecondActionTypes.SaveFailure;

  constructor(public payload: { validation: any, type: string }) {
  }
}

export class SubmitAction implements Action {
  readonly type = PhaseSecondActionTypes.SubmitAction;

  constructor(public payload: { id: string, value: any }) {
  }
}

export class SubmitSuccess implements Action {
  readonly type = PhaseSecondActionTypes.SubmitSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SubmitFailure implements Action {
  readonly type = PhaseSecondActionTypes.SubmitFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluatorsAction implements Action {
  readonly type = PhaseSecondActionTypes.EvaluatorsAction;

  constructor(public payload: {}) {
  }
}

export class EvaluatorsSuccess implements Action {
  readonly type = PhaseSecondActionTypes.EvaluatorsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluatorsFailure implements Action {
  readonly type = PhaseSecondActionTypes.EvaluatorsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class DeleteFileAction implements Action {
  readonly type = PhaseSecondActionTypes.DeleteFileAction;

  constructor(public payload: {id:any}) {
  }
}

export class DeleteFileSuccess implements Action {
  readonly type = PhaseSecondActionTypes.DeleteFileSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class DeleteFileFailure implements Action {
  readonly type = PhaseSecondActionTypes.DeleteFileFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EnableEvaluatorAction implements Action {
  readonly type = PhaseSecondActionTypes.EnableEvaluatorAction;

  constructor(public payload: {idEvaluation: string, idUser: string}) {
  }
}

export class EnableEvaluatorSuccess implements Action {
  readonly type = PhaseSecondActionTypes.EnableEvaluatorSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EnableEvaluatorFailure implements Action {
  readonly type = PhaseSecondActionTypes.EnableEvaluatorFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ExtendDateAction implements Action {
  readonly type = PhaseSecondActionTypes.ExtendDateAction;

  constructor(public payload: {id: string, data: any}) {
  }
}

export class ExtendDateSuccess implements Action {
  readonly type = PhaseSecondActionTypes.ExtendDateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ExtendDateFailure implements Action {
  readonly type = PhaseSecondActionTypes.ExtendDateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class FinishPhaseAction implements Action {
  readonly type = PhaseSecondActionTypes.FinishPhaseAction;

  constructor(public payload: {id: string, data: any}) {
  }
}

export class FinishPhaseSuccess implements Action {
  readonly type = PhaseSecondActionTypes.FinishPhaseSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class FinishPhaseFailure implements Action {
  readonly type = PhaseSecondActionTypes.FinishPhaseFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type PhaseSecondActions =
  | SaveAction
  | SaveSuccess
  | SaveFailure
  | SubmitAction
  | SubmitSuccess
  | SubmitFailure
  | EvaluatorsAction
  | EvaluatorsSuccess
  | EvaluatorsFailure
  | DeleteFileAction
  | DeleteFileSuccess
  | DeleteFileFailure
  | EnableEvaluatorAction
  | EnableEvaluatorSuccess
  | EnableEvaluatorFailure
  | ExtendDateAction
  | ExtendDateSuccess
  | ExtendDateFailure
  | FinishPhaseAction
  | FinishPhaseSuccess
  | FinishPhaseFailure;