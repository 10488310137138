export class AllForms {
  search = '';
}

export class AllFormsFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  allForms: AllForms = new AllForms();
}
