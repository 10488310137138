import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {NewEditFormService} from './new-edit-form.service';
import * as new_edit_form from '../../new-edit-form/store/new-edit-form.actions';

@Injectable()
export class NewEditFormEffects {

  constructor(private actions$: Actions,
              private newEditFormService: NewEditFormService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(new_edit_form.NewEditFormActionTypes.FormCreateAction),
    map((action: new_edit_form.FormCreateAction) => action.payload),
    exhaustMap((param: any) =>
      this.newEditFormService.createForm(param).pipe(
        map(success => new new_edit_form.FormCreateSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_form.FormCreateFailure({validation: err})))
      )
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(new_edit_form.NewEditFormActionTypes.FormUpdateAction),
    map((action: new_edit_form.FormUpdateAction) => action.payload),
    exhaustMap((param: any) =>
      this.newEditFormService.modifyForm(param.body, param.id).pipe(
        map(success => new new_edit_form.FormUpdateSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_form.FormUpdateFailure({validation: err})))
      )
    )
  );

  @Effect()
  getAllSubForms$ = this.actions$.pipe(
    ofType(new_edit_form.NewEditFormActionTypes.FormGetAllSubFormsAction),
    exhaustMap((param: any) =>
      this.newEditFormService.getAllSubForms().pipe(
        map(success => new new_edit_form.FormGetAllSubFormsSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_form.FormGetAllSubFormsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType(new_edit_form.NewEditFormActionTypes.FormGetByIdAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: new_edit_form.FormGetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.newEditFormService.getByIdForm(param.id).pipe(
        map(success => new new_edit_form.FormGetByIdSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_form.FormGetByIdFailure({validation: err.error})))
      )
    )
  );

  @Effect()
  fieldTypes$ = this.actions$.pipe(
    ofType(new_edit_form.NewEditFormActionTypes.FormFieldTypesAction),
    exhaustMap(() =>
      this.newEditFormService.getAllFieldTypes().pipe(
        map(success => new new_edit_form.FormFieldTypesSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_form.FormFieldTypesFailure({validation: err})))
      )
    )
  );

  @Effect()
  getCatalogs$ = this.actions$.pipe(
    ofType(new_edit_form.NewEditFormActionTypes.FormGetCatalogsAction),
    exhaustMap(() =>
      this.newEditFormService.getCatalogs().pipe(
        map(success => new new_edit_form.FormGetCatalogsSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_form.FormGetCatalogsFailure({validation: err})))
      )
    )
  );
}
