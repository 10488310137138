import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpEvent} from '@angular/common/http';
import {Store, ActionsSubject} from '@ngrx/store';
import {throwError, Observable} from 'rxjs';
import {tap, catchError, withLatestFrom, exhaustMap, finalize, map} from 'rxjs/operators';
import {ofType} from '@ngrx/effects';
import {RequestAuthenticationFailure, AuthActionTypes} from '../store/auth.actions';
import {Router} from '@angular/router';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  isRefreshingToken: boolean = false;
  userConfirmed: boolean;

  constructor(private store: Store<any>,
              private router: Router,
              private actions: ActionsSubject) {
    this.store.select(s => s.auth.confirmed).pipe(tap(confirmed => this.userConfirmed = confirmed)).subscribe();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req)).pipe(
      map(res => {
        if (window.localStorage.getItem('authenticate') &&
          (!this.userConfirmed || this.userConfirmed + '' === 'false') &&
          req.url.indexOf('verify') === -1) {
          this.router.navigate(['/admin/reenviar']);
        }
        return res;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && (<HttpErrorResponse>error).status == 401) {
          return this.handle401Error(req, next);
        } else {
          return throwError(error);
        }
      }));
  }

  addToken(req: HttpRequest<any>): HttpRequest<any> {
    if (!window.localStorage.getItem('authenticate'))
      return req;
    return req.clone({setHeaders: {Authorization: 'Bearer ' + this.loadtoken()}});
  }

  loadtoken() {
    return JSON.parse(window.localStorage.getItem('authenticate')).authToken;
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.store.dispatch(new RequestAuthenticationFailure());
    }

    return this.actions.pipe(
      ofType(AuthActionTypes.AuthenticationSuccess, AuthActionTypes.AuthenticationFailure),
      withLatestFrom(this.store),
      exhaustMap(([action, storeState]) => {
        if ((<any>action).type == AuthActionTypes.AuthenticationSuccess) {
          return next.handle(this.addToken(req));
        } else {
          return throwError(new HttpResponse({status: 401}));
        }
      }),
      finalize(() => {
        this.isRefreshingToken = false;
      }));
  }
}
