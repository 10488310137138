import {Component, OnInit, Input} from '@angular/core';
// RxJS
import {Observable} from 'rxjs';
// NGRX
import {select, Store} from '@ngrx/store';
// State
import {AppState} from '../../../../core/reducers';

import * as AuthActions from '../../../../pages/auth/shared/store/auth.actions';
import {shareReplay} from 'rxjs/operators';

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {

  user$: Observable<any>;

  @Input() showAvatar: boolean = true;
  @Input() showHi: boolean = true;
  @Input() showBadge: boolean = true;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.user$ = this.store.select(s => s.auth.user).pipe(shareReplay());
  }

  logout() {
    this.store.dispatch(
      new AuthActions.LogoutAction());
  }
}
