import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from "../../../../shared/utils/request-util";
import {PostulationsFilter} from "../postulations.model";

@Injectable()
export class PostulationsService {

  private filter = new BehaviorSubject<any>(new PostulationsFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  sendFilter(object: any) {
    this.filter.next(object);
  }

  currentFilter(): Observable<any> {
    return this.filter.asObservable();
  }

  getFilter() {
    return this.filter.getValue();
  }

  getDetailConvocatory(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}convocatories/detail/${id}`, {observe: 'response'});
  }

  getSubsectors(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}a/sectors/${id}?include=subsectors`, {observe: 'response'});
  }

  getReport(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}postulations/${id}/report/pdf?attached=true`, {observe: 'response'});
  }

  getTotalPostulations(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}convocatories/${id}/postulations`, {observe: 'response'});
  }

  getAllPostulations(id: any, postulationsFilter: PostulationsFilter): Observable<HttpResponse<any>> {
    const auxSearch = [];

    postulationsFilter.postulation.sector && postulationsFilter.postulation.sector !== '' ?
      auxSearch.push('sector.code:' + postulationsFilter.postulation.sector) : '';
    postulationsFilter.postulation.subsector && postulationsFilter.postulation.subsector !== '' ?
      auxSearch.push('subsector.code:' + postulationsFilter.postulation.subsector) : '';
    postulationsFilter.postulation.user && postulationsFilter.postulation.user !== '' ?
      auxSearch.push('perfilPerson.user.name:' + postulationsFilter.postulation.user) : '';
    postulationsFilter.postulation.code && postulationsFilter.postulation.code !== '' ?
      auxSearch.push('hash:' + postulationsFilter.postulation.code) : '';
    postulationsFilter.postulation.type && postulationsFilter.postulation.type !== '' ?
      auxSearch.push('perfilPerson.type:' + postulationsFilter.postulation.type) : '';
    postulationsFilter.postulation.postulation_date && postulationsFilter.postulation.postulation_date !== '' ?
      auxSearch.push('postulation_date:' + postulationsFilter.postulation.postulation_date) : '';
    postulationsFilter.postulation.updated_at && postulationsFilter.postulation.updated_at !== '' ?
      auxSearch.push('updated_at:' + postulationsFilter.postulation.updated_at) : '';

    const params = createRequestOption({
      page: postulationsFilter.page,
      limit: postulationsFilter.limit,
      orderBy: postulationsFilter.orderBy,
      sortedBy: postulationsFilter.sortedBy,
      searchByStatus: postulationsFilter.postulation.state,
      search: auxSearch.toLocaleString().replace(',', ';')
    });
    return this.http.get(`${environment.app.apiBaseUrl}postulations/convocatory/${id}?include=user`, {
      params: params,
      observe: 'response'
    });
  }

}
