import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {CatalogDetailFilter} from '../catalog-detail.model';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class CatalogDetailService {

  private filter = new BehaviorSubject<any>(new CatalogDetailFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  sendFilter(object: any) {
    this.filter.next(object);
  }

  currentFilter(): Observable<any> {
    return this.filter.asObservable();
  }

  getFilter() {
    return this.filter.getValue();
  }

  createCatalogDetail(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.app.apiBaseUrl}catalog_items`, body, {observe: 'response'});
  }

  deleteCatalogDetail(id): Observable<HttpResponse<any>> {
    return this.http.delete(`${environment.app.apiBaseUrl}catalog_items/${id}`, {observe: 'response'});
  }

  modifyCatalogDetail(body: any, id: any): Observable<HttpResponse<any>> {
    return this.http.put(`${environment.app.apiBaseUrl}catalog_items/${id}`, body, {observe: 'response'});
  }

  getByIdCatalogDetail(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}catalog_items/${id}`, {observe: 'response'});
  }

  getAllCatalogs(): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}catalogs`, {observe: 'response'});
  }

  getAllCatalogDetails(catalogDetailFilter: CatalogDetailFilter): Observable<HttpResponse<any>> {
    const params = createRequestOption({
      'page': catalogDetailFilter.page,
      'limit': catalogDetailFilter.limit,
      'orderBy': catalogDetailFilter.orderBy,
      'sortedBy': catalogDetailFilter.sortedBy,
      'search': catalogDetailFilter.catalogDetail.search
    });
    return this.http.get(`${environment.app.apiBaseUrl}catalog_items/catalog/${catalogDetailFilter.code}`, {params: params, observe: 'response'});
  }

}
