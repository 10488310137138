import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './intervention-lines.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {InterventionLinesEffects} from './intervention-lines.effects';
import {InterventionLinesService} from './intervention-lines.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppInterventionLinesModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppInterventionLinesModule,
      providers: [
        {provide: 'config', useValue: config},
        InterventionLinesService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppInterventionLinesModule,
    StoreModule.forFeature('appInterventionLines', reducer),
    EffectsModule.forFeature([InterventionLinesEffects]),
  ],
})
export class RootAppInterventionLinesModule {
}
