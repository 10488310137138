import {Action} from '@ngrx/store';

export enum PostulationsJudgeActionTypes {
  EvaluationGetAction = '[PostulationsJudge]EvaluationGetAction',
  EvaluationGetSuccess = '[PostulationsJudge]EvaluationGetSuccess',
  EvaluationGetFailure = '[PostulationsJudge]EvaluationGetFailure',

  EvaluationTotalsAction = '[PostulationsJudge]EvaluationTotalsAction',
  EvaluationTotalsSuccess = '[PostulationsJudge]EvaluationTotalsSuccess',
  EvaluationTotalsFailure = '[PostulationsJudge]EvaluationTotalsFailure',

  PostulationQualifyAction = '[PostulationsJudge]PostulationQualifyAction',
  PostulationQualifySuccess = '[PostulationsJudge]PostulationQualifySuccess',
  PostulationQualifyFailure = '[PostulationsJudge]PostulationQualifyFailure',

  PostulationQualifySetAction = '[PostulationsJudge]PostulationQualifySetAction',
  PostulationQualifySetSuccess = '[PostulationsJudge]PostulationQualifySetSuccess',
  PostulationQualifySetFailure = '[PostulationsJudge]PostulationQualifySetFailure',
  
  EvaluationPDfAction = '[PostulationsJudge]EvaluationPDfAction',
  EvaluationPDfSuccess = '[PostulationsJudge]EvaluationPDfSuccess',
  EvaluationPDfFailure = '[PostulationsJudge]EvaluationPDfFailure',

  PostulationReportAction = '[PostulationsJudge]PostulationReportAction',
  PostulationReportSuccess = '[PostulationsJudge]PostulationReportSuccess',
  PostulationReportFailure = '[PostulationsJudge]PostulationReportFailure',

  PostulationsJudgeEndAction = '[PostulationsJudge]PostulationsJudgeEndAction',
  PostulationsJudgeEndSuccess = '[PostulationsJudge]PostulationsJudgeEndSuccess',
  PostulationsJudgeEndFailure = '[PostulationsJudge]PostulationsJudgeEndFailure',
}

export class EvaluationGetAction implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationGetAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationGetSuccess implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationGetSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationGetFailure implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationGetFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationTotalsAction implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationTotalsAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationTotalsSuccess implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationTotalsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationTotalsFailure implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationTotalsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationQualifyAction implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationQualifyAction;

  constructor(public payload: { idConvocatory: string, id: string }) {
  }
}

export class PostulationQualifySuccess implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationQualifySuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationQualifyFailure implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationQualifyFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationQualifySetAction implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationQualifySetAction;

  constructor(public payload: { idConvocatory: string, id: string, data:any }) {
  }
}

export class PostulationQualifySetSuccess implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationQualifySetSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationQualifySetFailure implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationQualifySetFailure;

  constructor(public payload: { validation: any }) {
  }
}


export class EvaluationPDfAction implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationPDfAction;

  constructor(public payload: { evaluation: string, user: string }) {
  }
}

export class EvaluationPDfSuccess implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationPDfSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationPDfFailure implements Action {
  readonly type = PostulationsJudgeActionTypes.EvaluationPDfFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationReportAction implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationReportAction;

  constructor(public payload: { hash: any }) {
  }
}

export class PostulationReportSuccess implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationReportSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationReportFailure implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationReportFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationsJudgeEndAction implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationsJudgeEndAction;

  constructor(public payload: { id: any, phase: any }) {
  }
}

export class PostulationsJudgeEndSuccess implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationsJudgeEndSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationsJudgeEndFailure implements Action {
  readonly type = PostulationsJudgeActionTypes.PostulationsJudgeEndFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type PostulationsJudgeActions =
  | EvaluationGetAction
  | EvaluationGetSuccess
  | EvaluationGetFailure
  | EvaluationTotalsAction
  | EvaluationTotalsSuccess
  | EvaluationTotalsFailure
  | PostulationQualifyAction
  | PostulationQualifySuccess
  | PostulationQualifyFailure
  | PostulationQualifySetAction
  | PostulationQualifySetSuccess
  | PostulationQualifySetFailure
  | EvaluationPDfAction
  | EvaluationPDfSuccess
  | EvaluationPDfFailure
  | PostulationReportAction
  | PostulationReportSuccess
  | PostulationReportFailure
  | PostulationsJudgeEndAction
  | PostulationsJudgeEndSuccess
  | PostulationsJudgeEndFailure;
