import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {PostulationsFilter} from '../postulations.model';


@Injectable()
export class PostulationsService {
	private postulationsFilter = new BehaviorSubject<any>(new PostulationsFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	getEvaluation(id: string){
		let apiUrl = `${environment.app.apiBaseUrl}discharges/${id}?include=convocatory`;
		return this.http.get(apiUrl, { observe: 'response' });
	}
	currentPostulationsFilter(): Observable<any> {
    	return this.postulationsFilter.asObservable();
  	}
  	getPostulationsFilter() {
    	return this.postulationsFilter.getValue();
  	}
  	sendPostulationsFilter(object: any) {
    	this.postulationsFilter.next(object);
  	}
	getPostulations(id: string, postulationsFilter: PostulationsFilter): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}discharges/convocatory/${id}/postulations/winners`;

		const params = createRequestOption({
			'include': 'discharge',
			'page': postulationsFilter.page,
			'limit': postulationsFilter.limit,
			'orderBy': postulationsFilter.orderBy,
			'sortedBy': postulationsFilter.sortedBy,
			'search': postulationsFilter.postulation.search
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	getPostulationsStatus(id: string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}convocatories/${id}/postulations`;
		return this.http.get(apiUrl,{observe: 'response'});
	}
	getPostulationQualify(idConvocatory: string, id: string){
		let apiUrl = `${environment.app.apiBaseUrl}discharges/calification/postulation/${id}`;
		return this.http.get(apiUrl,{observe: 'response'});
	}
	setPostulationQualify(idConvocatory: string, id: string, data:any){
		let apiUrl = `${environment.app.apiBaseUrl}discharges/calification/postulation/${id}`;
		return this.http.put(apiUrl, data,{observe: 'response'});
	}
	getPDF(idEvaluation: string, idUser:string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/${idEvaluation}/qualifications/pdf/judge/${idUser}`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
	getReport(hash: string): Observable<HttpResponse<any>>{
		let apiUrl = `${environment.app.apiBaseUrl}a/discharges/postulation/${hash}/report`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
	closePostulation(hash: string, type: string = ''){
		let apiUrl = '';
		if(type === '')
			apiUrl = `${environment.app.apiBaseUrl}discharges/postulation/${hash}/nulled`;
		else
			apiUrl = `${environment.app.apiBaseUrl}discharges/postulation/${hash}/close`;
		return this.http.patch(apiUrl, {}, {observe: 'response'});	
	}
	finishPhase(id: string, phase: number){
		let apiUrl = `${environment.app.apiBaseUrl}discharges/${id}/close`;
		return this.http.patch(apiUrl, {}, {observe: 'response'});
	}
}