import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as Auth from '../store/auth.actions';

@Injectable()
export class JudgeGuard implements CanActivate {
  constructor(
    private store: Store<any>,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(s => s.auth),
      map(auth => {
        let r = auth.user.roles.filter(v=>{return v.name==='evaluator'});
        if (r.length == 0) {
          if(auth.user.roles.filter(v=>{return v.name==='piu'}).lenght!=0){
            this.router.navigateByUrl('/admin');
          }
          return false;
        }
        return true;
      }));
  }
}
