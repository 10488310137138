import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {DetailService} from './detail.service';
import * as postulations from './detail.actions';

@Injectable()
export class DetailEffects {

  constructor(private actions$: Actions,
              private detailService: DetailService) {
  }

  @Effect()
  getPostulation = this.actions$.pipe(
    ofType(postulations.DischargeDetailActionTypes.PostulationGetAction),
    map((action: postulations.PostulationGetAction) => action.payload),
    exhaustMap((param: any) =>
      this.detailService.getPostulation(param.hash).pipe(
        map(success => new postulations.PostulationGetSuccess({resultHeader: success})),
        catchError(err => of(new postulations.PostulationGetFailure({validation: err})))
      )
    )
  );

  @Effect()
  getDischarge = this.actions$.pipe(
    ofType(postulations.DischargeDetailActionTypes.DischargeGetAction),
    map((action: postulations.DischargeGetAction) => action.payload),
    exhaustMap((param: any) =>
      this.detailService.getDischarge(param.hash).pipe(
        map(success => new postulations.DischargeGetSuccess({resultHeader: success})),
        catchError(err => of(new postulations.DischargeGetFailure({validation: err})))
      )
    )
  );
}
