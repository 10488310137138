import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {SectorFilter} from '../sectors.model';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class SectorsService {

  private urlResource = environment.app.apiBaseUrl + 'sectors';
  private sectorFilter = new BehaviorSubject<any>(new SectorFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  sendSectorFilter(object: any) {
    this.sectorFilter.next(object);
  }

  currentSectorFilter(): Observable<any> {
    return this.sectorFilter.asObservable();
  }

  getSectorFilter() {
    return this.sectorFilter.getValue();
  }

  createSector(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urlResource}`, body, {observe: 'response'});
  }

  deleteSector(id): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urlResource}/${id}`, {observe: 'response'});
  }

  modifySector(body: any, id: any): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urlResource}/${id}`, body, {observe: 'response'});
  }

  getByIdSector(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}/${id}?include=area_intervention`, {observe: 'response'});
  }

  getAllSectors(sectorFilter: SectorFilter): Observable<HttpResponse<any>> {
    const params = createRequestOption({
      'page': sectorFilter.page,
      'limit': sectorFilter.limit,
      'orderBy': sectorFilter.orderBy,
      'sortedBy': sectorFilter.sortedBy,
      'search': sectorFilter.sector.search,
      'include': 'area_intervention'
    });
    return this.http.get(`${this.urlResource}`, {params: params, observe: 'response'});
  }
}
