import {SubsectorsActions} from './subsectors.actions';

export interface SubsectorsState {
  dataTable: any;
}

export const initialState: SubsectorsState = {
  dataTable: undefined
};

export function reducer(state: SubsectorsState = initialState, action: SubsectorsActions): SubsectorsState {

  switch (action.type) {

    default:
      return state;
  }
}
