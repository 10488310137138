import {Action} from '@ngrx/store';

export enum PostulationDetailActionTypes {
  GetConvocatoryDetailAction = '[ConvocatoriesPostulationDetail]GetConvocatoryDetailAction',
  GetConvocatoryDetailSuccess = '[ConvocatoriesPostulationDetail]GetConvocatoryDetailSuccess',
  GetConvocatoryDetailFailure = '[ConvocatoriesPostulationDetail]GetConvocatoryDetailFailure',

  GetPostulationDetailAction = '[ConvocatoriesPostulationDetail]GetPostulationDetailAction',
  GetPostulationDetailSuccess = '[ConvocatoriesPostulationDetail]GetPostulationDetailSuccess',
  GetPostulationDetailFailure = '[ConvocatoriesPostulationDetail]GetPostulationDetailFailure'
}

export class GetConvocatoryDetailAction implements Action {
  readonly type = PostulationDetailActionTypes.GetConvocatoryDetailAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetConvocatoryDetailSuccess implements Action {
  readonly type = PostulationDetailActionTypes.GetConvocatoryDetailSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetConvocatoryDetailFailure implements Action {
  readonly type = PostulationDetailActionTypes.GetConvocatoryDetailFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetPostulationDetailAction implements Action {
  readonly type = PostulationDetailActionTypes.GetPostulationDetailAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetPostulationDetailSuccess implements Action {
  readonly type = PostulationDetailActionTypes.GetPostulationDetailSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetPostulationDetailFailure implements Action {
  readonly type = PostulationDetailActionTypes.GetPostulationDetailFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type PostulationDetailActions =
  | GetConvocatoryDetailAction
  | GetConvocatoryDetailSuccess
  | GetConvocatoryDetailFailure

  | GetPostulationDetailAction
  | GetPostulationDetailSuccess
  | GetPostulationDetailFailure;
