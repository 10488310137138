import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AlertComponent} from '../modules/alert/alert.component';
import {MatDialog} from '@angular/material';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public dialog: MatDialog) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status.toString().charAt(0) === '5') {
              this.dialog.open(AlertComponent, {
                disableClose: true,
                data: {
                  item: '',
                  type: 'general-error',
                  title: 'ERROR GENERAL',
                  message: err.message,
                  textButton: 'Aceptar'
                }
              });
            }
          }
        }
      )
    );
  }
}
