import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './catalogs.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CatalogsEffects} from './catalogs.effects';
import {CatalogsService} from './catalogs.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppCatalogsModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppCatalogsModule,
      providers: [
        {provide: 'config', useValue: config},
        CatalogsService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppCatalogsModule,
    StoreModule.forFeature('appCatalogs', reducer),
    EffectsModule.forFeature([CatalogsEffects]),
  ],
})

export class RootAppCatalogsModule {
}
