import {Action} from '@ngrx/store';

export enum SubsectorsActionTypes {
  SubsectorsCreateAction = '[Subsectors]SubsectorsCreateAction',
  SubsectorsCreateSuccess = '[Subsectors]SubsectorsCreateSuccess',
  SubsectorsCreateFailure = '[Subsectors]SubsectorsCreateFailure',

  SubsectorsUpdateAction = '[Subsectors]SubsectorsUpdateAction',
  SubsectorsUpdateSuccess = '[Subsectors]SubsectorsUpdateSuccess',
  SubsectorsUpdateFailure = '[Subsectors]SubsectorsUpdateFailure',

  SubsectorsDeleteAction = '[Subsectors]SubsectorsDeleteAction',
  SubsectorsDeleteSuccess = '[Subsectors]SubsectorsDeleteSuccess',
  SubsectorsDeleteFailure = '[Subsectors]SubsectorsDeleteFailure',

  SubsectorsGetByIdAction = '[Subsectors]SubsectorsGetByIdAction',
  SubsectorsGetByIdSuccess = '[Subsectors]SubsectorsGetByIdSuccess',
  SubsectorsGetByIdFailure = '[Subsectors]SubsectorsGetByIdFailure'
}

export class SubsectorsCreateAction implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsCreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class SubsectorsCreateSuccess implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsCreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SubsectorsCreateFailure implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsCreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SubsectorsUpdateAction implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsUpdateAction;

  constructor(public payload: { body: any, id: any }) {
  }
}

export class SubsectorsUpdateSuccess implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsUpdateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SubsectorsUpdateFailure implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsUpdateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SubsectorsDeleteAction implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsDeleteAction;

  constructor(public payload: { id: any }) {
  }
}

export class SubsectorsDeleteSuccess implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsDeleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SubsectorsDeleteFailure implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsDeleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SubsectorsGetByIdAction implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsGetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class SubsectorsGetByIdSuccess implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsGetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SubsectorsGetByIdFailure implements Action {
  readonly type = SubsectorsActionTypes.SubsectorsGetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type SubsectorsActions =
  | SubsectorsCreateAction
  | SubsectorsCreateSuccess
  | SubsectorsCreateFailure

  | SubsectorsUpdateAction
  | SubsectorsUpdateSuccess
  | SubsectorsUpdateFailure

  | SubsectorsDeleteAction
  | SubsectorsDeleteSuccess
  | SubsectorsDeleteFailure

  | SubsectorsGetByIdAction
  | SubsectorsGetByIdSuccess
  | SubsectorsGetByIdFailure;
