import {CatalogDetailActions, CatalogDetailActionTypes} from './catalog-detail.actions';

export interface CatalogDetailState {
  dataTable: any;
  catalogs: any;
}

export const initialState: CatalogDetailState = {
  dataTable: undefined,
  catalogs: undefined
};

export function reducer(state: CatalogDetailState = initialState, action: CatalogDetailActions): CatalogDetailState {

  switch (action.type) {

    case CatalogDetailActionTypes.GetAllCatalogsSuccess: {
      return {
        ...state,
        catalogs: action.payload.resultHeader.body.data
      };
    }

    default:
      return state;
  }

}
