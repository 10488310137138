import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './evaluators.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {EvaluatorsEffects} from './evaluators.effects';
import {EvaluatorsService} from './evaluators.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppEvaluatorUsersModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppEvaluatorsUsersModule,
      providers: [
        {provide: 'config', useValue: config},
        EvaluatorsService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppEvaluatorUsersModule,
    StoreModule.forFeature('appEvaluatorUsers', reducer),
    EffectsModule.forFeature([EvaluatorsEffects]),
  ],
})
export class RootAppEvaluatorsUsersModule {
}
