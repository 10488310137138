import {Action} from '@ngrx/store';

export enum CatalogDetailActionTypes {
  CreateAction = '[CatalogDetail]CreateAction',
  CreateSuccess = '[CatalogDetail]CreateSuccess',
  CreateFailure = '[CatalogDetail]CreateFailure',

  UpdateAction = '[CatalogDetail]UpdateAction',
  UpdateSuccess = '[CatalogDetail]UpdateSuccess',
  UpdateFailure = '[CatalogDetail]UpdateFailure',

  DeleteAction = '[CatalogDetail]DeleteAction',
  DeleteSuccess = '[CatalogDetail]DeleteSuccess',
  DeleteFailure = '[CatalogDetail]DeleteFailure',

  GetByIdAction = '[CatalogDetail]GetByIdAction',
  GetByIdSuccess = '[CatalogDetail]GetByIdSuccess',
  GetByIdFailure = '[CatalogDetail]GetByIdFailure',

  GetAllCatalogsAction = '[CatalogDetail]GetAllCatalogsAction',
  GetAllCatalogsSuccess = '[CatalogDetail]GetAllCatalogsSuccess',
  GetAllCatalogsFailure = '[CatalogDetail]GetAllCatalogsFailure'
}

export class GetAllCatalogsAction implements Action {
  readonly type = CatalogDetailActionTypes.GetAllCatalogsAction;
}

export class GetAllCatalogsSuccess implements Action {
  readonly type = CatalogDetailActionTypes.GetAllCatalogsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetAllCatalogsFailure implements Action {
  readonly type = CatalogDetailActionTypes.GetAllCatalogsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class CreateAction implements Action {
  readonly type = CatalogDetailActionTypes.CreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class CreateSuccess implements Action {
  readonly type = CatalogDetailActionTypes.CreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class CreateFailure implements Action {
  readonly type = CatalogDetailActionTypes.CreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class UpdateAction implements Action {
  readonly type = CatalogDetailActionTypes.UpdateAction;

  constructor(public payload: { body: any, id: any }) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = CatalogDetailActionTypes.UpdateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class UpdateFailure implements Action {
  readonly type = CatalogDetailActionTypes.UpdateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class DeleteAction implements Action {
  readonly type = CatalogDetailActionTypes.DeleteAction;

  constructor(public payload: { id: any }) {
  }
}

export class DeleteSuccess implements Action {
  readonly type = CatalogDetailActionTypes.DeleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class DeleteFailure implements Action {
  readonly type = CatalogDetailActionTypes.DeleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetByIdAction implements Action {
  readonly type = CatalogDetailActionTypes.GetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetByIdSuccess implements Action {
  readonly type = CatalogDetailActionTypes.GetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetByIdFailure implements Action {
  readonly type = CatalogDetailActionTypes.GetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type CatalogDetailActions =
  | CreateAction
  | CreateSuccess
  | CreateFailure

  | UpdateAction
  | UpdateSuccess
  | UpdateFailure

  | DeleteAction
  | DeleteSuccess
  | DeleteFailure

  | GetByIdAction
  | GetByIdSuccess
  | GetByIdFailure

  | GetAllCatalogsAction
  | GetAllCatalogsSuccess
  | GetAllCatalogsFailure;
