import {Action} from '@ngrx/store';

export enum InterventionLinesActionTypes {
  InterventionLinesCreateAction = '[InterventionLines]InterventionLinesCreateAction',
  InterventionLinesCreateSuccess = '[InterventionLines]InterventionLinesCreateSuccess',
  InterventionLinesCreateFailure = '[InterventionLines]InterventionLinesCreateFailure',

  InterventionLinesUpdateAction = '[InterventionLines]InterventionLinesUpdateAction',
  InterventionLinesUpdateSuccess = '[InterventionLines]InterventionLinesUpdateSuccess',
  InterventionLinesUpdateFailure = '[InterventionLines]InterventionLinesUpdateFailure',

  InterventionLinesDeleteAction = '[InterventionLines]InterventionLinesDeleteAction',
  InterventionLinesDeleteSuccess = '[InterventionLines]InterventionLinesDeleteSuccess',
  InterventionLinesDeleteFailure = '[InterventionLines]InterventionLinesDeleteFailure',

  InterventionLinesGetByIdAction = '[InterventionLines]InterventionLinesGetByIdAction',
  InterventionLinesGetByIdSuccess = '[InterventionLines]InterventionLinesGetByIdSuccess',
  InterventionLinesGetByIdFailure = '[InterventionLines]InterventionLinesGetByIdFailure'
}

export class InterventionLinesCreateAction implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesCreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class InterventionLinesCreateSuccess implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesCreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class InterventionLinesCreateFailure implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesCreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class InterventionLinesUpdateAction implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesUpdateAction;

  constructor(public payload: { body: any, id: any }) {
  }
}

export class InterventionLinesUpdateSuccess implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesUpdateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class InterventionLinesUpdateFailure implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesUpdateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class InterventionLinesDeleteAction implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesDeleteAction;

  constructor(public payload: { id: any }) {
  }
}

export class InterventionLinesDeleteSuccess implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesDeleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class InterventionLinesDeleteFailure implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesDeleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class InterventionLinesGetByIdAction implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesGetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class InterventionLinesGetByIdSuccess implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesGetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class InterventionLinesGetByIdFailure implements Action {
  readonly type = InterventionLinesActionTypes.InterventionLinesGetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type InterventionLinesActions =
  | InterventionLinesCreateAction
  | InterventionLinesCreateSuccess
  | InterventionLinesCreateFailure

  | InterventionLinesUpdateAction
  | InterventionLinesUpdateSuccess
  | InterventionLinesUpdateFailure

  | InterventionLinesDeleteAction
  | InterventionLinesDeleteSuccess
  | InterventionLinesDeleteFailure

  | InterventionLinesGetByIdAction
  | InterventionLinesGetByIdSuccess
  | InterventionLinesGetByIdFailure;
