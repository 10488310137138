import {AllFormsActions} from './all-forms.actions';

export interface AllFormsState {
  dataTable: any;
}

export const initialState: AllFormsState = {
  dataTable: undefined
};

export function reducer(state: AllFormsState = initialState, action: AllFormsActions): AllFormsState {

  switch (action.type) {

    default:
      return state;
  }
}
