import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './published-past.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PublishedPastEffects} from './published-past.effects';
import {PublishedPastService} from './published-past.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppConvocatoriesPublishedPastModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppConvocatoriesPublishedPastModule,
      providers: [
        {provide: 'config', useValue: config},
        PublishedPastService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppConvocatoriesPublishedPastModule,
    StoreModule.forFeature('appConvocatoriesPublishedPast', reducer),
    EffectsModule.forFeature([PublishedPastEffects]),
  ],
})
export class RootAppConvocatoriesPublishedPastModule {
}
