import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {InterventionLinesService} from './intervention-lines.service';
import * as intervention_lines from './intervention-lines.actions';

@Injectable()
export class InterventionLinesEffects {

  constructor(private actions$: Actions,
              private interventionLinesService: InterventionLinesService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(intervention_lines.InterventionLinesActionTypes.InterventionLinesCreateAction),
    map((action: intervention_lines.InterventionLinesCreateAction) => action.payload),
    exhaustMap((param: any) =>
      this.interventionLinesService.createIntervencionLines(param).pipe(
        map(success => new intervention_lines.InterventionLinesCreateSuccess({resultHeader: success})),
        catchError(err => of(new intervention_lines.InterventionLinesCreateFailure({validation: err})))
      )
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(intervention_lines.InterventionLinesActionTypes.InterventionLinesUpdateAction),
    map((action: intervention_lines.InterventionLinesUpdateAction) => action.payload),
    exhaustMap((param: any) =>
      this.interventionLinesService.modifyIntervencionLines(param.body, param.id).pipe(
        map(success => new intervention_lines.InterventionLinesUpdateSuccess({resultHeader: success})),
        catchError(err => of(new intervention_lines.InterventionLinesUpdateFailure({validation: err})))
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType(intervention_lines.InterventionLinesActionTypes.InterventionLinesGetByIdAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: intervention_lines.InterventionLinesGetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.interventionLinesService.getByIdIntervencionLines(param.id).pipe(
        map(success => new intervention_lines.InterventionLinesGetByIdSuccess({resultHeader: success})),
        catchError(err => of(new intervention_lines.InterventionLinesGetByIdFailure({validation: err})))
      )
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(intervention_lines.InterventionLinesActionTypes.InterventionLinesDeleteAction),
    map((action: intervention_lines.InterventionLinesDeleteAction) => action.payload),
    exhaustMap((param: any) =>
      this.interventionLinesService.deleteIntervencionLines(param.id).pipe(
        map(success => new intervention_lines.InterventionLinesDeleteSuccess({resultHeader: success})),
        catchError(err => of(new intervention_lines.InterventionLinesDeleteFailure({validation: err})))
      )
    )
  );
}
