import {PublishedPastActions, PublishedPastActionTypes} from './published-past.actions';

export interface AllConvocatoriesState {
  dataTable: any;
  interventionAreas: any;
  interventionLines: any;
}

export const initialState: AllConvocatoriesState = {
  dataTable: undefined,
  interventionAreas: undefined,
  interventionLines: undefined
};

export function reducer(state: AllConvocatoriesState = initialState, action: PublishedPastActions): AllConvocatoriesState {

  switch (action.type) {

    case PublishedPastActionTypes.GetInterventionAreasSuccess: {
      return {
        ...state,
        interventionAreas: action.payload.resultHeader
      };
    }

    case PublishedPastActionTypes.GetInterventionLinesSuccess: {
      return {
        ...state,
        interventionLines: action.payload.resultHeader
      };
    }

    default:
      return state;
  }

}
