import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {EvaluatorsService} from './evaluators.service';
import * as evaluators from './evaluators.actions';

@Injectable()
export class EvaluatorsEffects {

  constructor(private actions$: Actions,
              private evaluatorsService: EvaluatorsService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(evaluators.EvaluatorsActionTypes.EvaluatorAddAction),
    map((action: evaluators.EvaluatorAddAction) => action.payload),
    exhaustMap((param: any) =>
      this.evaluatorsService.createUser(param.data).pipe(
        map(success => new evaluators.EvaluatorAddSuccess({resultHeader: success})),
        catchError(err => of(new evaluators.EvaluatorAddFailure({validation: err})))
      )
    )
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(evaluators.EvaluatorsActionTypes.EvaluatorEditAction),
    map((action: evaluators.EvaluatorEditAction) => action.payload),
    exhaustMap((param: any) =>
      this.evaluatorsService.editUser(param.id, param.data).pipe(
        map(success => new evaluators.EvaluatorEditSuccess({resultHeader: success})),
        catchError(err => of(new evaluators.EvaluatorEditFailure({validation: err})))
      )
    )
  );
}
