export class Catalog {
  search = '';
}

export class CatalogFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  catalog: Catalog = new Catalog();
}
