import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class DetailService {
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	getPostulation(id: string){
		let apiUrl = `${environment.app.apiBaseUrl}postulations/${id}?include=convocatory`;
		return this.http.get(apiUrl, { observe: 'response' });
	}
	getDischarge(hash: string){
		let apiUrl = `${environment.app.apiBaseUrl}discharges/postulation/${hash}`;
		return this.http.get(apiUrl, { observe: 'response' });
	}
}