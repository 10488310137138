import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {IntervencionAreaFilter} from '../intervention-areas.model';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class InterventionAreasService {

  private urlResource = environment.app.apiBaseUrl + 'areas_intervention';
  private intervencionAreaFilter = new BehaviorSubject<any>(new IntervencionAreaFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  sendIntervencionAreaFilter(object: any) {
    this.intervencionAreaFilter.next(object);
  }

  currentIntervencionAreaFilter(): Observable<any> {
    return this.intervencionAreaFilter.asObservable();
  }

  getIntervencionAreaFilter() {
    return this.intervencionAreaFilter.getValue();
  }

  createIntervencionArea(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urlResource}`, body, {observe: 'response'});
  }

  deleteIntervencionArea(id): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urlResource}/${id}`, {observe: 'response'});
  }

  modifyIntervencionArea(body: any, id: any): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urlResource}/${id}`, body, {observe: 'response'});
  }

  getByIdIntervencionArea(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}/${id}`, {observe: 'response'});
  }

  getAllIntervencionAreas(areasIntervencionFilter: IntervencionAreaFilter): Observable<HttpResponse<any>> {
    const params = createRequestOption({
      'page': areasIntervencionFilter.page,
      'limit': areasIntervencionFilter.limit,
      'orderBy': areasIntervencionFilter.orderBy,
      'sortedBy': areasIntervencionFilter.sortedBy,
      'search': areasIntervencionFilter.intervencionArea.search
    });
    return this.http.get(`${this.urlResource}`, {params: params, observe: 'response'});
  }
  getDataforModals(): Observable<HttpResponse<any>>{
    let apiUrl=`${environment.app.apiBaseUrl}a/areas_intervention`;
    return this.http.get(apiUrl, {observe: 'response'})
            .pipe(
              map(resp=>{
                let ai: any[] = [];
                if(resp['ok']){
                  resp['body']['data'].forEach(d=>{
                    ai.push({
                      id: d.id,
                      text: `${d.code} - ${d.name}`
                    });
                  });
                }
                resp['body']['data'] = ai;
                return resp;
              })
            );
  }
}
