import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {DashboardService} from './dashboard.service';
import * as archived_finished from './dashboard.actions';

@Injectable()
export class DashboardEffects {

  constructor(private actions$: Actions,
              private archivedFinishedService: DashboardService) {
  }

  @Effect()
  getDetailConvocatory$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.GetConvocatoryDetailAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetConvocatoryDetailAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getDetailConvocatory(param.id).pipe(
        map(success => new archived_finished.GetConvocatoryDetailSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetConvocatoryDetailFailure({validation: err})))
      )
    )
  );


  @Effect()
  rescheduleConvocatory$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.RescheduleConvocatoryAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.RescheduleConvocatoryAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.rescheduleConvocatory(param.id, param.body).pipe(
        map(success => new archived_finished.RescheduleConvocatorySuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.RescheduleConvocatoryFailure({validation: err})))
      )
    )
  );


  @Effect()
  archiveConvocatory$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.ArchiveConvocatoryAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.ArchiveConvocatoryAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.archiveConvocatory(param.id).pipe(
        map(success => new archived_finished.ArchiveConvocatorySuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.ArchiveConvocatoryFailure({validation: err})))
      )
    )
  );


  @Effect()
  unpublishConvocatory$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.UnpublishConvocatoryAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.UnpublishConvocatoryAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.unpublishConvocatory(param.id).pipe(
        map(success => new archived_finished.UnpublishConvocatorySuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.UnpublishConvocatoryFailure({validation: err})))
      )
    )
  );


  @Effect()
  publishConvocatory$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.PublishConvocatoryAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.PublishConvocatoryAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.publishConvocatory(param.id).pipe(
        map(success => new archived_finished.PublishConvocatorySuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.PublishConvocatoryFailure({validation: err})))
      )
    )
  );


  @Effect()
  getTotalPostulations$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.GetTotalPostulationsAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetTotalPostulationsAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getTotalPostulations(param.id).pipe(
        map(success => new archived_finished.GetTotalPostulationsSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetTotalPostulationsFailure({validation: err})))
      )
    )
  );


  @Effect()
  getChartPostulations$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.GetChartPostulationsAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetChartPostulationsAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getChartPostulations(param.id).pipe(
        map(success => new archived_finished.GetChartPostulationsSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetChartPostulationsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getPostulations$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.GetPostulationsAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetPostulationsAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getPostulations(param.id).pipe(
        map(success => new archived_finished.GetPostulationsSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetPostulationsFailure({validation: err})))
      )
    )
  );

  @Effect()
  finalize$ = this.actions$.pipe(
    ofType(archived_finished.DashBoardActionTypes.FinalizeAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.FinalizeAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.finalizePostulation(param.id).pipe(
        map(success => new archived_finished.FinalizeSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.FinalizeFailure({validation: err})))
      )
    )
  );

}
