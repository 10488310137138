import {DashboardActions, DashboardActionTypes} from './dashboard.actions';

export interface DashboardState {
}

export const initialState: DashboardState = {};

export function reducer(state: DashboardState = initialState, action: DashboardActions): DashboardState {

  switch (action.type) {

    default:
      return state;
  }

}
