import {ConvocatoriesActions} from './convocatories.actions';

export interface ReportingState {
  dataTable: any;
}

export const initialState: ReportingState = {
  dataTable: undefined
};

export function reducer(state: ReportingState = initialState, action: ConvocatoriesActions): ReportingState {

  switch (action.type) {

    default:
      return state;
  }
}
