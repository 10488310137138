import {Action} from '@ngrx/store';

export enum ProfileActionTypes {
  NameSendAction = '[Profile]NameSendAction',
  NameSendSuccess = '[Profile]NameSendSuccess',
  NameSendFailure = '[Profile]NameSendFailure',

  PasswordSendAction = '[Profile]PasswordSendAction',
  PasswordSendSuccess = '[Profile]PasswordSendSuccess',
  PasswordSendFailure = '[Profile]PasswordSendFailure',
}

export class NameSendAction implements Action {
  readonly type = ProfileActionTypes.NameSendAction;

  constructor(public payload: { id: string, name: string }) {
  }
}

export class NameSendSuccess implements Action {
  readonly type = ProfileActionTypes.NameSendSuccess;

  constructor(public payload: { resultHeader: any, name: string }) {
  }
}

export class NameSendFailure implements Action {
  readonly type = ProfileActionTypes.NameSendFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PasswordSendAction implements Action {
  readonly type = ProfileActionTypes.PasswordSendAction;

  constructor(public payload: { id: string, oldpassword: string, password: string }) {
  }
}

export class PasswordSendSuccess implements Action {
  readonly type = ProfileActionTypes.PasswordSendSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PasswordSendFailure implements Action {
  readonly type = ProfileActionTypes.PasswordSendFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type ProfileActions =
  | NameSendAction
  | NameSendSuccess
  | NameSendFailure
  | PasswordSendAction
  | PasswordSendSuccess
  | PasswordSendFailure;
