import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {InterventionAreasService} from './intervention-areas.service';
import * as intervention_areas from './intervention-areas.actions';

@Injectable()
export class InterventionAreasEffects {

  constructor(private actions$: Actions,
              private interventionAreasService: InterventionAreasService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(intervention_areas.InterventionAreasActionTypes.InterventionAreasCreateAction),
    map((action: intervention_areas.InterventionAreasCreateAction) => action.payload),
    exhaustMap((param: any) =>
      this.interventionAreasService.createIntervencionArea(param).pipe(
        map(success => new intervention_areas.InterventionAreasCreateSuccess({resultHeader: success})),
        catchError(err => of(new intervention_areas.InterventionAreasCreateFailure({validation: err})))
      )
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(intervention_areas.InterventionAreasActionTypes.InterventionAreasUpdateAction),
    map((action: intervention_areas.InterventionAreasUpdateAction) => action.payload),
    exhaustMap((param: any) =>
      this.interventionAreasService.modifyIntervencionArea(param.body, param.id).pipe(
        map(success => new intervention_areas.InterventionAreasUpdateSuccess({resultHeader: success})),
        catchError(err => of(new intervention_areas.InterventionAreasUpdateFailure({validation: err})))
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType(intervention_areas.InterventionAreasActionTypes.InterventionAreasGetByIdAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: intervention_areas.InterventionAreasGetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.interventionAreasService.getByIdIntervencionArea(param.id).pipe(
        map(success => new intervention_areas.InterventionAreasGetByIdSuccess({resultHeader: success})),
        catchError(err => of(new intervention_areas.InterventionAreasGetByIdFailure({validation: err})))
      )
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(intervention_areas.InterventionAreasActionTypes.InterventionAreasDeleteAction),
    map((action: intervention_areas.InterventionAreasDeleteAction) => action.payload),
    exhaustMap((param: any) =>
      this.interventionAreasService.deleteIntervencionArea(param.id).pipe(
        map(success => new intervention_areas.InterventionAreasDeleteSuccess({resultHeader: success})),
        catchError(err => of(new intervention_areas.InterventionAreasDeleteFailure({validation: err})))
      )
    )
  );
}
