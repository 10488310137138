import {NewEditFormActions, NewEditFormActionTypes} from './new-edit-form.actions';

export interface NewEditFormState {
  fieldTypes: any;
  forms: any;
  catalogs: any;
}

export const initialState: NewEditFormState = {
  fieldTypes: undefined,
  forms: undefined,
  catalogs: undefined
};

export function reducer(state: NewEditFormState = initialState, action: NewEditFormActions): NewEditFormState {

  switch (action.type) {

    case NewEditFormActionTypes.FormFieldTypesSuccess: {
      return {
        ...state,
        fieldTypes: action.payload.resultHeader.body.data
      };
    }

    case NewEditFormActionTypes.FormGetAllSubFormsSuccess: {
      return {
        ...state,
        forms: action.payload.resultHeader.body.data
      };
    }

    case NewEditFormActionTypes.FormGetCatalogsSuccess: {
      return {
        ...state,
        catalogs: action.payload.resultHeader.body.data
      };
    }

    default:
      return state;
  }
}
