import {Action} from '@ngrx/store';

export enum DischargeDetailActionTypes {
  PostulationGetAction = '[DischargePostulations]PostulationGetAction',
  PostulationGetSuccess = '[DischargePostulations]PostulationGetSuccess',
  PostulationGetFailure = '[DischargePostulations]PostulationGetFailure',

  DischargeGetAction = '[DischargePostulations]DischargeGetAction',
  DischargeGetSuccess = '[DischargePostulations]DischargeGetSuccess',
  DischargeGetFailure = '[DischargePostulations]DischargeGetFailure',

}

export class PostulationGetAction implements Action {
  readonly type = DischargeDetailActionTypes.PostulationGetAction;

  constructor(public payload: { hash: string }) {
  }
}

export class PostulationGetSuccess implements Action {
  readonly type = DischargeDetailActionTypes.PostulationGetSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationGetFailure implements Action {
  readonly type = DischargeDetailActionTypes.PostulationGetFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class DischargeGetAction implements Action {
  readonly type = DischargeDetailActionTypes.DischargeGetAction;

  constructor(public payload: { hash: string }) {
  }
}

export class DischargeGetSuccess implements Action {
  readonly type = DischargeDetailActionTypes.DischargeGetSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class DischargeGetFailure implements Action {
  readonly type = DischargeDetailActionTypes.DischargeGetFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type DischargePostulationsActions =
  | PostulationGetAction
  | PostulationGetSuccess
  | PostulationGetFailure
  | DischargeGetAction
  | DischargeGetSuccess
  | DischargeGetFailure;
