import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './piu.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PiuEffects} from './piu.effects';
import {PiuService} from './piu.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppPiuUsersModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppPiuUsersModule,
      providers: [
        {provide: 'config', useValue: config},
        PiuService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppPiuUsersModule,
    StoreModule.forFeature('appPiuUsers', reducer),
    EffectsModule.forFeature([PiuEffects]),
  ],
})
export class RootAppPiuUsersModule {
}
