import {Action} from '@ngrx/store';

export enum ConvocatoriesActionTypes {
  ConvocatoriesStartAction = '[Convocatories]ConvocatoriesStartAction',
  ConvocatoriesStartSuccess = '[Convocatories]ConvocatoriesStartSuccess',
  ConvocatoriesStartFailure = '[Convocatories]ConvocatoriesStartFailure',
}

export class ConvocatoriesStartAction implements Action {
  readonly type = ConvocatoriesActionTypes.ConvocatoriesStartAction;

  constructor(public payload: { id: any, code: any }) {
  }
}

export class ConvocatoriesStartSuccess implements Action {
  readonly type = ConvocatoriesActionTypes.ConvocatoriesStartSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ConvocatoriesStartFailure implements Action {
  readonly type = ConvocatoriesActionTypes.ConvocatoriesStartFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type ConvocatoriesActions =
  | ConvocatoriesStartAction
  | ConvocatoriesStartSuccess
  | ConvocatoriesStartFailure;
