import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {AllFormsFilter} from '../all-forms.model';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class AllFormsService {

  private urlResource = environment.app.apiBaseUrl + 'formbuilder';
  private allFormsFilter = new BehaviorSubject<any>(new AllFormsFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  sendAllFormsFilter(object: any) {
    this.allFormsFilter.next(object);
  }

  currentAllFormsFilter(): Observable<any> {
    return this.allFormsFilter.asObservable();
  }

  getAllFormsFilter() {
    return this.allFormsFilter.getValue();
  }

  deleteForm(id): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urlResource}/${id}`, {observe: 'response'});
  }

  getAllForms(allFormsFilter: AllFormsFilter): Observable<HttpResponse<any>> {
    const params = createRequestOption({
      'include':'parent',
      'page': allFormsFilter.page,
      'limit': allFormsFilter.limit,
      'orderBy': allFormsFilter.orderBy,
      'sortedBy': allFormsFilter.sortedBy,
      'search': allFormsFilter.allForms.search
    });
    return this.http.get(`${this.urlResource}/forms`, {params: params, observe: 'response'});
  }
}
