import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './ponstulants.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PostulantsEffects} from './ponstulants.effects';
import {PostulantsService} from './ponstulants.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppPostulantsUsersModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppPostulantsUsersModule,
      providers: [
        {provide: 'config', useValue: config},
        PostulantsService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppPostulantsUsersModule,
    StoreModule.forFeature('appEvaluatorUsers', reducer),
    EffectsModule.forFeature([PostulantsEffects]),
  ],
})
export class RootAppPostulantsUsersModule {
}
