import {ArchivedFinishedActions} from './archived-finished.actions';

export interface ArchivedFinishedState {
  dataTable: any;
}

export const initialState: ArchivedFinishedState = {
  dataTable: undefined
};

export function reducer(state: ArchivedFinishedState = initialState, action: ArchivedFinishedActions): ArchivedFinishedState {

  switch (action.type) {

    default:
      return state;
  }

}
