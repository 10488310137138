import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PostulationsService} from './postulations.service';
import * as postulations from './postulations.actions';

@Injectable()
export class PostulationsEffects {

  constructor(private actions$: Actions,
              private postulationsService: PostulationsService) {
  }

  @Effect()
  getConvocatorieDetail = this.actions$.pipe(
    ofType(postulations.PostulationsJudgeActionTypes.EvaluationGetAction),
    map((action: postulations.EvaluationGetAction) => action.payload),
    exhaustMap((param: any) =>
      this.postulationsService.getEvaluation(param.id).pipe(
        map(success => new postulations.EvaluationGetSuccess({resultHeader: success})),
        catchError(err => of(new postulations.EvaluationGetFailure({validation: err})))
      )
    )
  );

  @Effect()
  getTotals = this.actions$.pipe(
    ofType(postulations.PostulationsJudgeActionTypes.EvaluationTotalsAction),
    map((action: postulations.EvaluationTotalsAction) => action.payload),
    exhaustMap((param: any) =>
      this.postulationsService.getPostulationsStatus(param.id).pipe(
        map(success => new postulations.EvaluationTotalsSuccess({resultHeader: success})),
        catchError(err => of(new postulations.EvaluationTotalsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getQualify = this.actions$.pipe(
    ofType(postulations.PostulationsJudgeActionTypes.PostulationQualifyAction),
    map((action: postulations.PostulationQualifyAction) => action.payload),
    exhaustMap((param: any) =>
      this.postulationsService.getPostulationQualify(param.idConvocatory, param.id).pipe(
        map(success => new postulations.PostulationQualifySuccess({resultHeader: success})),
        catchError(err => of(new postulations.PostulationQualifyFailure({validation: err})))
      )
    )
  );

  @Effect()
  setQualify = this.actions$.pipe(
    ofType(postulations.PostulationsJudgeActionTypes.PostulationQualifySetAction),
    map((action: postulations.PostulationQualifySetAction) => action.payload),
    exhaustMap((param: any) =>
      this.postulationsService.setPostulationQualify(param.idConvocatory, param.id, param.data).pipe(
        map(success => new postulations.PostulationQualifySetSuccess({resultHeader: success})),
        catchError(err => of(new postulations.PostulationQualifySetFailure({validation: err})))
      )
    )
  );

  @Effect()
  getPdf = this.actions$.pipe(
    ofType(postulations.PostulationsJudgeActionTypes.EvaluationPDfAction),
    map((action: postulations.EvaluationPDfAction) => action.payload),
    exhaustMap((param: any) =>
      this.postulationsService.getPDF(param.evaluation, param.user).pipe(
        map(success => new postulations.EvaluationPDfSuccess({resultHeader: success})),
        catchError(err => of(new postulations.EvaluationPDfFailure({validation: err})))
      )
    )
  );

  @Effect()
  postulationReport$ = this.actions$.pipe(
    ofType(postulations.PostulationsJudgeActionTypes.PostulationReportAction),
    map((action: postulations.PostulationReportAction) => action.payload),
    exhaustMap((param: any) =>
      this.postulationsService.getReport(param.hash).pipe(
        map(success => new postulations.PostulationReportSuccess({resultHeader: success})),
        catchError(err => of(new postulations.PostulationReportFailure({validation: err})))
      )
    )
  );

  @Effect()
  phaseFirstEnd$ = this.actions$.pipe(
    ofType(postulations.PostulationsJudgeActionTypes.PostulationsJudgeEndAction),
    map((action: postulations.PostulationsJudgeEndAction) => action.payload),
    exhaustMap((param: any) =>
      this.postulationsService.finishPhase(param.id, param.phase).pipe(
        map(success => new postulations.PostulationsJudgeEndSuccess({resultHeader: success})),
        catchError(err => of(new postulations.PostulationsJudgeEndFailure({validation: err})))
      )
    )
  );
}
