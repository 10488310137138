import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {ConovocatorieFilter} from '../convocatories.model';


@Injectable()
export class ConvocatoriesService {
	private convocatorieFilter = new BehaviorSubject<any>(new ConovocatorieFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	currentConvocatorieFilter(): Observable<any> {
    	return this.convocatorieFilter.asObservable();
  	}
  	getConvocatorieFilter() {
    	return this.convocatorieFilter.getValue();
  	}
  	sendConvocatorieFilter(object: any) {
    	this.convocatorieFilter.next(object);
  	}
	getConvocatories(ConovocatorieFilter: ConovocatorieFilter): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/convocatories/list`;
		const params = createRequestOption({
			'include': 'evaluation',
			'page': ConovocatorieFilter.page,
			'limit': ConovocatorieFilter.limit,
			'orderBy': ConovocatorieFilter.orderBy,
			'sortedBy': ConovocatorieFilter.sortedBy,
			'search': ConovocatorieFilter.convocatorie.search
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	startEvaluation(id){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/convocatory/${id}/enable`;
		return this.http.patch(apiUrl, { observe: 'response' });
	}
}