import {DraftsPreparationsActions, DraftsPreparationsActionTypes} from './drafts-preparations.actions';

export interface DraftsPreparationsState {
  dataTable: any;
  interventionAreas: any;
  interventionLines: any;
}

export const initialState: DraftsPreparationsState = {
  dataTable: undefined,
  interventionAreas: undefined,
  interventionLines: undefined
};

export function reducer(state: DraftsPreparationsState = initialState, action: DraftsPreparationsActions): DraftsPreparationsState {

  switch (action.type) {

    case DraftsPreparationsActionTypes.GetInterventionAreasSuccess: {
      return {
        ...state,
        interventionAreas: action.payload.resultHeader
      };
    }

    case DraftsPreparationsActionTypes.GetInterventionLinesSuccess: {
      return {
        ...state,
        interventionLines: action.payload.resultHeader
      };
    }

    default:
      return state;
  }

}
