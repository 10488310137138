import {Observable, forkJoin} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class NewEditConvocatorieService {
  private urlResource = environment.app.apiBaseUrl;

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  createConvocatorie(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urlResource}convocatories`, {
      type: body.type,
      name: body.name
    }, {observe: 'response'});
  }

  modifyConvocatorie(body: any, id: any): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urlResource}convocatories/${id}`, body, {observe: 'response'});
  }

  getByIdConvocatorie(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}convocatories/${id}`, {observe: 'response'});
  }

  getInterventionAreas(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}a/areas_intervention`, {observe: 'response'});
  }

  getInterventionLines(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}a/lines_intervention`, {observe: 'response'});
  }

  getForms(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}formbuilder/a/forms/${id}`, {observe: 'response'});
  }

  getSubsectors(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}a/sectors/${id}?include=subsectors`, {observe: 'response'});
  }

  getSpecialties(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}a/areas_intervention/${id}?include=specialties`, {observe: 'response'});
  }

  getSectors(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}a/areas_intervention/${id}?include=sectors`, {observe: 'response'});
  }

  deleteFile(id: any): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urlResource}files/${id}`, {observe: 'response'});
  }
  getAllData(sectors: any){
    let resp: any[] = [];
    sectors.forEach(v=>{
      resp.push(this.http.get(`${this.urlResource}a/sectors/${v.id}?include=subsectors`, {observe: 'response'}));
    });
    return forkJoin(resp);
  }
}
