import {PostulationDetailActions, PostulationDetailActionTypes} from './postulation-detail.actions';

export interface ConvocatorieState {
  dataTable: any;
  sectors: any;
}

export const initialState: ConvocatorieState = {
  dataTable: undefined,
  sectors: undefined
};

export function reducer(state: ConvocatorieState = initialState, action: PostulationDetailActions): ConvocatorieState {

  switch (action.type) {

    case PostulationDetailActionTypes.GetConvocatoryDetailSuccess: {
      return {
        ...state,
        sectors: action.payload.resultHeader.body.data.sectors
      };
    }

    default:
      return state;
  }

}
