export class Convocatorie {
  search = '';
}

export class ConovocatorieFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  convocatorie: Convocatorie = new Convocatorie();
}
