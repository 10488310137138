import {Action} from '@ngrx/store';

export enum NewEditFormActionTypes {
  FormCreateAction = '[NewEditForm]FormCreateAction',
  FormCreateSuccess = '[NewEditForm]FormCreateSuccess',
  FormCreateFailure = '[NewEditForm]FormCreateFailure',

  FormUpdateAction = '[NewEditForm]FormUpdateAction',
  FormUpdateSuccess = '[NewEditForm]FormUpdateSuccess',
  FormUpdateFailure = '[NewEditForm]FormUpdateFailure',

  FormGetByIdAction = '[NewEditForm]FormGetByIdAction',
  FormGetByIdSuccess = '[NewEditForm]FormGetByIdSuccess',
  FormGetByIdFailure = '[NewEditForm]FormGetByIdFailure',

  FormFieldTypesAction = '[NewEditForm]FormFieldTypesAction',
  FormFieldTypesSuccess = '[NewEditForm]FormFieldTypesSuccess',
  FormFieldTypesFailure = '[NewEditForm]FormFieldTypesFailure',

  FormGetAllSubFormsAction = '[NewEditForm]FormGetAllSubFormsAction',
  FormGetAllSubFormsSuccess = '[NewEditForm]FormGetAllSubFormsSuccess',
  FormGetAllSubFormsFailure = '[NewEditForm]FormGetAllSubFormsFailure',

  FormGetCatalogsAction = '[NewEditForm]FormGetCatalogsAction',
  FormGetCatalogsSuccess = '[NewEditForm]FormGetCatalogsSuccess',
  FormGetCatalogsFailure = '[NewEditForm]FormGetCatalogsFailure'
}

export class FormCreateAction implements Action {
  readonly type = NewEditFormActionTypes.FormCreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class FormCreateSuccess implements Action {
  readonly type = NewEditFormActionTypes.FormCreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class FormCreateFailure implements Action {
  readonly type = NewEditFormActionTypes.FormCreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class FormUpdateAction implements Action {
  readonly type = NewEditFormActionTypes.FormUpdateAction;

  constructor(public payload: { body: any, id: any }) {
  }
}

export class FormUpdateSuccess implements Action {
  readonly type = NewEditFormActionTypes.FormUpdateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class FormUpdateFailure implements Action {
  readonly type = NewEditFormActionTypes.FormUpdateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class FormFieldTypesAction implements Action {
  readonly type = NewEditFormActionTypes.FormFieldTypesAction;
}

export class FormFieldTypesSuccess implements Action {
  readonly type = NewEditFormActionTypes.FormFieldTypesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class FormFieldTypesFailure implements Action {
  readonly type = NewEditFormActionTypes.FormFieldTypesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class FormGetByIdAction implements Action {
  readonly type = NewEditFormActionTypes.FormGetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class FormGetByIdSuccess implements Action {
  readonly type = NewEditFormActionTypes.FormGetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class FormGetByIdFailure implements Action {
  readonly type = NewEditFormActionTypes.FormGetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class FormGetAllSubFormsAction implements Action {
  readonly type = NewEditFormActionTypes.FormGetAllSubFormsAction;
}

export class FormGetAllSubFormsSuccess implements Action {
  readonly type = NewEditFormActionTypes.FormGetAllSubFormsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class FormGetAllSubFormsFailure implements Action {
  readonly type = NewEditFormActionTypes.FormGetAllSubFormsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class FormGetCatalogsAction implements Action {
  readonly type = NewEditFormActionTypes.FormGetCatalogsAction;
}

export class FormGetCatalogsSuccess implements Action {
  readonly type = NewEditFormActionTypes.FormGetCatalogsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class FormGetCatalogsFailure implements Action {
  readonly type = NewEditFormActionTypes.FormGetCatalogsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type NewEditFormActions =

  | FormCreateAction
  | FormCreateSuccess
  | FormCreateFailure

  | FormUpdateAction
  | FormUpdateSuccess
  | FormUpdateFailure

  | FormGetByIdAction
  | FormGetByIdSuccess
  | FormGetByIdFailure

  | FormFieldTypesAction
  | FormFieldTypesSuccess
  | FormFieldTypesFailure

  | FormGetAllSubFormsAction
  | FormGetAllSubFormsSuccess
  | FormGetAllSubFormsFailure

  | FormGetCatalogsAction
  | FormGetCatalogsSuccess
  | FormGetCatalogsFailure;

