import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {AllFormsService} from './all-forms.service';
import * as all_forms from './all-forms.actions';

@Injectable()
export class AllFormsEffects {

  constructor(private actions$: Actions,
              private allFormsService: AllFormsService) {
  }

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(all_forms.AllFormsActionTypes.AllFormsDeleteAction),
    map((action: all_forms.AllFormsDeleteAction) => action.payload),
    exhaustMap((param: any) =>
      this.allFormsService.deleteForm(param.id).pipe(
        map(success => new all_forms.AllFormsDeleteSuccess({resultHeader: success})),
        catchError(err => of(new all_forms.AllFormsDeleteFailure({validation: err})))
      )
    )
  );
}
