export class CatalogDetail {
  search = '';
}

export class CatalogDetailFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  code = '';
  catalogDetail: CatalogDetail = new CatalogDetail();
}
