import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PhaseFirstService} from './phase-first.service';
import * as phaseFirst from './phase-first.actions';

@Injectable()
export class PhaseFirstEffects {

  constructor(private actions$: Actions,
              private phaseFirstService: PhaseFirstService) {
  }

  @Effect()
  getConvocatorieDetail = this.actions$.pipe(
    ofType(phaseFirst.PhaseFirstActionTypes.EvaluationGetAction),
    map((action: phaseFirst.EvaluationGetAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseFirstService.getEvaluation(param.id).pipe(
        map(success => new phaseFirst.EvaluationGetSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationGetFailure({validation: err})))
      )
    )
  );

  @Effect()
  getTotals = this.actions$.pipe(
    ofType(phaseFirst.PhaseFirstActionTypes.EvaluationTotalsAction),
    map((action: phaseFirst.EvaluationTotalsAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseFirstService.getPostulationsStatus(param.id).pipe(
        map(success => new phaseFirst.EvaluationTotalsSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationTotalsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getPdf = this.actions$.pipe(
    ofType(phaseFirst.PhaseFirstActionTypes.EvaluationPDfAction),
    map((action: phaseFirst.EvaluationPDfAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseFirstService.getPDF(param.id, param.type).pipe(
        map(success => new phaseFirst.EvaluationPDfSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationPDfFailure({validation: err})))
      )
    )
  );

  @Effect()
  getExcel = this.actions$.pipe(
    ofType(phaseFirst.PhaseFirstActionTypes.EvaluationExcelAction),
    map((action: phaseFirst.EvaluationExcelAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseFirstService.getExcel(param.id).pipe(
        map(success => new phaseFirst.EvaluationExcelSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationExcelFailure({validation: err})))
      )
    )
  );

  @Effect()
  postulationReport$ = this.actions$.pipe(
    ofType(phaseFirst.PhaseFirstActionTypes.PostulationReportAction),
    map((action: phaseFirst.PostulationReportAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseFirstService.getReport(param.hash).pipe(
        map(success => new phaseFirst.PostulationReportSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.PostulationReportFailure({validation: err})))
      )
    )
  );

  @Effect()
  postulationQualified$ = this.actions$.pipe(
    ofType(phaseFirst.PhaseFirstActionTypes.PostulationQualifiedAction),
    map((action: phaseFirst.PostulationQualifiedAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseFirstService.startQualified(param.evaluation_id, param.postulation_id).pipe(
        map(success => new phaseFirst.PostulationQualifiedSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.PostulationQualifiedFailure({validation: err})))
      )
    )
  );

  @Effect()
  phaseFirstEnd$ = this.actions$.pipe(
    ofType(phaseFirst.PhaseFirstActionTypes.PhaseFirstEndAction),
    map((action: phaseFirst.PhaseFirstEndAction) => action.payload),
    exhaustMap((param: any) =>
      this.phaseFirstService.finishPhase(param.id, param.phase).pipe(
        map(success => new phaseFirst.PhaseFirstEndSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.PhaseFirstEndFailure({validation: err})))
      )
    )
  );
}
