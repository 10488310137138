import {Action} from '@ngrx/store';

export enum DraftsPreparationsActionTypes {
  GetConvocatoriesAction = '[DraftsPreparations]GetConvocatoriesAction',
  GetConvocatoriesSuccess = '[DraftsPreparations]GetConvocatoriesSuccess',
  GetConvocatoriesFailure = '[DraftsPreparations]GetConvocatoriesFailure',

  GetInterventionAreasAction = '[DraftsPreparations]GetInterventionAreasAction',
  GetInterventionAreasSuccess = '[DraftsPreparations]GetInterventionAreasSuccess',
  GetInterventionAreasFailure = '[DraftsPreparations]GetInterventionAreasFailure',

  GetInterventionLinesAction = '[DraftsPreparations]GetInterventionLinesAction',
  GetInterventionLinesSuccess = '[DraftsPreparations]GetInterventionLinesSuccess',
  GetInterventionLinesFailure = '[DraftsPreparations]GetInterventionLinesFailure',

  ArchiveAction = '[DraftsPreparations]ArchiveAction',
  ArchiveSuccess = '[DraftsPreparations]ArchiveSuccess',
  ArchiveFailure = '[DraftsPreparations]ArchiveFailure'
}

export class GetInterventionAreasAction implements Action {
  readonly type = DraftsPreparationsActionTypes.GetInterventionAreasAction;
}

export class GetInterventionAreasSuccess implements Action {
  readonly type = DraftsPreparationsActionTypes.GetInterventionAreasSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetInterventionAreasFailure implements Action {
  readonly type = DraftsPreparationsActionTypes.GetInterventionAreasFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetInterventionLinesAction implements Action {
  readonly type = DraftsPreparationsActionTypes.GetInterventionLinesAction;
}

export class GetInterventionLinesSuccess implements Action {
  readonly type = DraftsPreparationsActionTypes.GetInterventionLinesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetInterventionLinesFailure implements Action {
  readonly type = DraftsPreparationsActionTypes.GetInterventionLinesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetConvocatoriesAction implements Action {
  readonly type = DraftsPreparationsActionTypes.GetConvocatoriesAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetConvocatoriesSuccess implements Action {
  readonly type = DraftsPreparationsActionTypes.GetConvocatoriesSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetConvocatoriesFailure implements Action {
  readonly type = DraftsPreparationsActionTypes.GetConvocatoriesFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class ArchiveAction implements Action {
  readonly type = DraftsPreparationsActionTypes.ArchiveAction;

  constructor(public payload: { id: any }) {
  }
}

export class ArchiveSuccess implements Action {
  readonly type = DraftsPreparationsActionTypes.ArchiveSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class ArchiveFailure implements Action {
  readonly type = DraftsPreparationsActionTypes.ArchiveFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type DraftsPreparationsActions =
  | GetConvocatoriesAction
  | GetConvocatoriesSuccess
  | GetConvocatoriesFailure

  | GetInterventionAreasAction
  | GetInterventionAreasSuccess
  | GetInterventionAreasFailure

  | GetInterventionLinesAction
  | GetInterventionLinesSuccess
  | GetInterventionLinesFailure;
