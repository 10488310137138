import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as Auth from '../store/auth.actions';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store<any>) {
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(s => s.auth),
      map(auth => {
        if (!auth.user) {
          this.store.dispatch(new Auth.LoginRedirect());
          return false;
        }
        return true;
      }));
  }
}
