import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './dashboard.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DashboardEffects} from './dashboard.effects';
import {DashboardService} from './dashboard.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppConvocatoriesDahsboardModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppConvocatoriesDashboardModule,
      providers: [
        {provide: 'config', useValue: config},
        DashboardService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppConvocatoriesDahsboardModule,
    StoreModule.forFeature('appConvocatoriesDashboard', reducer),
    EffectsModule.forFeature([DashboardEffects]),
  ],
})
export class RootAppConvocatoriesDashboardModule {
}
