import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {PiuFilter} from '../piu.model';


@Injectable()
export class PiuService {
	private piuFilter = new BehaviorSubject<any>(new PiuFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	currentPiuFilter(): Observable<any> {
    	return this.piuFilter.asObservable();
  	}
  	getPiuFilter() {
    	return this.piuFilter.getValue();
  	}
  	sendPiuFilter(object: any) {
    	this.piuFilter.next(object);
  	}
	getPius(piuFilter: PiuFilter): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}admins/piu`;
		const params = createRequestOption({
			'page': piuFilter.page,
			'limit': piuFilter.limit,
			'orderBy': piuFilter.orderBy,
			'sortedBy': piuFilter.sortedBy,
			'search': piuFilter.convocatorie.search
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	createUser(data: any){
		let apiUrl = `${environment.app.apiBaseUrl}users/${data.role}`;
		return this.http.post(apiUrl, data, { observe: 'response' });
	}
	editUser(id: string, data: any){
		let apiUrl = `${environment.app.apiBaseUrl}users/${id}`;
		return this.http.put(apiUrl, data, { observe: 'response' });
	}
	enableDisable(id: string){
		let apiUrl = `${environment.app.apiBaseUrl}users/${id}/change/status`;
		return this.http.patch(apiUrl, {}, { observe: 'response' });
	}
}