import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {NewEditConvocatorieService} from './new-edit-convocatorie.service';
import * as new_edit_convocatorie from '../store/new-edit-convocatorie.actions';

@Injectable()
export class NewEditConvocatorieEffects {

  constructor(private actions$: Actions,
              private newEditConvocatorieService: NewEditConvocatorieService) {
  }

  @Effect()
  create$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieCreateAction),
    map((action: new_edit_convocatorie.ConvocatorieCreateAction) => action.payload),
    exhaustMap((param: any) =>
      this.newEditConvocatorieService.createConvocatorie(param).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieCreateSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieCreateFailure({validation: err})))
      )
    )
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieUpdateAction),
    map((action: new_edit_convocatorie.ConvocatorieUpdateAction) => action.payload),
    exhaustMap((param: any) =>
      this.newEditConvocatorieService.modifyConvocatorie(param.body, param.id).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieUpdateSuccess({
          resultHeader: success,
          typesave: param.typesave
        })),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieUpdateFailure({
          validation: err,
          typesave: param.typesave
        })))
      )
    )
  );

  @Effect()
  getById$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieGetByIdAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: new_edit_convocatorie.ConvocatorieGetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.newEditConvocatorieService.getByIdConvocatorie(param.id).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieGetByIdSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieGetByIdFailure({validation: err})))
      )
    )
  );

  @Effect()
  getInterventionAreas$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieGetInterventionAreasAction),
    exhaustMap(() =>
      this.newEditConvocatorieService.getInterventionAreas().pipe(
        map(success => new new_edit_convocatorie.ConvocatorieGetInterventionAreasSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieGetInterventionAreasFailure({validation: err})))
      )
    )
  );

  @Effect()
  getInterventionLines$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieGetInterventionLinesAction),
    exhaustMap(() =>
      this.newEditConvocatorieService.getInterventionLines().pipe(
        map(success => new new_edit_convocatorie.ConvocatorieGetInterventionLinesSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieGetInterventionLinesFailure({validation: err})))
      )
    )
  );

  @Effect()
  getForms$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieGetFormsAction),
    map((action: new_edit_convocatorie.ConvocatorieGetFormsAction) => action.payload),
    exhaustMap((param: any) =>
      this.newEditConvocatorieService.getForms(param.id).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieGetFormsSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieGetFormsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getSectors$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieGetSectorsAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: new_edit_convocatorie.ConvocatorieGetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.newEditConvocatorieService.getSectors(param.id).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieGetSectorsSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieGetSectorsFailure({validation: err})))
      )
    )
  );

  @Effect()
  getSpecialities$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieGetSpecialitiesAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: new_edit_convocatorie.ConvocatorieGetByIdAction) => action.payload),
    switchMap((param: any) =>
      this.newEditConvocatorieService.getSpecialties(param.id).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieGetSpecialitiesSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieGetSpecialitiesFailure({validation: err})))
      )
    )
  );

  @Effect()
  getSubsectors$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieGetSubsectorsAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: new_edit_convocatorie.ConvocatorieGetSubsectorsAction) => action.payload),
    switchMap((param: any) =>
      this.newEditConvocatorieService.getSubsectors(param.id).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieGetSubsectorsSuccess({resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieGetSubsectorsFailure({validation: err})))
      )
    )
  );

  @Effect()
  deleteFile$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieDeleteFileAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: new_edit_convocatorie.ConvocatorieDeleteFileAction) => action.payload),
    switchMap((param: any) =>
      this.newEditConvocatorieService.deleteFile(param.id).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieDeleteFileSuccess({
          resultHeader: success,
          type: param.type,
          index: param.index
        })),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieDeleteFileFailure({
          validation: err,
          type: param.type,
          index: param.index
        })))
      )
    )
  );

  @Effect()
  getAlls$ = this.actions$.pipe(
    ofType(new_edit_convocatorie.NewEditConvocatorieActionTypes.ConvocatorieGetAllAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: new_edit_convocatorie.ConvocatorieGetAllAction) => action.payload),
    switchMap((param: any) =>
      this.newEditConvocatorieService.getAllData(param.sector).pipe(
        map(success => new new_edit_convocatorie.ConvocatorieGetAllSuccess({ resultHeader: success})),
        catchError(err => of(new new_edit_convocatorie.ConvocatorieGetAllFailure({validation: err})))
      )
    )
  );
}
