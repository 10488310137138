import {Action} from '@ngrx/store';

export enum PostulationsActionTypes {
  GetConvocatoryDetailAction = '[ConvocatoriesPostulations]GetConvocatoryDetailAction',
  GetConvocatoryDetailSuccess = '[ConvocatoriesPostulations]GetConvocatoryDetailSuccess',
  GetConvocatoryDetailFailure = '[ConvocatoriesPostulations]GetConvocatoryDetailFailure',

  GetTotalPostulationsAction = '[ConvocatoriesPostulations]GetTotalPostulationsAction',
  GetTotalPostulationsSuccess = '[ConvocatoriesPostulations]GetTotalPostulationsSuccess',
  GetTotalPostulationsFailure = '[ConvocatoriesPostulations]GetTotalPostulationsFailure',

  PostulationsGetSubsectorsAction = '[ConvocatoriesPostulations]PostulationsGetSubsectorsAction',
  PostulationsGetSubsectorsSuccess = '[ConvocatoriesPostulations]PostulationsGetSubsectorsSuccess',
  PostulationsGetSubsectorsFailure = '[ConvocatoriesPostulations]PostulationsGetSubsectorsFailure',

  GetReportAction = '[ConvocatoriesPostulations]GetReportAction',
  GetReportSuccess = '[ConvocatoriesPostulations]GetReportSuccess',
  GetReportFailure = '[ConvocatoriesPostulations]GetReportFailure'
}

export class GetConvocatoryDetailAction implements Action {
  readonly type = PostulationsActionTypes.GetConvocatoryDetailAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetConvocatoryDetailSuccess implements Action {
  readonly type = PostulationsActionTypes.GetConvocatoryDetailSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetConvocatoryDetailFailure implements Action {
  readonly type = PostulationsActionTypes.GetConvocatoryDetailFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetTotalPostulationsAction implements Action {
  readonly type = PostulationsActionTypes.GetTotalPostulationsAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetTotalPostulationsSuccess implements Action {
  readonly type = PostulationsActionTypes.GetTotalPostulationsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetTotalPostulationsFailure implements Action {
  readonly type = PostulationsActionTypes.GetTotalPostulationsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationsGetSubsectorsAction implements Action {
  readonly type = PostulationsActionTypes.PostulationsGetSubsectorsAction;

  constructor(public payload: { id: any }) {
  }
}

export class PostulationsGetSubsectorsSuccess implements Action {
  readonly type = PostulationsActionTypes.PostulationsGetSubsectorsSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationsGetSubsectorsFailure implements Action {
  readonly type = PostulationsActionTypes.PostulationsGetSubsectorsFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class GetReportAction implements Action {
  readonly type = PostulationsActionTypes.GetReportAction;

  constructor(public payload: { id: any }) {
  }
}

export class GetReportSuccess implements Action {
  readonly type = PostulationsActionTypes.GetReportSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class GetReportFailure implements Action {
  readonly type = PostulationsActionTypes.GetReportFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type PostulationsActions =
  | GetConvocatoryDetailAction
  | GetConvocatoryDetailSuccess
  | GetConvocatoryDetailFailure

  | GetTotalPostulationsAction
  | GetTotalPostulationsSuccess
  | GetTotalPostulationsFailure

  | PostulationsGetSubsectorsAction
  | PostulationsGetSubsectorsSuccess
  | PostulationsGetSubsectorsFailure

  | GetReportAction
  | GetReportSuccess
  | GetReportFailure;
