import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PostulantsService} from './ponstulants.service';
import * as postulants from './ponstulants.actions';

@Injectable()
export class PostulantsEffects {

  constructor(private actions$: Actions,
              private postulantsService: PostulantsService) {
  }

  /*@Effect()
  create$ = this.actions$.pipe(
    ofType(postulants.EvaluatorsActionTypes.EvaluatorAddAction),
    map((action: postulants.EvaluatorAddAction) => action.payload),
    exhaustMap((param: any) =>
      this.evaluatorsService.createUser(param.data).pipe(
        map(success => new postulants.EvaluatorAddSuccess({resultHeader: success})),
        catchError(err => of(new postulants.EvaluatorAddFailure({validation: err})))
      )
    )
  );*/

}
