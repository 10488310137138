import {SectorsActions} from './sectors.actions';

export interface SectorsState {
  dataTable: any;
}

export const initialState: SectorsState = {
  dataTable: undefined
};

export function reducer(state: SectorsState = initialState, action: SectorsActions): SectorsState {

  switch (action.type) {

    default:
      return state;
  }
}
