import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './intervention-areas.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {InterventionAreasEffects} from './intervention-areas.effects';
import {InterventionAreasService} from './intervention-areas.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppInterventionAreasModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppInterventionAreasModule,
      providers: [
        {provide: 'config', useValue: config},
        InterventionAreasService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppInterventionAreasModule,
    StoreModule.forFeature('appInterventionAreas', reducer),
    EffectsModule.forFeature([InterventionAreasEffects]),
  ],
})
export class RootAppInterventionAreasModule {
}
