export class Postulation {
  search = '';
}

export class PostulationsFilter {
  limit = 10;
  page = 1;
  orderBy = '';
  sortedBy = '';
  postulation: Postulation = new Postulation();
}