export class Subsector {
  search = '';
}

export class SubsectorsFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  subsector: Subsector = new Subsector();
}
