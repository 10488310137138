import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ArchivedFinishedService} from './archived-finished.service';
import * as archived_finished from './archived-finished.actions';

@Injectable()
export class ArchivedFinishedEffects {

  constructor(private actions$: Actions,
              private archivedFinishedService: ArchivedFinishedService) {
  }

  @Effect()
  getConvocatories$ = this.actions$.pipe(
    ofType(archived_finished.AllConvocatoriesActionTypes.GetConvocatoriesFileAction),
    map((action: archived_finished.GetConvocatoriesFileAction) => action.payload),
    exhaustMap((param: any) =>
      this.archivedFinishedService.fileConvocatorie(param.id).pipe(
        map(success => new archived_finished.GetConvocatoriesFileSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetConvocatoriesFileFailure({validation: err})))
      )
    )
  );

  @Effect()
  getInterventionLines$ = this.actions$.pipe(
    ofType(archived_finished.AllConvocatoriesActionTypes.GetInterventionLinesAction),
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((param: any) =>
      this.archivedFinishedService.getInterventionLines().pipe(
        map(success => new archived_finished.GetInterventionLinesSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetInterventionLinesFailure({validation: err})))
      )
    )
  );

  @Effect()
  getInterventionAreas$ = this.actions$.pipe(
    ofType(archived_finished.AllConvocatoriesActionTypes.GetInterventionAreasAction),
    debounceTime(200),
    distinctUntilChanged(),
    switchMap((param: any) =>
      this.archivedFinishedService.getInterventionAreas().pipe(
        map(success => new archived_finished.GetInterventionAreasSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetInterventionAreasFailure({validation: err})))
      )
    )
  );

}
