import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './postulations.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PostulationsEffects} from './postulations.effects';
import {PostulationsService} from './postulations.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppPostulationsJudgeModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootPostulationsJudgeModule,
      providers: [
        {provide: 'config', useValue: config},
        PostulationsService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppPostulationsJudgeModule,
    StoreModule.forFeature('appPostulationsJudge', reducer),
    EffectsModule.forFeature([PostulationsEffects]),
  ],
})
export class RootPostulationsJudgeModule {
}
