import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {LogsFilter} from '../logs.model';


@Injectable()
export class LogsService {
	private logsFilter = new BehaviorSubject<any>(new LogsFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	currentLogsFilter(): Observable<any> {
    	return this.logsFilter.asObservable();
  	}
  	getLogsFilter() {
    	return this.logsFilter.getValue();
  	}
  	sendLogsFilter(object: any) {
    	this.logsFilter.next(object);
  	}
	getLogs(logFilter: LogsFilter): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}activities`;
		const params = createRequestOption({
			'search': 'log_name:'+logFilter.searchBy,
			'page': logFilter.page,
			'limit': logFilter.limit,
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	getActivities(){
		let apiUrl =`${environment.app.apiBaseUrl}activities/types`;
		return this.http.get(apiUrl, {observe: 'response'});
	}
}