import {Action} from '@ngrx/store';

export enum SectorsActionTypes {
  SectorsCreateAction = '[Sectors]SectorsCreateAction',
  SectorsCreateSuccess = '[Sectors]SectorsCreateSuccess',
  SectorsCreateFailure = '[Sectors]SectorsCreateFailure',

  SectorsUpdateAction = '[Sectors]SectorsUpdateAction',
  SectorsUpdateSuccess = '[Sectors]SectorsUpdateSuccess',
  SectorsUpdateFailure = '[Sectors]SectorsUpdateFailure',

  SectorsDeleteAction = '[Sectors]SectorsDeleteAction',
  SectorsDeleteSuccess = '[Sectors]SectorsDeleteSuccess',
  SectorsDeleteFailure = '[Sectors]SectorsDeleteFailure',

  SectorsGetByIdAction = '[Sectors]SectorsGetByIdAction',
  SectorsGetByIdSuccess = '[Sectors]SectorsGetByIdSuccess',
  SectorsGetByIdFailure = '[Sectors]SectorsGetByIdFailure'
}

export class SectorsCreateAction implements Action {
  readonly type = SectorsActionTypes.SectorsCreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class SectorsCreateSuccess implements Action {
  readonly type = SectorsActionTypes.SectorsCreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SectorsCreateFailure implements Action {
  readonly type = SectorsActionTypes.SectorsCreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SectorsUpdateAction implements Action {
  readonly type = SectorsActionTypes.SectorsUpdateAction;

  constructor(public payload: { body: any, id: any }) {
  }
}

export class SectorsUpdateSuccess implements Action {
  readonly type = SectorsActionTypes.SectorsUpdateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SectorsUpdateFailure implements Action {
  readonly type = SectorsActionTypes.SectorsUpdateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SectorsDeleteAction implements Action {
  readonly type = SectorsActionTypes.SectorsDeleteAction;

  constructor(public payload: { id: any }) {
  }
}

export class SectorsDeleteSuccess implements Action {
  readonly type = SectorsActionTypes.SectorsDeleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SectorsDeleteFailure implements Action {
  readonly type = SectorsActionTypes.SectorsDeleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SectorsGetByIdAction implements Action {
  readonly type = SectorsActionTypes.SectorsGetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class SectorsGetByIdSuccess implements Action {
  readonly type = SectorsActionTypes.SectorsGetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SectorsGetByIdFailure implements Action {
  readonly type = SectorsActionTypes.SectorsGetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type SectorsActions =
  | SectorsCreateAction
  | SectorsCreateSuccess
  | SectorsCreateFailure

  | SectorsUpdateAction
  | SectorsUpdateSuccess
  | SectorsUpdateFailure

  | SectorsDeleteAction
  | SectorsDeleteSuccess
  | SectorsDeleteFailure

  | SectorsGetByIdAction
  | SectorsGetByIdSuccess
  | SectorsGetByIdFailure;
