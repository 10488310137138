import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PhaseThirdService} from './phase-third.service';
import * as phaseFirst from './phase-third.actions';

@Injectable()
export class PhaseThirdEffects {

  constructor(private actions$: Actions,
              private PhaseThirdService: PhaseThirdService) {
  }

  @Effect()
  getDetail = this.actions$.pipe(
    ofType(phaseFirst.PhaseThirdActionTypes.EvaluationPostulationDetailAction),
    map((action: phaseFirst.EvaluationPostulationDetailAction) => action.payload),
    exhaustMap((param: any) =>
      this.PhaseThirdService.getDetail(param.evaluation_id, param.postulation_id).pipe(
        map(success => new phaseFirst.EvaluationPostulationDetailSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationPostulationDetailFailure({validation: err})))
      )
    )
  );

  @Effect()
  getPdf = this.actions$.pipe(
    ofType(phaseFirst.PhaseThirdActionTypes.EvaluationReportPDfAction),
    map((action: phaseFirst.EvaluationReportPDfAction) => action.payload),
    exhaustMap((param: any) =>
      this.PhaseThirdService.getPDF(param.id).pipe(
        map(success => new phaseFirst.EvaluationReportPDfSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationReportPDfFailure({validation: err})))
      )
    )
  );

  @Effect()
  getExcel = this.actions$.pipe(
    ofType(phaseFirst.PhaseThirdActionTypes.EvaluationReportExcelAction),
    map((action: phaseFirst.EvaluationReportExcelAction) => action.payload),
    exhaustMap((param: any) =>
      this.PhaseThirdService.getExcel(param.id, param.data).pipe(
        map(success => new phaseFirst.EvaluationReportExcelSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationReportExcelFailure({validation: err})))
      )
    )
  );

  @Effect()
  getEspecial = this.actions$.pipe(
    ofType(phaseFirst.PhaseThirdActionTypes.EvaluationEspecialReportAction),
    map((action: phaseFirst.EvaluationEspecialReportAction) => action.payload),
    exhaustMap((param: any) =>
      this.PhaseThirdService.getEspecial(param.id).pipe(
        map(success => new phaseFirst.EvaluationEspecialReportSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationEspecialReportFailure({validation: err})))
      )
    )
  );

  @Effect()
  postulationWinner$ = this.actions$.pipe(
    ofType(phaseFirst.PhaseThirdActionTypes.PostulationWinnerAction),
    map((action: phaseFirst.PostulationWinnerAction) => action.payload),
    exhaustMap((param: any) =>
      this.PhaseThirdService.setWiner(param.evaluation_id, param.postulation_id).pipe(
        map(success => new phaseFirst.PostulationWinnerSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.PostulationWinnerFailure({validation: err})))
      )
    )
  );

  @Effect()
  phaseComplete$ = this.actions$.pipe(
    ofType(phaseFirst.PhaseThirdActionTypes.EvaluationCompleteAction),
    map((action: phaseFirst.EvaluationCompleteAction) => action.payload),
    exhaustMap((param: any) =>
      this.PhaseThirdService.finishPhase(param.id, param.data).pipe(
        map(success => new phaseFirst.EvaluationCompleteSuccess({resultHeader: success})),
        catchError(err => of(new phaseFirst.EvaluationCompleteFailure({validation: err})))
      )
    )
  );
}
