import {CatalogsActions} from './catalogs.actions';

export interface CatalogsState {
  dataTable: any;
}

export const initialState: CatalogsState = {
  dataTable: undefined
};

export function reducer(state: CatalogsState = initialState, action: CatalogsActions): CatalogsState {

  switch (action.type) {

    default:
      return state;
  }

}
