import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './profile.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {ProfileEffects} from './profile.effects';
import {ProfileService} from './profile.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppProfileUsersModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppProfileUsersModule,
      providers: [
        {provide: 'config', useValue: config},
        ProfileService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppProfileUsersModule,
    StoreModule.forFeature('appProfileUsers', reducer),
    EffectsModule.forFeature([ProfileEffects]),
  ],
})
export class RootAppProfileUsersModule {
}
