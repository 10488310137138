// Angular
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
// RxJS
import {Observable, BehaviorSubject, of} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {DataTableService, DataTableItemModel} from '../../../index';

export class DataTableDataSource implements DataSource<DataTableItemModel> {
  // Public properties
  entitySubject = new BehaviorSubject<any[]>([]);
  hasItems: boolean = false; // Need to show message: 'No records found

  // Loading | Progress bar
  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean>;

  // Paginator | Paginators count
  paginatorTotalSubject = new BehaviorSubject<number>(0);
  paginatorTotal$: Observable<number>;

  /**
   * Data-Source Constructor
   *
   * @param dataTableService: DataTableService
   */
  constructor(private dataTableService: DataTableService) {
    this.loading$ = this.loadingSubject.asObservable();
    this.paginatorTotal$ = this.paginatorTotalSubject.asObservable();
    this.paginatorTotal$.subscribe(res => this.hasItems = res > 0);
  }

  /**
   * Connect data-source
   *
   * @param collectionViewer: CollectionViewer
   */
  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    // Connecting data source
    return this.entitySubject.asObservable();
  }

  /**
   * Disconnect data-source
   *
   * @param collectionViewer: CollectionViewer
   */
  disconnect(collectionViewer: CollectionViewer): void {
    // Disonnecting data source
    this.entitySubject.complete();
    this.loadingSubject.complete();
    this.paginatorTotalSubject.complete();
  }

  baseFilter(_entities: any[], _queryParams: any): any {
    return [];
  }

  loadItems(queryParams: any) {
    this.loadingSubject.next(true);
    this.dataTableService.getAllItems().pipe(
      tap(res => {
        const result = this.baseFilter(res, queryParams);
        this.entitySubject.next(result.items);
        this.paginatorTotalSubject.next(result.totalCount);

      }),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe();
  }
}
