import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './dashboard.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DashboardEffects} from './dashboard.effects';
import {DashboardService} from './dashboard.service';
import {AppConfig} from "../../../shared/models/app-config.model";

@NgModule({
  imports: [CommonModule]
})

export class AppDashboardModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppDashboardModule,
      providers: [
        {provide: 'config', useValue: config},
        DashboardService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppDashboardModule,
    StoreModule.forFeature('appDashboard', reducer),
    EffectsModule.forFeature([DashboardEffects]),
  ],
})

export class RootAppDashboardModule {
}
