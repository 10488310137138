import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PostulationDetailService} from './postulation-detail.service';
import * as archived_finished from './postulation-detail.actions';

@Injectable()
export class PostulationDetailEffects {

  constructor(private actions$: Actions,
              private archivedFinishedService: PostulationDetailService) {
  }

  @Effect()
  getDetailConvocatory$ = this.actions$.pipe(
    ofType(archived_finished.PostulationDetailActionTypes.GetConvocatoryDetailAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetConvocatoryDetailAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getConvocatoryDetail(param.id).pipe(
        map(success => new archived_finished.GetConvocatoryDetailSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetConvocatoryDetailFailure({validation: err})))
      )
    )
  );

  @Effect()
  getDetailPostulation$ = this.actions$.pipe(
    ofType(archived_finished.PostulationDetailActionTypes.GetPostulationDetailAction),
    debounceTime(200),
    distinctUntilChanged(),
    map((action: archived_finished.GetPostulationDetailAction) => action.payload),
    switchMap((param: any) =>
      this.archivedFinishedService.getPostulationDetail(param.id).pipe(
        map(success => new archived_finished.GetPostulationDetailSuccess({resultHeader: success})),
        catchError(err => of(new archived_finished.GetPostulationDetailFailure({validation: err})))
      )
    )
  );

}
