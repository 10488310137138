import {Inject, Injectable} from '@angular/core';
import {Observable, throwError,} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams, HttpResponse, HttpHeaders} from '@angular/common/http';
import {AuthenticateResponse, AuthenticateByLogin, User, AuthenticateByRefreshToken} from '../models/auth.models';
import {AuthConfig} from '../models/auth-config.model';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient,
              @Inject('authConfig') private config: AuthConfig) {
  }

  login(credentials: AuthenticateByLogin): Observable<{ user: User, confirmed: boolean, authenticate: AuthenticateResponse }> {
    return this.http.post(`${this.config.apiBaseUrl}admin/login`, {
      email: credentials.email,
      password: credentials.password
    })
      .pipe<{ user: User, confirmed: boolean, authenticate: AuthenticateResponse }>(
        map((data: any) => {
          return {
            user: {
              id: data.data.id,
              username: data.data.name,
              email: data.data.email,
              roles: data.data.roles.data
            },
            confirmed: data.data.confirmed,
            authenticate: {
              authToken: data.meta.custom.authorization.access_token,
              refreshToken: data.meta.custom.authorization.refresh_token
            }
          };
        })
      );
  }

  logout(): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.config.apiBaseUrl}logout`, {observe: 'response'});
  }

  loginFacebook(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.config.apiBaseUrl}auth/facebook`, body, {observe: 'response'});
  }

  verifyEmail(user: any, expires: any, signature: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.config.apiBaseUrl}email/verify/` + user + '?expires= ' + expires + '&signature= ' + signature,
      {observe: 'response'});
  }

  getProfile(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.config.apiBaseUrl}user/profile?include=roles`, {observe: 'response'});
  }

  refreshToken(refreshToken: AuthenticateByRefreshToken): Observable<{ confirmed: boolean, authenticate: AuthenticateResponse }> {
    if (!refreshToken || !refreshToken.refreshToken)
      return throwError({user: null, confirmed: false, authenticate: null});

    const body = new HttpParams()
      .set('refresh_token', refreshToken.refreshToken)

    return this.http.post(`${this.config.apiBaseUrl}admin/refresh`, body)
      .pipe(
        map((data: any) => {
          return {
            confirmed: true,
            authenticate: {authToken: data.access_token, refreshToken: data.refresh_token}
          };
        })
      );
  }

  resend(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.config.apiBaseUrl}email/resend`, body, {observe: 'response'});
  }

  resetPassword(user: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept','application/json');
    httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(`${this.config.apiBaseUrl}password/reset`, user, { headers: httpHeaders })
        .pipe(
            map((data: any) => {
                return data;
            })
        );
  }
  public requestPassword(email: any): Observable<any> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept','application/json');
    httpHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(`${this.config.apiBaseUrl}password/forgot`,email, {headers: httpHeaders})
        .pipe(
            map((data: any) => {
                return data;
            })
        );
  }
}
