import {Inject, Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {exhaustMap, map, catchError, withLatestFrom} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {of} from 'rxjs';
import {AuthenticateByLogin} from '../models/auth.models';
import {Store} from '@ngrx/store';
import {AuthConfig} from '../models/auth-config.model';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    @Inject('authConfig') private config: AuthConfig,
    private service: AuthService,
    private router: Router,
    private store: Store<any>
  ) {
  }

  @Effect()
  logoutSucess$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.LogoutSuccess, AuthActions.AuthActionTypes.AuthenticationFailure),
    map(() => new AuthActions.LoginRedirect())
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.LogoutAction),
    exhaustMap(() =>
      this.service.logout().pipe(
        map(() => new AuthActions.LogoutSuccess())
      )
    )
  );

  @Effect()
  login$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.Login),
    map((action: AuthActions.Login) => action.payload),
    exhaustMap((param: { credentials: AuthenticateByLogin }) =>
      this.service.login(param.credentials).pipe(
        map(success => new AuthActions.AuthenticationSuccess(success)),
        catchError(error => of(new AuthActions.AuthenticationFailure(error)))
      )
    )
  );

  @Effect()
  requestAuthenticationFailure$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.RequestAuthenticationFailure),
    withLatestFrom(this.store),
    map(([action, storeState]) =>
      window.localStorage.getItem('authenticate') && JSON.parse(window.localStorage.getItem('authenticate')).refreshToken
        ? new AuthActions.RefreshToken({refreshToken: JSON.parse(window.localStorage.getItem('authenticate')).refreshToken})
        : new AuthActions.AuthenticationFailure('no-refresh-token')
    )
  );

  @Effect()
  refreshToken$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.RefreshToken),
    exhaustMap((action: AuthActions.RefreshToken) =>
      this.service.refreshToken({refreshToken: action.payload.refreshToken}).pipe(
        map(success => new AuthActions.AuthenticationSuccess(success)),
        catchError(error => of(new AuthActions.AuthenticationFailure(error)))
      )
    )
  );

  @Effect()
  loginFacebook$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.AuthenticationFacebookAction),
    map((action: any) => action.payload),
    exhaustMap((param: any) =>
      this.service.loginFacebook(param).pipe(
        map((success: any) => {
          if(success.body.data.roles.data.filter(v=>{return v.name!='postulant'}).length > 0){
            return new AuthActions.AuthenticationSuccess({
              user: {
                id: success.body.data.id,
                username: success.body.data.name,
                email: success.body.data.email,
                roles: success.body.data.roles.data
              },
              confirmed: success.body.data.confirmed,
              authenticate: {
                authToken: success.body.meta.custom.authorization.access_token,
                refreshToken: success.body.meta.custom.authorization.refresh_token
              }
            })
          }else{
            return new AuthActions.AuthenticationFacebookFailure(success);
          }
        }),
        catchError(error => of(new AuthActions.AuthenticationFacebookFailure(error)))
      )
    )
  );

  @Effect()
  verifyEmail$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.AuthenticationVerifyEmailAction),
    map((action: AuthActions.Login) => action.payload),
    exhaustMap((param: any) =>
      this.service.verifyEmail(param.user, param.expires, param.signature).pipe(
        map(success => new AuthActions.AuthenticationVerifyEmailSuccess({resultHeader: success})),
        catchError(error => of(new AuthActions.AuthenticationVerifyEmailFailure({validation: error})))
      )
    )
  );

  @Effect()
  resentEmail$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.AuthenticationResendEmailAction),
    map((action: AuthActions.AuthenticationResendEmailAction) => action.payload),
    exhaustMap((param: any) =>
      this.service.resend(param.body).pipe(
        map(success => new AuthActions.AuthenticationResendEmailSuccess({resultHeader: success})),
        catchError(error => of(new AuthActions.AuthenticationResendEmailFailure({validation: error})))
      )
    )
  );

  @Effect()
  getProfile$ = this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.AuthenticationGetProfileAction),
    exhaustMap(() =>
      this.service.getProfile().pipe(
        map(success => new AuthActions.AuthenticationGetProfileSuccess({resultHeader: success})),
        catchError(error => of(new AuthActions.AuthenticationGetProfileFailure({validation: error})))
      )
    )
  );

}
