export class Sector {
  search = '';
}

export class SectorFilter {
  limit = 10;
  page = 1;
  orderBy = 'updated_at';
  sortedBy = 'desc';
  sector: Sector = new Sector();
}
