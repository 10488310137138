import {Action} from '@ngrx/store';

export enum SpecialtiesActionTypes {
  SpecialtiesCreateAction = '[Specialties]SpecialtiesCreateAction',
  SpecialtiesCreateSuccess = '[Specialties]SpecialtiesCreateSuccess',
  SpecialtiesCreateFailure = '[Specialties]SpecialtiesCreateFailure',

  SpecialtiesUpdateAction = '[Specialties]SpecialtiesUpdateAction',
  SpecialtiesUpdateSuccess = '[Specialties]SpecialtiesUpdateSuccess',
  SpecialtiesUpdateFailure = '[Specialties]SpecialtiesUpdateFailure',

  SpecialtiesDeleteAction = '[Specialties]SpecialtiesDeleteAction',
  SpecialtiesDeleteSuccess = '[Specialties]SpecialtiesDeleteSuccess',
  SpecialtiesDeleteFailure = '[Specialties]SpecialtiesDeleteFailure',

  SpecialtiesGetByIdAction = '[Specialties]SpecialtiesGetByIdAction',
  SpecialtiesGetByIdSuccess = '[Specialties]SpecialtiesGetByIdSuccess',
  SpecialtiesGetByIdFailure = '[Specialties]SpecialtiesGetByIdFailure'
}

export class SpecialtiesCreateAction implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesCreateAction;

  constructor(public payload: { body: any }) {
  }
}

export class SpecialtiesCreateSuccess implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesCreateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SpecialtiesCreateFailure implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesCreateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SpecialtiesUpdateAction implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesUpdateAction;

  constructor(public payload: { body: any, id: any }) {
  }
}

export class SpecialtiesUpdateSuccess implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesUpdateSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SpecialtiesUpdateFailure implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesUpdateFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SpecialtiesDeleteAction implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesDeleteAction;

  constructor(public payload: { id: any }) {
  }
}

export class SpecialtiesDeleteSuccess implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesDeleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SpecialtiesDeleteFailure implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesDeleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class SpecialtiesGetByIdAction implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesGetByIdAction;

  constructor(public payload: { id: any }) {
  }
}

export class SpecialtiesGetByIdSuccess implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesGetByIdSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class SpecialtiesGetByIdFailure implements Action {
  readonly type = SpecialtiesActionTypes.SpecialtiesGetByIdFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type SpecialtiesActions =
  | SpecialtiesCreateAction
  | SpecialtiesCreateSuccess
  | SpecialtiesCreateFailure

  | SpecialtiesUpdateAction
  | SpecialtiesUpdateSuccess
  | SpecialtiesUpdateFailure

  | SpecialtiesDeleteAction
  | SpecialtiesDeleteSuccess
  | SpecialtiesDeleteFailure

  | SpecialtiesGetByIdAction
  | SpecialtiesGetByIdSuccess
  | SpecialtiesGetByIdFailure;
