import {Subscription} from 'rxjs';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {LayoutConfigService, SplashScreenService} from './shared/index';
// Layout
import {TranslationService} from './shared/index';
// language list
import {locale as enLang} from './core/config/i18n/en';
import {locale as chLang} from './core/config/i18n/ch';
import {locale as esLang} from './core/config/i18n/es';
import {locale as jpLang} from './core/config/i18n/jp';
import {locale as deLang} from './core/config/i18n/de';
import {locale as frLang} from './core/config/i18n/fr';
import {ActionsSubject} from '@ngrx/store';
import {filter, tap} from 'rxjs/operators';

import * as AuthActions from '../app/pages/auth/shared/store/auth.actions';

@Component({
  selector: 'app-root',
  template: `
    <kt-splash-screen *ngIf="loader"></kt-splash-screen>
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  loader: boolean;
  private unsubscribe: Subscription[] = [];

  constructor(private actions: ActionsSubject,
              private translationService: TranslationService,
              private router: Router,
              private layoutConfigService: LayoutConfigService,
              private splashScreenService: SplashScreenService) {
    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
  }

  ngOnInit(): void {


    // LOGOUT
    this.actions.pipe(
      filter(s => s.type === AuthActions.AuthActionTypes.LoginRedirect),
      tap(s => this.router.navigate(['/']))
    ).subscribe();
    // LOGOUT


    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
