import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  getDetailConvocatory(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}convocatories/detail/${id}`, {observe: 'response'});
  }

  rescheduleConvocatory(id: any, body: any): Observable<HttpResponse<any>> {
    return this.http.patch(`${environment.app.apiBaseUrl}convocatories/${id}/reschedule`, body, {observe: 'response'});
  }

  archiveConvocatory(id: any): Observable<HttpResponse<any>> {
    return this.http.patch(`${environment.app.apiBaseUrl}convocatories/${id}/archive`, {}, {observe: 'response'});
  }

  unpublishConvocatory(id: any): Observable<HttpResponse<any>> {
    return this.http.patch(`${environment.app.apiBaseUrl}convocatories/${id}/unpublish`, {}, {observe: 'response'});
  }

  publishConvocatory(id: any): Observable<HttpResponse<any>> {
    return this.http.patch(`${environment.app.apiBaseUrl}convocatories/${id}/publish`, {}, {observe: 'response'});
  }

  getTotalPostulations(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}convocatories/${id}/postulations`, {observe: 'response'});
  }

  getChartPostulations(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}convocatories/${id}/chart/postulations`, {observe: 'response'});
  }

  getPostulations(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}postulations/convocatory/${id}?include=user&limit=10&orderBy=updated_at&sortedBy=desc`, {observe: 'response'});
  }

  finalizePostulation(id: any): Observable<HttpResponse<any>> {
    return this.http.patch(`${environment.app.apiBaseUrl}convocatories/${id}/cancel`, {}, {observe: 'response'});
  }

}
