import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {CatalogFilter} from '../catalogs.model';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class CatalogsService {

  private filter = new BehaviorSubject<any>(new CatalogFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  sendFilter(object: any) {
    this.filter.next(object);
  }

  currentFilter(): Observable<any> {
    return this.filter.asObservable();
  }

  getFilter() {
    return this.filter.getValue();
  }

  createCatalog(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${environment.app.apiBaseUrl}catalogs`, body, {observe: 'response'});
  }

  deleteCatalog(id): Observable<HttpResponse<any>> {
    return this.http.delete(`${environment.app.apiBaseUrl}catalogs/${id}`, {observe: 'response'});
  }

  modifyCatalog(body: any, id: any): Observable<HttpResponse<any>> {
    return this.http.put(`${environment.app.apiBaseUrl}catalogs/${id}`, body, {observe: 'response'});
  }

  getByIdCatalog(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${environment.app.apiBaseUrl}catalogs/${id}`, {observe: 'response'});
  }

  getAllCatalogs(catalogFilter: CatalogFilter): Observable<HttpResponse<any>> {
    const params = createRequestOption({
      'page': catalogFilter.page,
      'limit': catalogFilter.limit,
      'orderBy': catalogFilter.orderBy,
      'sortedBy': catalogFilter.sortedBy,
      'search': catalogFilter.catalog.search
    });
    return this.http.get(`${environment.app.apiBaseUrl}catalogs`, {params: params, observe: 'response'});
  }

}
