import {ActionReducerMap} from '@ngrx/store';
import {routerReducer} from '@ngrx/router-store';

import * as fromRouter from '@ngrx/router-store';
import * as fromAuth from '../../pages/auth/shared/store/auth.reducer';
import * as intervention_areas from '../../pages/categories/intervention-areas/store/intervention-areas.reducer';
import * as intervention_lines from '../../pages/categories/intervention-lines/store/intervention-lines.reducer';
import * as subsectors from '../../pages/categories/subsectors/store/subsectors.reducer';
import * as sectors from '../../pages/categories/sectors/store/sectors.reducer';
import * as specialties from '../../pages/categories/specialties/store/specialties.reducer';
import * as new_edit_form from '../../pages/forms/new-edit-form/store/new-edit-form.reducer';
import * as all_forms from '../../pages/forms/all-forms/store/all-forms.reducer';

import * as convocatories_archived_finished
  from '../../pages/convocatories/archived-finished/store/archived-finished.reducer';
import * as convocatories_drafts_preparations
  from '../../pages/convocatories/drafts-preparations/store/drafts-preparations.reducer';
import * as convocatories_published_past
  from '../../pages/convocatories/published-past/store/published-past.reducer';

import * as new_edit_convocatorie
  from '../../pages/convocatories/new-edit-convocatorie/store/new-edit-convocatorie.reducer';

import * as convocatorie_dashboard
  from '../../pages/convocatories/dashboard/store/dashboard.reducer';

import * as convocatorie_postulations
  from '../../pages/convocatories/postulations/store/postulations.reducer';

import * as convocatorie_postulation_detail
  from '../../pages/convocatories/postulation-detail/store/postulation-detail.reducer';

import * as catalogs
  from '../../pages/categories/catalogs/store/catalogs.reducer';

import * as catalog_detail
  from '../../pages/categories/catalog-detail/store/catalog-detail.reducer';

import * as dashboard
  from '../../pages/dashboard/store/dashboard.reducer';

export interface AppState {
  router: fromRouter.RouterReducerState;
  auth: fromAuth.AuthState;
  appInterventionAreas: intervention_areas.ReportingState;
  appInterventionLines: intervention_lines.InterventionLinesState;
  appSubsectors: subsectors.SubsectorsState;
  appSectors: sectors.SectorsState;
  appSpecialties: specialties.SpecialtiesState;
  appNewEditForm: new_edit_form.NewEditFormState;
  appAllForms: all_forms.AllFormsState;
  appConvocatoriesArchivedFinished: convocatories_archived_finished.ArchivedFinishedState;
  appConvocatoriesDraftsPreparations: convocatories_drafts_preparations.DraftsPreparationsState;
  appConvocatoriesPublishedPast: convocatories_published_past.AllConvocatoriesState;
  appNewEditConvocatorie: new_edit_convocatorie.NewEditConvocatorieState;
  appConvocatorieDashboard: convocatorie_dashboard.ConvocatorieState;
  appConvocatoriePostulations: convocatorie_postulations.ConvocatorieState;
  appConvocatoriePostulationDetail: convocatorie_postulation_detail.ConvocatorieState;
  appCatalogs: catalogs.CatalogsState;
  appCatalogDetail: catalog_detail.CatalogDetailState;
  appDashboard: dashboard.DashboardState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: fromAuth.reducer,
  appInterventionAreas: intervention_areas.reducer,
  appInterventionLines: intervention_lines.reducer,
  appSubsectors: subsectors.reducer,
  appSectors: sectors.reducer,
  appSpecialties: specialties.reducer,
  appNewEditForm: new_edit_form.reducer,
  appAllForms: all_forms.reducer,
  appConvocatoriesArchivedFinished: convocatories_archived_finished.reducer,
  appConvocatoriesDraftsPreparations: convocatories_drafts_preparations.reducer,
  appConvocatoriesPublishedPast: convocatories_published_past.reducer,
  appNewEditConvocatorie: new_edit_convocatorie.reducer,
  appConvocatorieDashboard: convocatorie_dashboard.reducer,
  appConvocatoriePostulations: convocatorie_postulations.reducer,
  appConvocatoriePostulationDetail: convocatorie_postulation_detail.reducer,
  appCatalogs: catalogs.reducer,
  appCatalogDetail: catalog_detail.reducer,
  appDashboard: dashboard.reducer
};
