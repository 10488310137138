import {Action} from '@ngrx/store';

export enum EvaluatorsActionTypes {
  EvaluatorAddAction = '[Evaluators]EvaluatorAddAction',
  EvaluatorAddSuccess = '[Evaluators]EvaluatorAddSuccess',
  EvaluatorAddFailure = '[Evaluators]EvaluatorAddFailure',

  EvaluatorEditAction = '[Evaluators]EvaluatorEditAction',
  EvaluatorEditSuccess = '[Evaluators]EvaluatorEditSuccess',
  EvaluatorEditFailure = '[Evaluators]EvaluatorEditFailure',
}

export class EvaluatorAddAction implements Action {
  readonly type = EvaluatorsActionTypes.EvaluatorAddAction;

  constructor(public payload: { data: any }) {
  }
}

export class EvaluatorAddSuccess implements Action {
  readonly type = EvaluatorsActionTypes.EvaluatorAddSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluatorAddFailure implements Action {
  readonly type = EvaluatorsActionTypes.EvaluatorAddFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluatorEditAction implements Action {
  readonly type = EvaluatorsActionTypes.EvaluatorEditAction;

  constructor(public payload: { id: string, data: any }) {
  }
}

export class EvaluatorEditSuccess implements Action {
  readonly type = EvaluatorsActionTypes.EvaluatorEditSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluatorEditFailure implements Action {
  readonly type = EvaluatorsActionTypes.EvaluatorEditFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type EvaluatorsActions =
  | EvaluatorAddAction
  | EvaluatorAddSuccess
  | EvaluatorAddFailure
  | EvaluatorEditAction
  | EvaluatorEditSuccess
  | EvaluatorEditFailure;
