import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {SubsectorsFilter} from '../subsectors.model';
import {createRequestOption} from '../../../../shared/utils/request-util';

@Injectable()
export class SubsectorsService {

  private urlResource = environment.app.apiBaseUrl + 'subsectors';
  private areasIntervencionFilter = new BehaviorSubject<any>(new SubsectorsFilter());

  constructor(private http: HttpClient,
              @Inject('config') private config: AppConfig) {
  }

  createSubsector(body: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.urlResource}`, body, {observe: 'response'});
  }

  deleteSubsector(id): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.urlResource}/${id}`, {observe: 'response'});
  }

  modifySubsector(body: any, id: any): Observable<HttpResponse<any>> {
    return this.http.put(`${this.urlResource}/${id}`, body, {observe: 'response'});
  }

  getByIdSubsector(id: any): Observable<HttpResponse<any>> {
    return this.http.get(`${this.urlResource}/${id}?include=sector`, {observe: 'response'});
  }

  sendSubsectorFilter(object: any) {
    this.areasIntervencionFilter.next(object);
  }

  currentSubsectorFilter(): Observable<any> {
    return this.areasIntervencionFilter.asObservable();
  }

  getSubsectorFilter() {
    return this.areasIntervencionFilter.getValue();
  }

  getAllSubsectors(areasIntervencionFilter: SubsectorsFilter): Observable<HttpResponse<any>> {
    const params = createRequestOption({
      'page': areasIntervencionFilter.page,
      'limit': areasIntervencionFilter.limit,
      'orderBy': areasIntervencionFilter.orderBy,
      'sortedBy': areasIntervencionFilter.sortedBy,
      'search': areasIntervencionFilter.subsector.search,
      'include': 'sector,area_intervention'
    });
    return this.http.get(`${this.urlResource}`, {params: params, observe: 'response'});
  }
}
