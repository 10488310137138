import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {reducer} from './postulation-detail.reducer';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PostulationDetailEffects} from './postulation-detail.effects';
import {PostulationDetailService} from './postulation-detail.service';
import {AppConfig} from '../../../../shared/models/app-config.model';

@NgModule({
  imports: [CommonModule]
})

export class AppConvocatoriesPostulationDetailModule {
  static forRoot(config: AppConfig): ModuleWithProviders {
    return {
      ngModule: RootAppConvocatoriesPostulationDetailModule,
      providers: [
        {provide: 'config', useValue: config},
        PostulationDetailService
      ]
    };
  }
}

@NgModule({
  imports: [
    AppConvocatoriesPostulationDetailModule,
    StoreModule.forFeature('appConvocatoriesPostulationDetail', reducer),
    EffectsModule.forFeature([PostulationDetailEffects]),
  ],
})

export class RootAppConvocatoriesPostulationDetailModule {
}
