import {Action} from '@ngrx/store';

export enum PhaseThirdActionTypes {
  EvaluationGetAction = '[PhaseFirst]EvaluationGetAction',
  EvaluationGetSuccess = '[PhaseFirst]EvaluationGetSuccess',
  EvaluationGetFailure = '[PhaseFirst]EvaluationGetFailure',

  EvaluationPostulationDetailAction = '[PhaseFirst]EvaluationPostulationDetailAction',
  EvaluationPostulationDetailSuccess = '[PhaseFirst]EvaluationPostulationDetailSuccess',
  EvaluationPostulationDetailFailure = '[PhaseFirst]EvaluationPostulationDetailFailure',

  EvaluationReportPDfAction = '[PhaseFirst]EvaluationReportPDfAction',
  EvaluationReportPDfSuccess = '[PhaseFirst]EvaluationReportPDfSuccess',
  EvaluationReportPDfFailure = '[PhaseFirst]EvaluationReportPDfFailure',

  EvaluationReportExcelAction = '[PhaseFirst]EvaluationReportExcelAction',
  EvaluationReportExcelSuccess = '[PhaseFirst]EvaluationReportExcelSuccess',
  EvaluationReportExcelFailure = '[PhaseFirst]EvaluationReportExcelFailure',

  PostulationWinnerAction = '[PhaseFirst]PostulationWinnerAction',
  PostulationWinnerSuccess = '[PhaseFirst]PostulationWinnerSuccess',
  PostulationWinnerFailure = '[PhaseFirst]PostulationWinnerFailure',

  EvaluationCompleteAction = '[PhaseFirst]EvaluationCompleteAction',
  EvaluationCompleteSuccess = '[PhaseFirst]EvaluationCompleteSuccess',
  EvaluationCompleteFailure = '[PhaseFirst]EvaluationCompleteFailure',

  EvaluationEspecialReportAction = '[PhaseFirst]EvaluationEspecialReportAction',
  EvaluationEspecialReportSuccess = '[PhaseFirst]EvaluationEspecialReportSuccess',
  EvaluationEspecialReportFailure = '[PhaseFirst]EvaluationEspecialReportFailure',
}

export class EvaluationGetAction implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationGetAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationGetSuccess implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationGetSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationGetFailure implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationGetFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationPostulationDetailAction implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationPostulationDetailAction;

  constructor(public payload: { evaluation_id: string, postulation_id: string }) {
  }
}

export class EvaluationPostulationDetailSuccess implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationPostulationDetailSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationPostulationDetailFailure implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationPostulationDetailFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationReportPDfAction implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationReportPDfAction;

  constructor(public payload: { id: any }) {
  }
}

export class EvaluationReportPDfSuccess implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationReportPDfSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationReportPDfFailure implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationReportPDfFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationReportExcelAction implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationReportExcelAction;

  constructor(public payload: { id: any, data: any }) {
  }
}

export class EvaluationReportExcelSuccess implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationReportExcelSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationReportExcelFailure implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationReportExcelFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationEspecialReportAction implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationEspecialReportAction;

  constructor(public payload: { id: string }) {
  }
}

export class EvaluationEspecialReportSuccess implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationEspecialReportSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationEspecialReportFailure implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationEspecialReportFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class PostulationWinnerAction implements Action {
  readonly type = PhaseThirdActionTypes.PostulationWinnerAction;

  constructor(public payload: { evaluation_id: any, postulation_id: any }) {
  }
}

export class PostulationWinnerSuccess implements Action {
  readonly type = PhaseThirdActionTypes.PostulationWinnerSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class PostulationWinnerFailure implements Action {
  readonly type = PhaseThirdActionTypes.PostulationWinnerFailure;

  constructor(public payload: { validation: any }) {
  }
}

export class EvaluationCompleteAction implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationCompleteAction;

  constructor(public payload: { id: any, data: any }) {
  }
}

export class EvaluationCompleteSuccess implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationCompleteSuccess;

  constructor(public payload: { resultHeader: any }) {
  }
}

export class EvaluationCompleteFailure implements Action {
  readonly type = PhaseThirdActionTypes.EvaluationCompleteFailure;

  constructor(public payload: { validation: any }) {
  }
}

export type PhaseThirdActions =
  | EvaluationGetAction
  | EvaluationGetSuccess
  | EvaluationGetFailure
  | EvaluationPostulationDetailAction
  | EvaluationPostulationDetailSuccess
  | EvaluationPostulationDetailFailure
  | EvaluationReportPDfAction
  | EvaluationReportPDfSuccess
  | EvaluationReportPDfFailure
  | EvaluationReportExcelAction
  | EvaluationReportExcelSuccess
  | EvaluationReportExcelFailure
  | EvaluationEspecialReportAction
  | EvaluationEspecialReportSuccess
  | EvaluationEspecialReportFailure
  | PostulationWinnerAction
  | PostulationWinnerSuccess
  | PostulationWinnerFailure
  | EvaluationCompleteAction
  | EvaluationCompleteSuccess
  | EvaluationCompleteFailure;
