import {Actions, Effect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, debounceTime, distinctUntilChanged, exhaustMap, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ProfileService} from './profile.service';
import * as profile from './profile.actions';

@Injectable()
export class ProfileEffects {

  constructor(private actions$: Actions,
              private profileService: ProfileService) {
  }

  @Effect()
  name$ = this.actions$.pipe(
    ofType(profile.ProfileActionTypes.NameSendAction),
    map((action: profile.NameSendAction) => action.payload),
    exhaustMap((param: any) =>
      this.profileService.sendPerfil(param.id, param.name).pipe(
        map(success => new profile.NameSendSuccess({resultHeader: success, name: param.name})),
        catchError(err => of(new profile.NameSendFailure({validation: err})))
      )
    )
  );

  @Effect()
  password$ = this.actions$.pipe(
    ofType(profile.ProfileActionTypes.PasswordSendAction),
    map((action: profile.PasswordSendAction) => action.payload),
    exhaustMap((param: any) =>
      this.profileService.sendPassword(param.id, param.oldpassword, param.password).pipe(
        map(success => new profile.PasswordSendSuccess({resultHeader: success})),
        catchError(err => of(new profile.PasswordSendFailure({validation: err})))
      )
    )
  );
}
