import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {PostulantFilter} from '../postulants.model';


@Injectable()
export class PostulantsService {
	private postulantsFilter = new BehaviorSubject<any>(new PostulantFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	currentEvaluatorsFilter(): Observable<any> {
    	return this.postulantsFilter.asObservable();
  	}
  	getEvaluatorsFilter() {
    	return this.postulantsFilter.getValue();
  	}
  	sendEvaluatorsFilter(object: any) {
    	this.postulantsFilter.next(object);
  	}
	getEvaluators(EvaluatorsFilter: PostulantFilter): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}postulants`;
		const params = createRequestOption({
			//'include': 'convocatories',
			'page': EvaluatorsFilter.page,
			'limit': EvaluatorsFilter.limit,
			'orderBy': EvaluatorsFilter.orderBy,
			'sortedBy': EvaluatorsFilter.sortedBy,
			'search': EvaluatorsFilter.convocatorie.search
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	
}