import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppConfig} from '../../../../shared/models/app-config.model';
import {environment} from '../../../../../environments/environment';
import {createRequestOption} from '../../../../shared/utils/request-util';
import {EvaluatorFilter} from '../evaluators.model';


@Injectable()
export class EvaluatorsService {
	private evaluatorsFilter = new BehaviorSubject<any>(new EvaluatorFilter());
	constructor(private http: HttpClient,
		@Inject('config') private config: AppConfig) {
	}
	currentEvaluatorsFilter(): Observable<any> {
    	return this.evaluatorsFilter.asObservable();
  	}
  	getEvaluatorsFilter() {
    	return this.evaluatorsFilter.getValue();
  	}
  	sendEvaluatorsFilter(object: any) {
    	this.evaluatorsFilter.next(object);
  	}
	getEvaluators(EvaluatorsFilter: EvaluatorFilter): Observable<HttpResponse<any>> {
		let apiUrl = `${environment.app.apiBaseUrl}evaluators`;
		const params = createRequestOption({
			'include': 'convocatories',
			'page': EvaluatorsFilter.page,
			'limit': EvaluatorsFilter.limit,
			'orderBy': EvaluatorsFilter.orderBy,
			'sortedBy': EvaluatorsFilter.sortedBy,
			'search': EvaluatorsFilter.convocatorie.search
		});
		return this.http.get(apiUrl, {params: params, observe: 'response'});
	}
	startEvaluation(id){
		let apiUrl = `${environment.app.apiBaseUrl}evaluation/convocatory/${id}/enable`;
		return this.http.patch(apiUrl, { observe: 'response' });
	}
	createUser(data: any){
		let apiUrl = `${environment.app.apiBaseUrl}evaluators`;
		return this.http.post(apiUrl, data, { observe: 'response' });
	}
	editUser(id: string, data: any){
		let apiUrl = `${environment.app.apiBaseUrl}users/${id}`;
		return this.http.put(apiUrl, data, { observe: 'response' });
	}
}